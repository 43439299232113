import { propertySchemaApi } from "apis/schema"
import { displayPhoneValue, hrefPhoneValue } from 'utils/contact-value';


export const entityContactValueSchema = {
	"addValue": {

	},
	"order": {

	},
	"purposes": {
		valueType: 'enum',
		valueSubType: 'contactValuePurposes',
		displayIfEmpty: '',
	},
	"type": {
		valueType: 'enum',
		valueSubType: 'contactValueTypes',
		getValue(m,s) {
			m = m.attributes || m;
			return m.value?.type || m.type;
			// let innerType = propertySchemaApi.getValue('value.type', m, s);
			// if (innerType) { return innerType }
			// return m.type;
		}
	},
	"isPhone": {
		getValue(model, schema) {
			const type = (propertySchemaApi.getValue('type', model, schema) || '').toLowerCase();
			return type.indexOf('phone') > -1;
		}
	},
	"value": {
		getValue(m,s) {
			m = m.attributes || m;
			let v = m.value;
			if (v && typeof v === 'object') {
				return v.value;
			}
			return v;
		},
		displayValue(v, model, schema) {
			
			const type = (propertySchemaApi.getValue('type', model, schema) || '').toLowerCase();
			const isPhone = type.indexOf('phone') > -1;
			if (isPhone) {
				const add = propertySchemaApi.getValue('addValue', model, schema);
				return displayPhoneValue(v, add);
			} else {
				return v;
			}
		}
	},
	"href": {
		getValue(model, schema) {
			const type = propertySchemaApi.getValue('type', model, schema);
			const value = propertySchemaApi.getValue('value', model, schema) || '';
			if (type == 'email') {
				return 'mailto:' + value;
			} else if (type === 'phone' || type === 'mobilePhone') {
				return hrefPhoneValue(value);
			} else if (value && value.startsWith && value.startsWith('http')) {
				return value;
			} else {
				console.warn('unhandled value case:', value, typeof value)
			}

		}
	}
}


function formatPhone(phone) {

}