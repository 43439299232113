﻿import { BbModel, BbCollection, _ } from 'vendors';

import busControls from 'bus/controls';
import EditBehavior from 'ui/behaviors/editValue';
import modals from '_libs/modals';

import Select from 'ui/controls/select';
import SelectRest from 'ui/controls/selectRest';
import Input from 'ui/controls/input';
import Range from 'ui/controls/range';
import Datetime from 'ui/controls/datetime';
import VC from 'ui/models/values/collection';

import SelectEmployees from 'ui/controls/selectEmployees';
import SelectOwners from 'ui/controls/selectOwners';
import Datetime2 from 'ui/controls/datetime2';
import LeadSourceEdit from 'ui/controls/leadSourceEdit';
import TagsInput from 'ui/controls/tagsInput';

import Rest from 'ui/models/values/rest';
import EditPriceMeter from 'ui/controls/editPriceMeter';
import EditPrice2 from '../editPrice2';
import SelectBuilding from 'ui/controls/selectBuilding';

import { EditOfferPriceBoth, EditOfferPriceMeter, EditOfferPriceSquare } from '../editOfferPrice';

import template from './modal.html';

import { BbeCollection } from 'core/bbe';

import { MneView } from 'core/mne';
import { View } from 'core';

import './editValue-control.less';
import { isViewClass } from 'utils';
import refs from 'references';


var ns = {};

function cloneAndCleanOptions(opts) {		
	var options = _.extend({}, opts);

	delete options.template;
	delete options.collection;
	delete options.model;
	return options;
}


var Layout = View.extend({ //MneView.extend({
	baseClassName:'editValue-control',
	classNames:[
		(v) => v.getOption('editValueClassName', true)
	],
	regions: {
		'valueView': '.control-region',
		'predefinedView' : '.predefined-region',
	},
	behaviors: [EditBehavior],
	initialize: function () {

		console.log('-editValue-control-', this);

		this.initialValue = this.getOption('initialValue'); // || [];
		this.valueOptions = cloneAndCleanOptions(this.options);
		//this.initialValue = this.valueOptions.initialValue;
	},
	onRender: function () {
		this.showValueView();
		this.showPredefinedView();
	},
	getRangeViewOptions: function (value, opts) {
		var fromOpts = _.extend({}, opts, { initialValue: (value || {}).from });
		var toOpts = _.extend({}, opts, { initialValue: (value || {}).to });



		var rangeOpts = {
			fromView: opts.valueType == 'datetime' ? Datetime.create(fromOpts) : Input.create(fromOpts),
			toView: opts.valueType == 'datetime' ? Datetime.create(toOpts) : Input.create(toOpts),
			initialValue: value,
		}
		return rangeOpts;
	},
	getControlViewOptions() {
		const controlOptions = this.getOption('controlOptions', true) || {};
		const opts = {
			...(this.valueOptions || {}),
			...controlOptions
		}
		return opts;
	},
	getValueView: function () {
		var opts = this.getControlViewOptions();
			//this.valueOptions;

		console.log('## CHECK', opts);

		if (opts.controlType) {

			if (isViewClass(opts.controlType)) {
				const Control = opts.controlType;
				const controlOptions = {
					...opts,
					noBoxHeader: true,
				}
				delete controlOptions.controlType;
				delete controlOptions.header;
				let modelhash = controlOptions.modelHash;
				if (controlOptions.value && typeof controlOptions.value === 'object') {
					
					controlOptions.modelHash = controlOptions.value;
				}
				console.warn('control options', controlOptions);
				console.warn('wiped modelHash', modelhash);
				return new Control(controlOptions);
			}

			//console.log('#', opts);
			switch (opts.controlType) {
				case 'select':
					opts.collection || (opts.collection = opts.sourceValues);
					return new Select(opts); //Select.create(opts);
				case 'selectRest':
					return new SelectRest(opts);
				case 'selectEmployees':
					return SelectEmployees.create(opts); //SelectEmployees.create(opts);
				case 'selectOwners':
					return new SelectOwners(opts);
				case 'selectBuildings':
					console.log(' SELECTING BUILDING');
					return new SelectBuilding(opts);
				case 'datetime':
					console.log(' SELECTING DATETIME ');
					return new Datetime2(opts);					
				case 'datetime2':
					console.log(' SELECTING DATETIME2 ');
					return new Datetime2(opts);
				case 'leadSourceEdit':
					return new LeadSourceEdit(opts);
				case 'tagsInput':
					return new TagsInput(opts);
				case 'editPriceMeter':
					console.log(' new EditPriceMeter(opts) ');
					return new EditPriceMeter(opts);
				case 'editOfferPriceMeter':
					return new EditOfferPriceMeter(opts);
				case 'editOfferPriceSquare':
					return new EditOfferPriceSquare(opts);
				case 'editOfferPriceBoth':
					return new EditOfferPriceBoth(opts);
				case 'editPrice2':
					return new EditPrice2(opts);
				case 'text':
					return new Input(opts);
				default: 
					console.warn(opts.controlType, ' is unhandled control type');
				}
		} else if (opts.sourceValues instanceof BbeCollection) {
			opts.collection = opts.sourceValues;
			return Select.create(opts);
		} else if (opts.multiple) {
			console.warn('EditValue: not implemented getValueView')
		} else if (!opts.multiple && opts.modelType !== 'range') {
			if (opts.valueType == 'date') {
				/// WTF ???
			} else if (opts.valueType == 'datetime') {
				console.log(' - GET DATETIME BY valueType')
				return new Datetime2(opts);
				//return Datetime.create(opts);
			} else
				return Input.create(opts);
		} else if (!opts.multiple && opts.modelType == 'range') {
				//var fromOpts = _.extend({}, opts, { initialValue: (this.initialValue || {}).from });
				//var toOpts = _.extend({}, opts, { initialValue: (this.initialValue || {}).to });
				//var rangeOpts = {
				//	fromView: Input.create(fromOpts),
				//	toView: Input.create(toOpts),
				//	initialValue: this.initialValue,
				//}

			if (opts.valueType == 'datetime') {
				console.log(' - GET RANGE DATETIME BY valueType')
				return new Datetime2(opts);
			}

			var rangeOpts = this.getRangeViewOptions(this.initialValue, this.valueOptions);
			return Range.create(rangeOpts);
		}

	},
	showValueView: function() {
		var valueView = this._valueView = this.getValueView();

		if (valueView)
			this.showChildView('valueView', valueView)
		else
			console.warn('ui-controls-editValue: valueView is undefined');
	},
	onChildviewValueChanged: function (value) {
		console.warn('[editValue:change]', value, this);
		this.newValue = value;
		let onChange = this.getOption('onChange', false);
		if (typeof onChange === 'function') {
			console.warn('[editValue:change]', 'onChange invoke');
			onChange.call(this, value);
		}
	},
	onBeforeEditDone: function (type) {
		// if (type === 'reset') {
		// 	this.onChildviewValueChanged(undefined);
		// }
		const newvalue = ('newValue' in this === true) ? this.newValue : this.initialValue;
		console.warn('onBeforeEditDone', this.newValue, this.initialValue);
		this.triggerMethod('edit:done', type, newvalue, this.initialValue);
	},
	onCancel: function() { },
	onReset: function () { console.log('URA!!') },
	onApply: function() { },
	onChildviewRefresh: function() {
		this.triggerMethod('refresh');
	},
	showPredefinedView: function () {
		var suggestions = this.getOption('suggestions');
		if (suggestions instanceof Array && suggestions.length) {
			var models = _.map(suggestions, function (v, i) {
				if (_.isObject(v)) return v;
				return { id: v, value: v, label: v };
			});
			var col = new BbeCollection(models);
			var view = new Select({
				collection: col,
				noComparator: true,
			});
			this.showChildView('predefinedView', view);
			var valueView = this._valueView;
			this.listenTo(view, 'value:changed', function (values) {
				if (valueView && valueView.refreshValue)
					valueView.refreshValue(values);
			});
		}
	},
	templateContext: function() {
		//var ec = this.getOption('editContext') || {};
		var header = this.getOption('header');
		var okLabel = this.getOption('okLabel') || 'ok';
		var cancelLabel = this.getOption('cancelLabel') || 'отменить';
		var resetLabel = this.getOption('resetLabel') || 'сбросить';
		var applyLabel = this.getOption('applyLabel') || 'применить';
		var showApply = !!this.getOption('applyLabel') || this.getOption('showApplyButton') === true;
		return {
			header: header,
			okLabel: okLabel,
			cancelLabel: cancelLabel,
			resetLabel: resetLabel,
			applyLabel: applyLabel,
			showApply: showApply,
		}
	},
});

const UnInvokable = [BbCollection, Rest];

function normalizeSourceOptions(options) {
	
	let sc = options.sourceValues;
	if (typeof sc === 'function' && UnInvokable.every(Ctor => sc.prototype instanceof Ctor === false)) {
		sc = sc();
		options.sourceValues = sc;
	}

	var svWho = _.who(sc)
	if (sc && !svWho.bbc && svWho.hash && !(sc instanceof Rest)) {
		options.sourceValues = VC.Single.fromHash(sc, { excludeValues: options.excludeValues, valueType: options.valueType });
	}
}

ns.modal = function(opts, modalOpts) {
	var options = _.extend({ 
		//template: '#tmpl-ui-controls-editValue-modal',
		template
	},opts);
	
	normalizeSourceOptions(options);

	var view =  new Layout(options);
	// console.log(options);
	// console.log(view);
	//var modal = 
	modals.modal(view, modalOpts);

	var promise = $.Deferred();

	view.on('edit:done', function (type, value, initialValue) {
		console.warn('EDIT:DONE', type, value, initialValue);
		if (type == 'ok' || type == 'apply') {
			promise.resolve(type, value, initialValue);
		} else {
			promise.reject(type, value, initialValue);
		}
		view.destroy();
	});

	view.on('destroy', function () {
		if (promise.state() === 'pending') promise.reject('destroy');
	});


	return promise;
}

ns.modal_modern = function(opts, modalOpts) {
	const options = Object.assign({ 
		template
	},opts);
	normalizeSourceOptions(options);
	const view =  new Layout(options);
	modals.modal(view, modalOpts);


	const promise = new Promise((resolver, rejector) => {
		view.on('edit:done', (type, value, initialValue) => {
			if (type == 'ok' || type == 'apply') {
				resolver({
					ok: true,
					value: {
						type, value, initialValue
					}
				});
				view.destroy();
			} else {
				rejector({
					ok: false,
					value: {
						type, value, initialValue
					}
				})
			}
		})
	});

	return promise;
}

ns.modal.single = function (_opts, showOptions) {
	var opts = {
		header: _opts.header || 'Введите новое значение',
		multiple: false,
		modelType: 'single',
		valueType: _opts.valueType,
		valueSubType: _opts.valueSubType,
		initialValue: _opts.value,
	}
	var svWho = _.who(_opts.sourceValues)
	if (svWho.hash && !(_opts.sourceValues instanceof Rest)) {
		opts.sourceValues = VC.Single.fromHash(_opts.sourceValues, { excludeValues: _opts.excludeValues, valueType: _opts.valueType });
		delete _opts.sourceValues;
	}

	if (opts.valueType === 'enum' && opts.valueSubType && !opts.sourceValues) {
		opts.sourceValues = refs.enum(opts.valueSubType);
	}

	var options = _.extend({}, opts, _opts);
	if (showOptions == null) {
		showOptions = { modalCssCfg: 'shortAutoHeight' };
	}
	console.log('edit-value', options);
	return ns.modal(options, showOptions);
}

ns.modal.single_modern = function(_opts, showOptions) {
	var opts = {
		header: _opts.header || 'Введите новое значение',
		multiple: false,
		modelType: 'single',
		valueType: _opts.valueType,
		valueSubType: _opts.valueSubType,
		initialValue: _opts.value,
	}
	var svWho = _.who(_opts.sourceValues)
	if (svWho.hash && !(_opts.sourceValues instanceof Rest)) {
		opts.sourceValues = VC.Single.fromHash(_opts.sourceValues, { excludeValues: _opts.excludeValues, valueType: _opts.valueType });
		delete _opts.sourceValues;
	}

	if (opts.valueType === 'enum' && opts.valueSubType && !opts.sourceValues) {
		opts.sourceValues = refs.enum(opts.valueSubType);
	}

	var options = _.extend({}, opts, _opts);
	if (showOptions == null) {
		showOptions = { modalCssCfg: 'shortAutoHeight' };
	}
	console.log('edit-value', options);
	return ns.modal_modern(options, showOptions);
}

ns.modal.string = function (_opts) {

	var opts = {
		header: _opts.header || 'Введите новое значение',
		multiple: false,
		modelType: 'single',
		valueType: 'string',
		initialValue: _opts.value,
		//sourceValues: radio.request('employeesSelect'),
		//controlType: 'select',
		//viewComparator: function (a, b) {
		//	return bbeViewComparator(a, b, function () { return this.model.get('label').toLowerCase(); })
		//}
	}

	var options = _.extend({}, opts, _opts);
	
	return ns.modal(options, { modalCssCfg: 'shortAutoHeight' });

}

ns.modal.string_modern = function(_opts) {
	var opts = {
		header: _opts.header || 'Введите новое значение',
		multiple: false,
		modelType: 'single',
		valueType: 'string',
		initialValue: _opts.value,
	}

	var options = _.extend({}, opts, _opts);
	
	return ns.modal_modern(options, { modalCssCfg: 'shortAutoHeight' });
}

busControls.reply('edit:value', ns);

export default ns;
