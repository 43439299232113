﻿// define('realties-searchOnMap-layout', ['bus', 'app-paths', 'mapApi', 'ui-models-filters', 'realties-offers-filters', 'realties-search-actionsPanel', 'ui-controls-fastfilters'
// , 'ui-models-selectionHolder', 'svc-realties-searchOnMap-clusterInfo', 'svc-realties-searchOnMap-polyInfo', 'ui-controls-map-input'
// , 'realties-sendByEmail', 'ui-layouts-selectedPane', 'realties-offers-views-collection', 'm-realties-offers', 'realties-models'], function (Bus, paths, mapApi, Filters, offersFilters, ActionsPanel, FastFilters, SelectionHolder, ClusterInfo, PolyInfo, MapInput, SendByEmail, SelectedPane, OffersView, offersNs, realtiesNs) {});

import Bus from 'bus';
// import appBus from 'bus/app';
// import paths from 'app-paths';

//import mapApi from 'mapApi';

import Filters from 'ui/models/filters';
import offersFilters from 'svc/realties/offers/filters';
import SelectionHolder from 'ui/models/selectionHolder';
import ClusterInfo from 'svc/realties/searchOnMap/clusterInfo';
import PolyInfo from 'svc/realties/searchOnMap/polyInfo';
import MapInput from 'ui/controls/map/input';
import SendByEmail from 'svc/realties/sendByEmail';
import SelectedPane from 'ui/layouts/selectedPane';
import FastFilters from 'ui/controls/fastfilters';
import OffersView from 'svc/realties/offers/views/collection';
import offersNs from 'm/realties/offers';
import realtiesNs from 'svc/realties/models';

import { SearchRealtyControl, SearchPlaceControl } from 'ui/controls/map/control-input';

import template from './layout.html';

import { getValueByPath, bbeViewComparator } from 'utils';


import { BbView, NextCollectionView } from 'vendors';
import { MnView, BbModel } from 'vendors';

import { BbeCollection } from 'core/bbe';

import { MneView } from 'core/mne';

// import { pdfApi } from 'apis';
import { actionsMixin } from '../search-selected-actions';

//import { allbuildings } from 'm/realties/buildings';
import { getRealtyLatLng, getRealty, getRealtyAsync, realtiesCoords } from 'mods/realties/realties';

import { View } from 'core/view';
import { SearchContext } from '../searchContext';
import { OffersCollectionV4 } from '../../../m/realties/offers';
import { OffersCollection } from 'mods/realties/offers';
import { mapsApi } from 'apis/mapsApi';
import { MapView } from './MapView';
import { SideBar } from './SideBar';
import { BuildingInList } from '../building/views/inList';
import {  } from '../../../../mods/realties/realties/realties';
import { SideBarModel } from './SideBarModel';
import { isAsidelessMap, isMapWithAside } from './utils';


function buildBounds(realties = {}) {
	return Object.keys(realties).reduce((memo, id) => {
		const latlng = getRealtyLatLng(id);
		extendBounds(memo, latlng);
		return memo;
	}, {});
}

async function buildBoundsAsync(realties) {
	const ids = Object.keys(realties);
	const missingIds = [];
	const coordsArray = [];
	for(let id of ids) {
		let latlng = getRealtyLatLng(id);
		if (!latlng) {
			missingIds.push(id)
		} else {
			coordsArray.push(latlng);
		}
	}

	if (missingIds.length) {
		const freshll = await realtiesCoords.fetch(missingIds);
		coordsArray.push(...freshll);
	}

	const bounds = coordsArray.reduce((memo, latlng) => {
		extendBounds(memo, latlng);
		return memo;
	}, {});

	return bounds;
}


function extendBounds(bounds, latlng) {
	if (!latlng) { return; }
	if (bounds.north == null || latlng.lat > bounds.north) {
		bounds.north = latlng.lat;
	}

	if (bounds.south == null || latlng.lat < bounds.south) {
		bounds.south = latlng.lat;
	}

	if (bounds.east == null || latlng.lng > bounds.east) {
		bounds.east = latlng.lng;
	}

	if (bounds.west == null || latlng.lng < bounds.west) {
		bounds.west = latlng.lng;
	}

}


var RealtyInList = MneView.extend({
	className: 'map-realty-search-popup-item',
	template: _.template('<big><%= label %></big><small><%= description %></small>'),
	events: {
		click: function () {
			var data = {
				id: this.model.get('value'),
				coords: this.model.get('coords')
			};
			this.triggerMethod('searched:bld:click', data);
		}
	}
});

var PopupView = NextCollectionView.extend({
	className: 'map-realty-search-popup',
	childView: RealtyInList,
	childViewTriggers: {
		'searched:bld:click': 'searched:bld:click'
	}
});

function getAddressLatlng(addressId) {
	return Bus.Models.request('geo:mapPoint:latlng', addressId);
}

var StationView = MneView.extend({
	className: 'metro-station',
	template: _.template('<div <%= metroClass %>>ст. м. <%= name %></div>'),
	templateContext () {
		var item = this.getOption('item')
		return {
			name: item.name,
			metroClass: ' class="clr-metro-' + item.lineNumber + ' txt"'
		}
	}
});

const HeaderItemView = View.extend({
	template: '<div <%= addClass %>><%= name %> <span class="badge" title="количество предложений"><%= total %></span></div>',
	classNames: [
		v => v.type
	],
	initialize() {
		this.type = this.getOption('type');
		this.item = this.getOption('item');
		this.model = new BbModel();

		// this.listenTo(this.collection, 'data:load:complete', () => {
		// 	this.model.set('total', this.getTotal());
		// });
		this.listenTo(this.collection, 'sync', () => {
			this.model.set('total', this.getTotal());
		});
		this.listenTo(this.collection, 'request', () => {
			this.model.set('total', '...');
		});

		if (this.types[this.type]) {
			this.types[this.type].call(this, this);
		} else {
			this._initNotImplemented();
		}
	},
	types: {
		line: v => v._initSubway(),
		station: v => v._initSubway(),
		cluster: v => v._initCluster(),
		all: v => v._initNotSelected()
	},
	_initNotImplemented() {
		this.model.set({
			addClass: '',
			name: '&mdash;',
			total: '...',
		});
		// this.state({
		// 	'add-class': '',
		// 	'name-prefix': '',
		// 	'name': '&mdash;',
		// 	'name-postfix': '',
		// });
	},
	_initSubway() {

		const isStation = this.type === 'station'; 
		const lineNumber = isStation ? this.item.collection.lineNumber : this.item.get('lineNumber');
		const name = this.item.get('name');
			// this.item.lineNumber  || '';
		
		const postfix = isStation ? '' : ''; //' линия';
		const prefix = isStation ? 'ст. м. ' : '';
		// const name = prefix + item.name + postfix;
		this.model.set({
			addClass: 'clr-metro-' + lineNumber,
			name: prefix + name + postfix,
			total: '...',
		});
		// this.state({
		// 	'add-class': 'clr-metro-' + lineNumber,
		// 	'name-prefix': prefix,
		// 	'name': this.item.name,
		// 	'name-postfix': postfix,
		// });
	},
	_initNotSelected() {
		this.model.set({
			addClass: '',
			name: 'все предложения',
			total: '...',
		});		
		// this.state({
		// 	'add-class': '',
		// 	'name-prefix': '',
		// 	'name': 'все предложения',
		// 	'name-postfix': '',
		// });
	},
	_initCluster() {
		this.model.set({
			addClass: '',
			name: 'группа зданий',
			total: '...',
		});	
		// this.state({
		// 	'add-class': '',
		// 	'name-prefix': '',
		// 	'name': 'группа зданий',
		// 	'name-postfix': '',
		// });
	},
	templateContext() {
		return {
			addClass: this.getAddClass(),
			// name: this.getName(),
			// total: this.getTotal()
		}
	},
	getAddClass() {
		//const add = this.state('add-class');
		const add = this.model.get('addClass');
		if (!add) { return ''; }
		return ' class="' + add + '"';
	},
	getName() {
		return [this.state('name-prefix'), this.state('name'), this.state('name-postfix')].filter(f => !!f).join(' ');
	},
	modelEvents: {
		change: 'render'
	},
	getTotal() {
		const fr = this.collection.getFetchResult?.call(this.collection);
		const total = fr?.founded || 0;
		return total;
	}
});

const SubwayItemView = MneView.extend({
	className: 'metro-station',
	template: _.template('<div <%= addClass %>><%= name %></div>'),
	templateContext () {
		const type = this.getOption('type');
		const isStation = type === 'station'; 

		const item = this.getOption('item')
		
		const postfix = isStation ? '' : ' линия';
		const prefix = isStation ? 'ст. м. ' : '';
		const name = prefix + item.name + postfix;
		const lineNumber = item.lineNumber  || '';

		return {
			name,
			addClass: ' class="clr-metro-' + lineNumber + ' txt"'
		}
	}
});


var ResultView = View.extend({
	baseClassName: 'result-container',
	template: '<div class="item"></div><div class="result"></div><button class="unselect"><i></i></button>',

	stateClassNames: ['something-selected'],

	initialize: function (opts) {
		this.filterObject = opts.filterObject;
		this.initOptions(opts);
		// console.error('JOINED OFFERS', this.getOption('joinedOffers'));
	},

	initOptions: function (opts = {}) {
		// console.warn('-opts-', opts);
		this.type = opts.type;
		this.item = opts.item;
		this.data = opts.data || this.filterObject.getUrlHash();
		this.rawData = _.extend({}, opts.rawdata, opts.rawData);
		this.viewFilter = opts.viewFilter;
		this.realtyId = opts.realtyId;
		this.overlays = opts.overlays;


		this.state('something-selected', !!this.type);

	},

	regions: {
		'item': { el: '.item', replaceElement: true },
		'result': '.result'
	},

	onRender: function () {
		this.showViews();
	},

	showViews(options) {
		this.showItem(options);
		this.showResult(options);
	},

	events: {
		'click button.unselect': function () {
			this.trigger('sidebar:selection:removed');
			this.getRegion('item').empty();
			this.getRegion('result').empty();
			this.initOptions();
			this.showViews();
		}
	},

	// _invokeOnOverlays(method, args = []) {
	// 	if (this.overlays) {
	// 		this.overlays.forEach(overlay => {
	// 			if (overlay[method]) {
	// 				overlay[method].apply(overlay, args);
	// 			}
	// 		});
	// 	}
	// },
	// unselectOverlays() {
	// 	this._invokeOnOverlays('state', ['selected', false]);
	// },
	// selectOverlays() {
	// 	this._invokeOnOverlays('state', ['selected', true]);
	// },

	async showItem() {
		var type = this.type;

		if (type == 'realty') {
			var View = BuildingInList;
			// Bus.Views.request('realties:building:inList');
			var model = await getRealtyAsync(this.realtyId);
			// Bus.svc.request('buildings', this.realtyId);
			console.log('BuildingInList#REALTY#', model, this.realtyId, this);
			let view = new View({ model: model });
			this.showChildView('item', view);
			return;
		} 

		const header = new HeaderItemView({
			type: type || 'all',
			item: this.item,
			collection: this.collection
		});
		this.showChildView('item', header);

		// //if (!type) return;
		// if (type === 'station' || type === 'line') {

		// 	var view = new SubwayItemView({
		// 		type,
		// 		item: this.item,
		// 		collection: this.collection,
		// 	});
		// 	this.showChildView('item', view);

		// } else if (type == 'cluster') {
		// 	let view = new MneView({

		// 		className: 'cluster',
		// 		template: _.template('<div>группа зданий</div>')

		// 	});
		// 	this.showChildView('item', view);
		// } else if (type == 'poly') {
		// 	this.showChildView('item', _.template('<div>полигон</div>'));
		// } else {
		// 	this.showChildView('item', _.template('<div>все предложения</div>'));
		// }
	},
	showResult: function () {
		this.collection.reset();
		var view = new OffersView.V2({
			childViewEventPrefix: false,
			className: 'offers-v2 with-scroll scrollY-region',
			collection: this.collection,
			isJoined: child => this.isJoinedOffer(child),
			collectionViewOptions: {
				childViewEventPrefix: false,
				// childViewOptions: {
				// 	isJoined: child => this.isJoinedOffer(child)
				// }
				//className: 'scrollY-region'
			},
			data: this.data,
			viewFilter: this.viewFilter,
			selectionHolder: this.getOption('selectionHolder'),
			selectedComparators: this.getOption('selectedComparators'),
			selectedActions: this.getOption('selectedActions'),
		});
		// this.listenTo(view, 'after:render:children')
		
		view.triggerMethod('filters:apply');
		view.listenTo(this.filterObject, 'filters:apply', () => {
			const newData = Object.assign({}, this.data, this.filterObject.getUrlHash());
			view.options.data = newData;
			view.triggerMethod('filters:apply', newData);
		});
		this.offers = view;
		this.showChildView('result', view);
	},
	refresh: function (opts) {
		// this.unselectOverlays();
		if (opts) {
			this.initOptions(opts);
			this.render();		
		}
	},
	childViewTriggers: {
		'metro:station:click': 'metro:station:click',
		'address:click': 'address:click',
		'after:render:children':'after:render:children'
	},
	childViewEvents: {
		'offer:hover'(arg) {
			this.triggerInteraction('hover', 'offer', arg);
		},
		'offer:unhover'(arg) {
			this.triggerInteraction('unhover', 'offer', arg);
		},
		// 'address:hover'(arg) {
		// 	this.triggerInteraction('hover', 'address', arg);
		// },
		// 'address:unhover'(arg) {
		// 	this.triggerInteraction('unhover', 'address', arg);
		// },
	},
	triggerInteraction(eventType, entityType, entity) {
		this.triggerMethod('interaction', eventType, entityType, entity);
	},
	isJoinedOffer(view) {
		const joinedOffers = this.getOption('joinedOffers') || {};
		const res = view.model.id in joinedOffers;
		return res;
	}
});

var Tabs = MneView.extend({
	getTemplate: function () {
		var btn = function (name, active) {
			return '<button class="tab-item ' + name + ((active && ' active') || '') + '" data-id="' + name + '"><i></i></span>';
		}
		var html = '';
		html += btn('result', true);
		html += btn('filters');
		html += btn('selected');
		return _.template(html);
	},
	events: {
		'click button': function (e) {
			var $btn = $(e.target).closest('button');
			var id = $btn.data('id');
			this.swapTo(id);
			//this.$('button').removeClass('active');
			//$btn.addClass('active');
			this.trigger('tab:click', id);
		}
	},
	swapTo: function (tab) {
		this.$('button').removeClass('active');
		this.$('button[data-id="' + tab + '"]').addClass('active');
	}
});

var OldSideBar = MneView.extend({
	className: 'side-bar',
	template: _.template('<div class="tabs"></div><div class="content"></div><div class="slider"></div>'),
	events: {
		'mousedown .slider': function (e) {
			// var $doc = $(window);
			// var moveHandler = _.debounce(this.moveHandler.bind(this), 300);
			// this.trigger('map:events:disable');
			// var _this = this;
			// $doc.on('mousemove', moveHandler);
			// $doc.one('mouseup', function (e) {
			// 	$doc.off('mousemove', moveHandler);
			// 	_this.trigger('map:redraw');
			// 	_this.trigger('map:events:enable');
			// });
		}
	},
	moveHandler: function (e) {
		this.$el.css({
			'width': e.clientX + 'px',
		});
	},

	regions: {
		'tabs': '.tabs',
		'content': '.content',
	},
	initialize: function (opts) {
		this.collection = new OffersCollection(); // new OffersCollectionV4();
		// this.collection.on('all', c => console.log('[col]', c));
		 // new OffersCollection();
			// new OffersCollectionV4();
		this.useNextMapApi = this.getOption('useNextMapApi');
		this.mapApi = this.getOption('mapApi');
		if (this.useNextMapApi) {
			this.listenTo(this.mapApi, {
				'cluster:click': (realties, data) => {
					this.showNewCluster({ realties, data });
				},
				'station:click': station => this.showNewStation({ station }),
				'line:click': line => this.showNewLine({ line }),
				'click': (type, options) => {
					console.warn('CLICK', type, options)
					if (type === 'point' || type === 'cluster') {
						const { cluster } = options;
						this.showYandexCluster(cluster);
					}
				}
				//'station:click': (...args) => this.trigger('map:station:click', ...args),
				//'line:click': (...args) => this.trigger('map:line:click', ...args),
			});
			//this.on('map:redraw', () => this.)
		}
		
			// new offersNs.OffersCollection();
		// this.collection.url = paths.api.realtiesOffer;
		this.filterObject = opts.filterObject;
		if (this.filterObject) {
			this.listenTo(this.filterObject, 'filters:apply', () => {
				// this.fetchOffers();
				if (!this.isRendered()) { return; }
				this.showTabContent('result');
				this.tabsLayout.swapTo('result');
			});
		}
		// this.fetchOffers();
	},
	fetchOffers() {
		const search = this.getFilterDataHash();
		this.collection.fetch({ search });
	},
	getFilterDataHash() {
		return this.filterObject?.getUrlHash() || {};
	},
	onRender: function () {
		this.showTabs();
		this.showTabContent('result');
	},
	showTabs: function () {
		var view = this.tabsLayout = new Tabs();
		this.showChildView('tabs', view);
	},

	childViewEvents: {
		'tab:click': function (tab) {
			this.showTabContent(tab);
		}
	},
	childViewTriggers: {
		'address:hover': 'address:hover',
		'address:unhover': 'address:unhover',
		'offer:hover': 'offer:hover',
		'offer:unhover': 'offer:unhover',
		//'metro:station:click': 'metro:station:click',
		'address:click': 'address:click',
		'interaction': 'sidebar:interaction',
		'sidebar:selection:removed':'sidebar:selection:removed'
	},

	showTabContent: function (tab, opts) {

		var view = tab instanceof BbView ? tab : this.getTabView(tab);
		if (!view) return;
		this.detachChildView('content');
		var doRefresh = view.isRendered();


		this.showChildView('content', view);
		if (doRefresh && view.refresh)
			view.refresh(opts);
	},
	getTabView: function (tab) {

		if (!this.tabViews) {
			this.tabViews = {};
		}
		
		var view = this.tabViews[tab];
		if (view) {
			// console.warn('exist tab view');
			return view;
		}

		view = this.buildTabView(tab);
		// console.warn('new tab view');
		this.tabViews[tab] = view;

		return view;
	},
	buildTabView: function (tab) {
		var views = this.getOption('views') || {};
		var context = views[tab];
		if (context.view) {
			return context.view;
		}
		else {
			const options = Object.assign({ collection: this.collection }, context.options);
			return new context.View(options);
		}
	},
	showNewOffers(type, data, rawdata, ext) {



		const options = Object.assign({
			type,
			data,
			rawdata
		}, ext);

		this._currentShowed = options;

		this.showOffers(options);

	},
	showYandexCluster(cluster) {
		const geoBounds = cluster.geoBounds()
	},
	async showNewCluster(options) {
		const realties = options.realties || {};

		const rawdata = options.data;
		const data = Object.assign({}, options.data);

		const overlaysGroup = options.overlays && options.overlays.length > 1;
		const fakeBounds = options.fakeBounds;

		const ids = Object.keys(realties);

		if (ids.length === 1) {
			data['bld.v'] = ids[0];
			this.showNewOffers('realty', data, rawdata, { realtyId: ids[0], overlays: options.overlays, realties });
		} else if (ids.length > 1) {

			const bounds = overlaysGroup ? fakeBounds : await buildBoundsAsync(realties);

			let overlays = overlaysGroup ? options.overlays : undefined;
			Object.assign(data, bounds);

			this.showNewOffers('cluster', data, rawdata, { overlays, realties });
		}
	},
	showNewStation(options) {
		const type = 'station';
		let rawdata = options.data;
		if (rawdata == null) {
			rawdata = this.getFilterDataHash();
		}
		const data = Object.assign({}, rawdata);
		const station = options.station;
		data['mls.v'] = station.id;
		this.showNewOffers(type, data, rawdata, { item: station });
	},
	showNewLine(options) {
		const type = 'line';
		let rawdata = options.data;
		if (rawdata == null) {
			rawdata = this.getFilterDataHash();
		}
		const data = Object.assign({}, rawdata);
		const line = options.line;
		data['ms.v'] = line.id;
		// data['mls.v'] = Object.keys(line.stations || {}).forEach((id, index) => {
		// 	data[`mls[${index}].v`] = id;
		// });
		this.showNewOffers(type, data, rawdata, { item: line });
	},
	showData: function (opts) {
		
		!opts && (opts = {});
		
		const { type } = opts;
		
		if (type === 'new:cluster') {
			return this.showNewCluster(opts);
		}
		else if (type === 'new:station') {
			this.trigger('sidebar:selection:removed');
			return this.showNewStation(opts);
		}
		else if (type === 'new:line') {
			this.trigger('sidebar:selection:removed');
			return this.showNewLine(opts);
		}

		!opts.data && (opts.data = {});

		var item = opts.item;
		opts.rawdata = _.extend({}, opts.data);
		if (opts.type == 'station') {
			opts.data['mls.v'] = opts.item.model.id;
		} else if (opts.type == 'cluster') {
			var ids = item.getRealtiesIds() || [];
			if (ids.length == 1) {
				opts.data['bld.v'] = ids[0];
				opts.type = 'realty';
				opts.realtyId = ids[0];
			} else if (ids.length > 1) {
				_.extend(opts.data, item.getBounds().toJSON());
				//console.log('ii>',opts.item, item.getBounds().toJSON());
			}
		} else if (opts.type == 'realty') {
			opts.data['bld.v'] = item;
			opts.realtyId = item;
		}
		else if (opts.type == 'poly') {
			_.extend(opts.data, item.poly.getBounds().toJSON());
			opts.viewFilter = function (v) {
				var latlng = getAddressLatlng(v.model.get('addressId'));
				return item.poly.contains(latlng);
			}
			//console.log('POLY', item);
		}
		console.log('#OPTS#', opts);
		this.showOffers(opts);
	},
	showOffers: function (opts) {
		this.showTabContent('result', opts);
		this.tabsLayout.swapTo('result');
	}
});


var OldLayout = MneView.extend({
	className: 'search-on-map',
	//template: '#tmpl-service-realties-searchOnMap-layout',
	template,
	regions: {
		'map': { el: '.map-wrapper', replaceElement: false },
		'actionsPanel': { el: '.actions-panel-left', replaceElement: true },
		'sidebar': { el: '.side-bar', replaceElement: true },
	},
	// initialize: function () {
	// 	//var _this = this;
	// 	//Bus.gmaps.reply('show:cluster:details', function () {
	// 	//	//_this.showClusterInfo.apply(_this, arguments);
	// 	//});
	// 	//Bus.gmaps.reply('show:poly:details', function () {
	// 	//	//_this.showPolyInfo.apply(_this, arguments);
	// 	//});
	// },
	initializeFilters: function () {

		if (this.filterObject) return;
		var filters = offersFilters.filters({ deal: this.getOption('deal') });

		var groups = offersFilters.groups();
		this.filterObject = Filters.create({
			groups: groups,
			filters: filters,
			changeHandlers: [
				offersFilters.changeHandler
			],
			deal: this.getOption('deal'),
		});
		console.log('## init filter', this.filterObject);
	},
	initSelectionHolder: function () {
		if (this.selectionHolder) return this.selectionHolder;
		this.selectionHolder = SelectionHolder.create('searchoffers');
		return this.selectionHolder;
	},
	onRender: function () {
		this.initializeFilters();
		this.initSelectionHolder();
		this.showMap();
		this.showSidebar();
		//this.showActionPanel();
	},
	_getDealMapContext() {
		var deal = this.getOption('deal');
		if (!deal) {
			return {};
			//console.log('deny', ids, offers);
		}
		var offers = deal.getOffers();
		let denySelect = offers.map(function (m) { return getValueByPath(m, 'realtyOffer.id'); }).filter(o => !!o);


		// var cfg = (deal.get('subject') || {});
		// let entry = {
		// 	realty: Bus.svc.request('buildings', cfg.realtyId),
		// 	realtyId: cfg.realtyId,
		// 	lat: cfg.lat,
		// 	lng: cfg.lng,
		// };
		const realtyId = getValueByPath(deal, 'subject.realtyId');
		const realty = getRealty(realtyId);
		const entry = Object.assign({}, deal.get('subject'), { realty });
		// console.log('deal map entry:', entry, realty);
		return { denySelect, entry };
	},
	showMap: function () {


		//let filterObject = this.filterObject;

		import('mapApi').then((esModule) => {

			let mapApi = esModule.default;

			var view = this.mapView = this.getMapApiView(mapApi);
			this.showChildView('map', view);
			view.whenReady.then(() => {
				console.log('mpavView ready, applying filterObject...');
				this.filterObject.apply();
			});

		});




	},
	getMapApiView(mapApi) {

		let { denySelect, entry } = this._getDealMapContext();

		return mapApi.getView({
			entry: entry,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			denySelectionFor: denySelect,
			fetchOnFirstIdle: false,
			controls: {
				// searchPlace: MapInput.extend({
				// 	className: 'map-control-searchPlace nested-pac-container',
				// 	doNotFocus: true,
				// 	// events: {
				// 	// 	'focus input': function (e) {
				// 	// 		console.log('- focused -');
				// 	// 		var cnt = $('.pac-container');
				// 	// 		$(e.target).parent().append(cnt);
				// 	// 	}
				// 	// },
				// 	inputAttributes: {
				// 		placeholder: 'название места',
				// 		title: 'перемещает карту на выбранное место'
				// 	}
				// }),
				// searchRealty: MapInput.extend({
				// 	initialize: function () {
				// 		this.searchInputed = _.debounce(this._searchInputed, 300).bind(this);
				// 		this.rest = realtiesNs.BuildingsRest.create();
				// 		this.listenTo(this.rest, 'data:arrived', this._onDataArrived);
				// 	},
				// 	className: 'map-control-searchRealty',
				// 	onRender: function () {
				// 		MapInput.prototype.onRender.apply(this, arguments);
				// 		var $inp = this.$('input');
				// 		$inp.off().on('input', this.searchInputed);
				// 		$inp.on('blur', this.destroyPopup.bind(this));
				// 	},
				// 	_searchInputed: function () {
				// 		let text = this.$('input').val();
				// 		this.rest.fetch({ data: { text: text } });
				// 	},
				// 	_onDataArrived: function (col) {
				// 		var pop = this.ensurePopup();
				// 		pop.collection.reset(col.models);
				// 	},
				// 	destroyPopup() {
				// 		var pv = this.popupView;
				// 		if (!pv) return;
				// 		var inp = this.$('input');
				// 		setTimeout(function () {
				// 			pv.destroy();
				// 			inp.val('');
				// 		}, 300);
				// 	},
				// 	ensurePopup: function () {
				// 		if (this.popupView && !this.popupView.isDestroyed()) {
				// 			return this.popupView;
				// 		} else {
				// 			let popup = this.popupView = new PopupView({
				// 				collection: new BbeCollection()
				// 			});
				// 			popup.render();
				// 			popup.$el.css('width', (this.$el.outerWidth() - 2) + 'px');
				// 			popup.$el.appendTo(this.$el);
				// 			this.listenTo(popup, 'searched:bld:click', this._onResultClick);
				// 			this.on('destroy', function () {
				// 				popup.destroy();
				// 			});
				// 		}
				// 		return this.popupView;
				// 	},
				// 	inputAttributes: {
				// 		placeholder: 'название бц/ск',
				// 		title: 'перемещает карту по адресу бц/ск'
				// 	},
				// 	_onResultClick(data) {
				// 		const view = this.getOption('mapView');
				// 		view.map.setCenter(data.coords);
				// 		view.map.setZoom(17);
				// 		var marker = new google.maps.Marker({
				// 			position: data.coords,
				// 			animation: google.maps.Animation.BOUNCE,
				// 		});
				// 		marker.setMap(view.map);
				// 		setTimeout(function () {
				// 			marker.setMap(null);
				// 			marker = null;
				// 		}, 3000);
				// 	},
				// }),
				searchRealty: true, // SearchRealtyControl,
				searchPlace: true, // SearchPlaceControl,
				searchOnMapButtons: true,
			},
		});
	},

	showActionPanel: function () {
		return;
		/*
		var selectedActions = this.getSelectedActions();
		var actionspanel = new ActionsPanel({
			className: 'actions-panel actions-panel-left',
			statsView: undefined,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			selectedComparators: {
				square: function (a, b) {
					return bbeViewComparator([
						[a, b, function () { return this.model.get('object').info.squareOffer; }]
					]);
				}
			},
			selectedActions: selectedActions,
			fastFiltersView: FastFilters.create({
				filters: this.filterObject,
			}),
		});

		this.showChildView('actionsPanel', actionspanel);
		*/
	},
	showClusterInfo: function (cluster, bld) {

		if (this.showedCluster)
			this.showedCluster.trigger('unselect');

		cluster.trigger('select');
		this.showedCluster = cluster;

		//console.log('cluster', cluster)

		var selectedActions = this.getSelectedActions();
		var view = new ClusterInfo({
			model: cluster,
			deal: this.getOption('deal'),
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			selectedComparators: {
				square: function (a, b) {
					return bbeViewComparator([
						[a, b, function () { return this.model.get('object').info.squareOffer; }]
					]);
				}
			},
			selectedActions: selectedActions,
			realtyId: bld,
		});
		this.showChildView('actionsPanel', view);
	},
	showPolyInfo: function (polyObj) {
		if (this.showedCluster) {
			this.showedCluster.trigger('unselect');
			delete this.showedCluster;
		}
		var selectedActions = this.getSelectedActions();
		var view = new PolyInfo({
			//model: cluster,
			poly: polyObj,
			deal: this.getOption('deal'),
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			selectedComparators: {
				square: function (a, b) {
					return bbeViewComparator([
						[a, b, function () { return this.model.get('object').info.squareOffer; }]
					]);
				}
			},
			selectedActions: selectedActions,
			//realtyId: bld,
		});
		this.showChildView('actionsPanel', view);
		//console.log('_this', polyObj);

	},
	onChildviewReturnFilters: function () {
		if (this.showedCluster)
			this.showedCluster.trigger('unselect');
		this.showActionPanel();
	},

	// actionSendAsEmail: SendByEmail.actionSendByEmail,
	// actionCreatePdf (offers) {
	// 	offers = offers.map(arg => typeof arg === 'string' ? arg : arg.id);
	// 	pdfApi.showBlocks(offers);

	// },
	// getSelectedActions: function () {
	// 	var selectedActions = [];
	// 	var deal = this.getOption('deal');
	// 	if (deal)
	// 		selectedActions.push({ text: "прикрепить отобранное к процессу", action: 'joinOffersToDeal', actionOn: deal });

	// 	selectedActions.push({ text: "отправить по email", action: 'sendAsEmail', actionOn: this });
	// 	selectedActions.push({ text: "сформировать PDF", action: 'createPdf', actionOn: this });

	// 	return selectedActions;
	// },

	...actionsMixin,

	showSidebar() {

		var selectedActions = this.getActionsPanelActions();
		var selectedComparators = {
			square: function (a, b) {
				return bbeViewComparator([
					[a, b, function () { return this.model.get('object').info.squareOffer; }]
				]);
			}
		};
		var selectionHolder = this.selectionHolder;

		var view = this.sideBar = new SideBar({
			filterObject: this.filterObject,
			views: {
				result: {
					View: ResultView,
					options: {
						filterObject: this.filterObject,
						//data: this.filterObject.getUrlHash(),
						selectionHolder: selectionHolder,
					}
				},
				filters: {
					view: FastFilters.create({
						filters: this.filterObject,
					}),
				},
				selected: {
					view: new SelectedPane({
						selectionHolder: this.selectionHolder,
						filterObject: this.filterObject,
						selectedComparators: selectedComparators,
						selectedActions: selectedActions,
					})
				}
			}
		});
		this.showChildView('sidebar', view);
	},
	onChildviewMapEventsDisable: function () {
		this.mapView.disableMapEvents();
	},
	onChildviewMapEventsEnable: function () {
		this.mapView.enableMapEvents();
	},


	onChildviewMapClickThing: function (type, item) {
		//debugger;
		this.sideBar.showData({
			type: type,
			item: item,
			data: this.filterObject.getUrlHash(),
		});

	},

	childViewEvents: {
		'address:hover': function (address) {
			this.mapView.triggerAddressHover(address);
			//console.log('address hover', address);
		},
		'address:unhover': function (address) {
			this.mapView.triggerAddressUnhover(address);
			//console.log('address unhover', address);
		},
		'metro:station:click': function (id) {
			this.mapView.triggerMetroStationClick(id);
		},
		'address:click': function (address) {
			this.mapView.triggerAddressClick(address);
		}
	},

});

var NewLayout = View.extend({

	...actionsMixin,
	DBGNAME: 'new-map-layout',
	classNames: ['search-on-map'],
	stateClassNames: ['sidebar-on'],
	template,
	regions: {
		'map': { el: '.map-wrapper', replaceElement: false },
		'actionsPanel': { el: '.actions-panel-left', replaceElement: true },
		'sidebar': { el: '.side-bar', replaceElement: true },
	},
	useNextMapApi: true,

	initialize() {
		const wideEnough = isMapWithAside();
		this.state('sidebar-on', wideEnough);
		this.updateClassName();
		// this.on('state:sidebar-on', () => {
		// 	const show = !!this.state('sidebar-on');
		// 	if (show) {
		// 		this.showSidebar();
		// 	} else {
		// 		this.hideSidebar();
		// 	}
		// });
		
		this.DBGNAME = 'new-map-layout';
		this.asideModel = new SideBarModel();
		this.initializeFilters();
		this.initSelectionHolder();
		this.initSearchContext();
		if (this.useNextMapApi) {
			this.initializeMapApiV2();
		}
	},

	initializeFilters: function () {

		if (this.filterObject) return;

		var filters = offersFilters.filters({ deal: this.getOption('deal') });

		var groups = offersFilters.groups();

		this.filterObject = Filters.create({
			groups: groups,
			filters: filters,
			changeHandlers: [
				offersFilters.changeHandler
			],
			deal: this.getOption('deal'),
		});

		//console.error('## init filter', this.filterObject);

	},

	initSelectionHolder: function () {
		if (this.selectionHolder) return this.selectionHolder;
		this.selectionHolder = SelectionHolder.create('searchoffers');
		// this.selectionHolder.on('all', e => console.warn('-st-', e));
		// console.error('holder', this.selectionHolder);
		return this.selectionHolder;
	},

	initializeMapApiV2() {
		
		this.mapApi = undefined;
		const filter = this.filterObject;
		// this.mapFilters = mapsApi.createMapFilters();
		const mapFiltersOptions = { 
			getFiltersHash: () => filter.getUrlHash(), 
			method: 'get',
			setup: mapFilters => {
				mapFilters.listenTo(filter, 'filters:apply', mapFilters.updateFilters)
			} 
		}
		const selectionHolder = this.selectionHolder;
		
		let { denySelect, entry, existRealtyIds = {} } = this._getDealMapContext();
		
		this.mapApi = mapsApi.createSearchApi({ mapFiltersOptions, selectionHolder, entry, existRealtyIds, denySelect, asideModel: this.asideModel });
		this.mapApi.on('all', e => console.warn('[map]', e));
		this.listenTo(this.mapApi, 'map:click', (...args) => {
			console.log('MAPCLICK! =')
			if (this.state('sidebar-on')) { return; }
			this.toggleSidebar({ initialMapClick: args });
		});
	},

	initSearchContext() {

		const deal = this.getOption('deal');
		if (!deal) { return; }

		const offers = deal.getOffers();
		this.joinedOffers = offers.reduce((memo, offer) => {
			// console.warn('#', offer);
			const roff = offer.get('realtyOffer');
			if (roff) {
				memo[roff.id] = 1;
			}
			return memo;
		}, {});

		// this.searchContext = new SearchContext({ 
		// 	selectionHolder: this.selectionHolder
		// });
	},
	onRender() {
		delete this._mapContainerSized;
		if (this.useNextMapApi) {
			this.showNextMap();
		} else {
			this.showOldMap();
		}
		this.showSidebar();
		this._mapContainerSized = true;
	},
	_old_showSidebar() {
		// if (1 === 1) { return; }
		var selectedActions = this.getActionsPanelActions();
		var selectedComparators = {
			square: function (a, b) {
				return bbeViewComparator([
					[a, b, function () { return this.model.get('object').info.squareOffer; }]
				]);
			}
		};
		var selectionHolder = this.selectionHolder;
		//console.error('-COLL?-', this.collection);
		var view = this.sideBar = new OldSideBar({
			useNextMapApi:  this.useNextMapApi,
			mapApi: this.mapApi,
			filterObject: this.filterObject,
			views: {
				result: {
					View: ResultView,
					options: {
						filterObject: this.filterObject,
						//data: this.filterObject.getUrlHash(),
						selectionHolder: selectionHolder,
						joinedOffers: this.joinedOffers
					}
				},
				filters: {
					view: new FastFilters({
						filters: this.filterObject,
					}),
				},
				selected: {
					view: new SelectedPane({
						selectionHolder: this.selectionHolder,
						filterObject: this.filterObject,
						selectedComparators: selectedComparators,
						selectedActions: selectedActions,
					})
				}
			}
		});
		this.showChildView('sidebar', view);		
	},
	showSidebar(additional) {
		
		if (!this.state('sidebar-on')) { return; }
		const filterObject = this.filterObject;
		const selectionHolder = this.selectionHolder;
		const selectedActions = this.getActionsPanelActions();
		const selectedComparators = {
			square: function (a, b) {
				return bbeViewComparator([
					[a, b, function () { return this.model.get('object').info.squareOffer; }]
				]);
			}
		};
		const options = Object.assign({
			model: this.asideModel,
			mapApi: this.mapApi,
			filterObject,
			selectedActions,
			selectionHolder,
			selectedComparators,
			joinedOffers: this.joinedOffers
		}, additional)
		const view = this.sideBar = new SideBar(options);
		this.showChildView('sidebar', view);
	},
	hideSidebar() {
		const reg = this.getRegion('sidebar');
		if (reg) {
			reg.empty();
		}
	},
	async showNextMap() {
		// let { denySelect, entry, existRealtyIds } = this._getDealMapContext();
		const entryRealtyId = this.mapApi.mapStateData.entryRealtyId;
		const realtyPromise = entryRealtyId ? getRealtyAsync(entryRealtyId) : Promise.resolve();

		const res = await mapsApi.whenReady();
		if (!res.ok || this.isDestroyed()) { return; }

		await realtyPromise;


		const view = new MapView({
			// entry: entry,
			// existRealtyIds,
			// denySelectionFor: denySelect,
			mapApi: this.mapApi,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			joinedOffers: this.joinedOffers
		});
		this.nextMapView = view;

		//this.mapApi.on('map:ready', map => this.map = map);
		view.on('map:attach', map => this.map = map);

		this.showChildView('map', view, { replaceElement: true });

		// mapsApi.whenReady(() => {
		// });

	},
	showOldMap() {
		import('mapApi').then((esModule) => {

			let mapApi = esModule.default;

			var view = this.mapView = this.getMapApiView(mapApi);
			if (view) {
				this.showChildView('map', view);
			}

			// view.whenReady.then(() => {
			// 	console.log('mpavView ready, applying filterObject...');
			// 	this.filterObject.apply();
			// });

		});		
	},
	getMapApiView(mapApi) {
		let { denySelect, entry, existRealtyIds } = this._getDealMapContext();

		const toggleOfferHighlight = (api, offer, state) => {
			const id = (offer.attributes || offer).realtyId;
			const view = api.realtyClusterViews.get(id);
			if (view) {
				view.state('highlighted', state);
			}
		}

		return mapApi.getViewVer2({
			entry: entry,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			denySelectionFor: denySelect,
			fetchOnFirstIdle: false,
			existRealtyIds,
			controls: {
				searchRealty: true, // SearchRealtyControl,
				searchPlace: true, // SearchPlaceControl,
				searchOnMapButtons: true,
			},
			onOfferHover(offer) {
				toggleOfferHighlight(this.mapApi, offer, true);
			},
			onOfferUnhover(offer) {
				toggleOfferHighlight(this.mapApi, offer, false);
			}
		});
	},
	_getDealMapContext() {
		
		var deal = this.getOption('deal');
		if (!deal) { return {}; }

		var offers = deal.getOffers();
		console.warn('deal-ctx', offers);

		const denySelectResult = [];
		const existRealtyIds = {};
		const add = Array.isArray(denySelectResult)
			? (arg, value) => arg.push(value)
			: (arg, value) => arg[value] = 1;

		let denySelect = offers.reduce((arr, off) => {

			// o = o.attributes || o;
			// if (o.realtyOffer) {
			// 	existRealtyIds[o.realtyOffer.realtyId] = 1;
			// }
			// const id = o.realtyOffer?.id;
			// if (id) { add(arr, id); }
			const roff = off.get('realtyOffer');
			if (!roff || !roff.id) { return arr; }
			existRealtyIds[roff.realtyId] = 1;
			arr.push(roff.id)
			return arr;
		}, []);

		//.map(function (m) { return getValueByPath(m, 'realtyOffer.id'); }).filter(o => !!o);
		const realtyId = getValueByPath(deal, 'subject.realtyId');
		const realty = getRealty(realtyId);
		const entry = Object.assign({}, deal.get('subject'), { realty });
		const entryRealtyId = entry?.realtyId;
		console.warn('-deal-ctx-', existRealtyIds, denySelect);

		return { denySelect, entry, entryRealtyId, existRealtyIds };

	},
	childViewEvents: {
		'map:cluster:click'(realties, overlays, fakeBounds) {
			this.sideBar.showData({
				type: 'new:cluster',
				realties,
				overlays,
				fakeBounds,
				data: this.filterObject.getUrlHash(),
			});
		},
		'map:station:click'(station) {
			this.sideBar.showData({
				type: 'new:station',
				station,
				data: this.filterObject.getUrlHash(),
			});
		},
		'map:line:click'(line) {
			this.sideBar.showData({
				type: 'new:line',
				line,
				data: this.filterObject.getUrlHash(),
			});
		},
		'sidebar:interaction'(eventType, entityType, entity) {
			if (this.mapView) {
				console.warn('sidebar:interaction', eventType, entityType, entity);
				this.mapView.triggerMethod(`${entityType}:${eventType}`, entity);
				return;
			}

			if (this.useNextMapApi) {
				if (eventType.endsWith('hover') && (entityType in {offer: 1, realty: 1})) {
					const state = eventType === 'hover';
					const realty = entityType === 'realty' ? entity : undefined;
					const offer = entityType === 'offer' ? entity : undefined;
					this.mapApi.highlightCluster({ realty, offer }, state);
					return;
				}
				console.warn('missing handler', entityType, eventType);
				// this.nextMapView.triggerMethod(`sidebar:${entityType}:${eventType}`, entity);
			}

		},
		'sidebar:selection:removed'() {
			if (this.useNextMapApi) {
				this.mapApi.unselectSelected();
			} else {
				this.mapView.mapApi.trigger('sidebar:selection:removed');
			}
		},
		'sidebar:inner:close'() {
			if (isAsidelessMap()) {
				this._hideSidebar();
			}
		}
		// 'map:redraw'() {
		// 	if (!this.map) {
		// 		this.map.redraw();
		// 	}
		// }
		// 'offer:hover': function (offer) {
		// 	this.mapView.triggerMethod('offer:hover', offer);
		// 	//console.log('address hover', address);
		// },
		// 'offer:unhover': function (offer) {
		// 	this.mapView.triggerMethod('offer:unhover', offer);
		// 	//console.log('address unhover', address);
		// },
	},
	events: {
		'click .toggle-sidebar'() {
			this.toggleSidebar();
			//this.state('sidebar-on', !this.state('sidebar-on'));
			//this.render();
			//this.filterObject.apply();
			//mapsApi.startSearchApi();
		}
	},
	toggleSidebar(opts) {
		const shouldShow = !this.state('sidebar-on');
		//this.state('sidebar-on', shouldShow);
		if (shouldShow) {
			this._showSidebar(opts);
		} else {
			this._hideSidebar();
		}
	},
	_showSidebar(opts) {
		this.state('sidebar-on', true);
		this.showSidebar(opts);
	},
	_hideSidebar() {
		this.state('sidebar-on', false);
		this.hideSidebar();
	}
});

const Layout = NewLayout;

export default Layout;
