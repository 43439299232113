import { CollectionView, Collection } from 'core';
import { ContactsListItem, NoContactsView } from './ContactsListItem';

export const ContactsList = CollectionView.extend({
	baseClassName: 'contacts-list',
	initialize() {
		this.initializeCollection();
	},
	initializeCollection() {
		if (!this.collection) { 
			const raw = this.getOption('contacts')
			this.collection = new Collection(raw);
		}
		//this.listenTo(this.collection, 'all', e => console.log('[#-col:]', e));
	},
	collectionEvents: {
		'refresh'() {
			this.sort();
		}
	},
	onSetup() {
		this.triggerMethod('collection:initialized', this.collection);
	},
	emptyView: NoContactsView,
	childView: ContactsListItem,
	childViewTriggers: {
		'show:details':'show:details',
			//openContactDetailsInModal(this.model);
	},
	// viewComparator(v1, v2) {
	// 	let m1 = v1.model.attributes;
	// 	let m2 = v2.model.attributes;
	// 	if (m1.primary !== m2.primary) {
	// 		return m1.primary > m2.primary ? -1 : 1;
	// 	}
	// 	let vl1 = getContactScore(m1.values);
	// 	let vl2 = getContactScore(m2.values);
	// 	return vl2 - vl1;
	// }
});

// function getContactScore(vals) {
// 	return vals.reduce((score, item) => getContactItemScore(item) + score, 0);
// }
// function getContactItemScore(item) {
// 	return contactTypeScores[item.type] || 0;
// }
// const contactTypeScores = {
// 	other: 0,
// 	phone: 300,
// 	mobilePhone: 400,
// 	email: 20,
// 	messenger: 0,
// 	site: 1,
// 	social: 0,
// }