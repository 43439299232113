﻿// define('svc-clients-client-layout', [
// 	'bus', 'ui-layouts-logsBoardList', 'ui-layouts-commentBoardList', 'link', 'm-deals', 'svc-clients-client-deals', 
// 	'svc-clients-client-contacts', 'm-contacts', 'ui-layouts-contactsList', 'ui-layouts-dataroleActions', 'ui-controls-taskBoardList']
// , function (Bus, LogsBoardList, CommentBoardList, link, dealsNs, Deals, Contacts, contactsNs, ContactsList, DataroleActions, TaskBoardList) {});

import Bus from 'bus';
import LogsBoardList from 'ui/layouts/logsBoardList';
import CommentBoardList from 'ui/layouts/commentBoardList';
//import link from '';
//import dealsNs from '';
import Deals from './deals';
//import Contacts from '';
//import contactsNs from '';
import ContactsList from 'ui/layouts/contactsList';
import DataroleActions from 'ui/layouts/dataroleActions';
import TaskBoardList from 'ui/controls/taskBoardList';
import template from './layout.html';

import { MneView } from 'core/mne';
import { ClientInfo } from './info';

import { handleAddTagClick, showTagsList } from 'coms/ui/Tags';
import { ClientContactsBox } from 'mods/entity-contacts/views/ClientContactsBox';
import { backendApi } from 'apis';
import { urls } from 'coms/urls';

var View = MneView.extend({
	//template: '#tmpl-service-clients-client-layout',
	template,
	regions: {
		'tasks': { el: '.tasks-region', replaceElement: true },
		'actions': { el: '.task-actions', replaceElement: true },
		'info': { el: '.info-region', replaceElement: true },
		'currentDeals': { el: '.current-deals', replaceElement: true },
		'closedDeals': { el: '.closed-deals', replaceElement: true, },
		'contacts': { el: '.contacts', replaceElement: true, },
		'comments': '.board-list-region.comments',
		'logs': '.board-list-region.logs',
		'tags': '.tags-container'
	},
	onRender: function () {
		this.showActionsLayout();
		this.showDeals();
		this.showCommentsLayout();
		this.showLogsLayout();
		this.showContacts();
		this.showInfo();
		this.showTaskView();
		this.showTags();
	},
	events: {
		'click .add-new-tag'(event) {
			handleAddTagClick(this, event)
		}
	},
	showTags() {
		showTagsList(this, { url: 'v4/tags/clients/' + this.model.id });
	},
	showTaskView: function() {
		var tasksview = new TaskBoardList({ entity: 'client', entityId: this.model.get('id') });
		this.showChildView('tasks', tasksview);
	},
	showInfo: function () {
		var View = ClientInfo;
		// Bus.Views.request('client:info');
		var view = new View({ model: this.model, className: 'block block-btn' });
		this.showChildView('info', view);
	},
	showContacts: function () {
		//var entries = this.model.getContacts();
		console.log(this.model);
		
		let contactsUrl = urls.api(`v4/clients/${this.model.id}/contacts?view=view`)
		//baseUrl + '/contacts?view=view';
		const view = new ClientContactsBox({
			header: 'контакты',
			contacts: this.model.get('contacts'),
			client: this.model,
			clientId: this.model.id,
			async refreshData() {
				const rawContactsRes = await backendApi.getAsync(contactsUrl);
				if (rawContactsRes.ok) {
					this.collection.reset(rawContactsRes.value);
					this.collection.trigger('after:edit');
				}
				console.error('refreshed', rawContacts);
			}
		});
		// var view = new ContactsList({
		// 	collection: entries,
		// });
		this.showChildView('contacts', view);
	},
	showDeals: function () {
		var deals = this.model.getDeals();
		//console.log('DEALS', deals);
		var curDeals = new Deals({
			collection: deals
			, viewFilter: function (v) {
				return v.model.isNotCompleted();
			}
		});
		var closedDeals = new Deals({
			collection: deals
			, viewFilter: function (v) {
				return !v.model.isNotCompleted();
			}
		});

		this.showChildView('currentDeals', curDeals);
		this.showChildView('closedDeals', closedDeals);



	},
	showActionsLayout: function () {
		
		var filter = function (act) {
			return act.type != 'openClientPage'
		}

		let model = this.model;

		var view = DataroleActions.create({ model: model, layoutType: 'board-list-block', actionsFilter: filter });
		if (view)
			this.showChildView('actions', view);
	},
	showCommentsLayout: function () {
		//var entityId = this.model.get('id');
		var view = new CommentBoardList({ entity: 'client', entityId: this.model.get('id') });
		this.showChildView('comments', view);
	},
	showLogsLayout: function () {
		var view = new LogsBoardList({ entity: 'client', entityId: this.model.get('id') });
		this.showChildView('logs', view);
	},

	templateContextExtend: function () {
		return {
		}
	}
	
});


Bus.Views.reply('client:card', function () {
	return View;
});

Bus.Views.reply('client:page', function () {
	return View;
});

export default View;
