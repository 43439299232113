import { View } from 'core';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { BoxView } from 'coms/ui/Box';
import { getBuildingImagesCollection } from 'm/realties/buildings';
import { showImageGalery, ImageView } from 'coms/ImageGalery';

import { AsyncModelBoxView, AsyncModelBoxWraperView } from 'coms/ui/Box';

// import './realty-box-thumb.less';
// import './realty-name.less';

import { RealtyOfferPropertiesView, RealtyOfferMainPropertiesView } from './properties-lines';
import { realtyOffersStore } from '../store';
//import { modelSchema } from '../schema';
//import { imageModelComparator } from 'coms/ImageGalery';
import { getSchema } from 'apis/schema';
import { ContactsBox } from 'coms/ui/ContactsBox';
import { OwnerContactsBox } from '../../../entity-contacts';
// deprecated
// const commonMixin = {
// 	initialize(options) {
//         this.initializeModel();
// 	},
//     initializeModel() {
//         let id;
//         if (this.model) {
//             id = this.model.id;
//         } else {
//             id = this.getOption('offerId', true);
//         }
//         this.model = realtyOffersStore.get(id, true);
//         this.syncModels();
//     },
//     async syncModels() {
//         let schema = getSchema(this.model);
//         try {

//             await this.model.whenFetched;

//             if (schema) {
//                 await schema.whenBackendSchemaFetched;
//             }
//             this.triggerMethod('data:ready', this.model);

//         } catch(exc) {
//             console.error('-syncModels', exc);
//         }
//     }
// }



export const RealtyOfferBoxView = BoxView.extend({
	thisClassName: 'realty-offer-box',
	content: v => [		
		RealtyOfferPropertiesView
	],
    // ...commonMixin,
	onDataReady() {
			//this.getOption('')
			console.warn('data:ready', this.model);
			this.render();
	}
});

// deprecated
// export const _RealtyOfferBoxViewWraper = HamburgerView.extend({
//     thisClassName: 'realty-offer-box-holder',
// 	childrenViews: v => [RealtyOfferBoxView],
//     // ...commonMixin,
//     onDataReady() {
//         console.warn('another:data:ready', this.model);
//         this.model.attributes.object = this.getOption('realtyObject');
//         this.model.attributes.realty = this.getOption('realty');
//         // console.log(this.getOption('realtyObject'), this.getOption('realty'));
//     }
// });


export const RealtyOfferBoxViewWraper = AsyncModelBoxWraperView.extend({
  thisClassName: 'realty-offer-box-holder',
	// childViewOptions() {
	// 	return {
	// 		thisClassName: 'realty-offer-box',
	// 		content: [
	// 			RealtyOfferPropertiesView
	// 		],
	// 	}
	// },
	boxView: [
		{
			content: RealtyOfferMainPropertiesView
		},
		{
			content: RealtyOfferPropertiesView
		},
		v => v.getContactsBox()
	],
	getContactsBox() {
		const model = this.getOption('contactsInRealty');
		const contacts = model.get('contactsInRealty');
		const entity = {
			typeText: 'Собственник',
			type: 'owner',
			name: model.get('owner')?.name
		}
		const ownerId = this.getOption('ownerId')
		const realtyId = this.getOption('realtyId')
		return {
			class: OwnerContactsBox,
			entity,
			contacts,
			ownerId, realtyId,
			async refreshData() {
				await model.fetch();
				const raw = model.get('contactsInRealty') || [];
				this.collection.reset(raw);
				this.collection.trigger('after:edit');
				console.error('refreshed', model.attributes, this.collection);
			}
		}
	},
	initializeModel() {
		const id = this.getOption('offerId');
		this.model = realtyOffersStore.get(id, true);
		console.log('#initialized model#', this.model);
  },
	onDataReady() {
		console.warn('another:data:ready', this.model);
		this.model.attributes.object = this.getOption('realtyObject');
		this.model.attributes.realty = this.getOption('realty');
		// console.log(this.getOption('realtyObject'), this.getOption('realty'));
	}
});