import { ContactsList } from "./ContactsList";
import { View } from 'core';
import { openAddOrJoinDialogue } from "./utils";
import { addContactInModalAsync } from "mods/contacts";


export const ContactsInfoView = View.extend({
	baseClassName: 'inner',
	template: `<div class="icon">
		<i class="glyphicon glyphicon-user"></i>
		<button class="add-or-join"><i class="glyphicon glyphicon-plus"></i></button>
	</div>
	<div class="contacts contacts-container"></div>`,
	regions: {
		contacts: '.contacts'
	},
	onRender() {
		let view = new ContactsList({ model: this.model });
		this.contactsCollection = view.collection;
		this.showChildView('contacts', view);
	},
	events: {
		'click .add-or-join'() {
			return this.handleAddClick()
		}
	},
	async handleAddClick() {
		const { ownerId, realtyId } =  this.model.attributes;
		const res = await addContactInModalAsync(this.model, { ownerId, realtyId, ownerInRealty: this.model });
		if (res) {
			await this.contactsCollection.fetch();
		}
		console.log('[EDIT RESULT]', res);
	}
});