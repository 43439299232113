import { Collection } from 'core';
import { ClusterModel } from './ClusterModel';
import { MapCollection } from './common';
import { MapPointModel } from './MapPointModel';

export const ClustersCollection = MapCollection.extend({

	model: ClusterModel,

	defaultGrid: {
		rows: 10,
		cells: 10
	},

	clear() {
		this.points.invoke('hideMarker');
		this.setPoints();
	},

	initialize(attrs, options = {}) {
		console.log('clusters', this);
		this.mapStateData = options.mapStateData;
		this.fetchData = options.fetchData;
		this.points = new MapCollection(undefined, { map: this.map, model: MapPointModel, mergeKeys: ['mapStateData'], mapStateData: this.mapStateData });
		this._setGrid(options.grid);
		this.updateBounds();
		const toggle = (event, opts = {}) => {

			const { cluster, point, line, station } = opts;
			const lineId = line?.id;
			const stationId = station?.id;
			const realtyId = point?.id;
			const bounds = !realtyId && cluster && cluster.bounds();
			const selectedOptions = { lineId, stationId, realtyId, bounds };

			const prev = this.mapStateData.setSelected(selectedOptions);
			this._unselectClusters(!realtyId && cluster);
			this._updateRealtyPoint(prev.realtyId);
			this._updateRealtyPoint(point?.id);

		}
		this.on({
			'click:point click:cluster': toggle,
		});
		// this.on('click:point click:cluster', cluster => {
		// 	const bounds = cluster.bounds();
		// 	this.mapStateData.setSelectedBounds(bounds);
		// 	this._unselectSelected(cluster);
		// });
		this.listenTo(this.mapStateData, 'unselect:all', () => {
			const prev = this.mapStateData.setSelected({});
			this._unselectClusters();
			this._updateRealtyPoint(prev.realtyId);
		});
	},

	toggleClusters(tru) {
		//const method = tru ? 'updateMarker' : 'hideMarker';
		
		if (tru) {
			this.setPoints(this._lastFetchedData);
		} else {
			this.clear();
		}
	},

	_unselectClusters(cluster) {
		const selected = this.where({ selected: true }).map(m => ({ id: m.id, selected: false }));
		if (cluster) {
			selected.push({ id: cluster.id, selected: true });
		}
		this.set(selected, { merge: true, remove: false });
	},
	_updateRealtyPoint(realtyId) {
		const point = this.points.get(realtyId);
		point && point.updateMarker();
	},
	//изменение сетки. всё стереть. всё перерисовать.
	setGrid(grid) {
		this.clearClusters();
		this._setGrid(grid);
		this.updateBounds();
		this.setPoints(this._lastFetchedData);
	},

	_setGrid(grid) {
		grid = grid || this.defaultGrid;
		if (typeof grid === 'number') {
			grid = { cells: grid, rows: grid };
		}
		const { rows, cells } = grid;
		this.grid = {
			rows,
			cells,
			count: cells * rows,
		}
	},

	// clusters must be clear
	updateBounds() {
		console.log('[clusters] updateBounds');
		let newaspect = this._updateBounds();
		this._updateClusters(newaspect);
	},

	_updateBounds() {
		// яндекс боундинг бокс [[северо-запад], [юго-восток]] top-left, bottom-right
		const bounds = this.map.bounds;
		const size = this.map.size;
		console.error('[_updateBounds]', size);
		const [minLngMaxLat, maxLngMinLat] = bounds;
		const [minLng, maxLat] = minLngMaxLat;
		const [maxLng, minLat] = maxLngMinLat;
		this.bounds = {
			minLat, minLng, maxLat, maxLng,		
		}
		const newaspect = this._newAspectDetected = this.clusterConfig?.sizeX !== size.x || this.clusterConfig?.sizeY !== size.y;
		this.clusterConfig = {
			latStep: (maxLat - minLat) / this.grid.rows,
			lngStep: (maxLng - minLng) / this.grid.cells,
			widthStep: size.x / this.grid.cells,
			heightStep: size.y / this.grid.rows,
			sizeX: size.x,
			sizeY: size.y
		}
		return newaspect;
	},

	_buildClusterModels() {
		let models = [];
		let id = 0;
		console.error('[_buildClusterModels]', this.clusterConfig);
		for(let y = 0; y < this.grid.rows; y++) {
			for (let x = 0; x < this.grid.cells; x++) {
				const model = this._buildClusterModel(id, y, x);
				models.push(model);
				id++;
			}	
		}
		return models;
	},

	_buildClusterModel(id, y, x) {
		const { lngStep, latStep } = this.clusterConfig;
		const minLng = this.bounds.minLng + lngStep * x;
		const maxLng = minLng + lngStep;
		const minLat = this.bounds.minLat + latStep * y;
		const maxLat = minLat + latStep;
		const model = {
			id: id,
			row: y,
			cell: x,
			minLng,
			minLat,
			maxLng,
			maxLat,
			width: this.clusterConfig.widthStep,
			height: this.clusterConfig.heightStep,
			topLeftLat: maxLat,
			topLeftLng: minLng,
			centerLng: minLng + (lngStep/2),
			centerLat: minLat + (latStep/2),
			selected: this.mapStateData.isSelectedBoundsIntersects({ minLng, minLat, maxLng, maxLat})
		}
		return model;
	},

	_updateClusters(newaspect) {
		console.error('[_updateClusters] start', this.length, 'newaspect:', newaspect, this.clusterConfig);
		if (newaspect) {
			this.clearClusters();
			this.reset();
		}
		const models = this._buildClusterModels();
		// this.once('update', (col, options = {}) => console.log('ON_UPDATE', options))
		// this.clear();
		let res = this.set(models, { merge: true, grid: this.grid, mapStateData: this.mapStateData, mergeKeys: ['mapStateData'] });

		console.error('[_updateClusters] end', this.length, res);
	},



	setPoints(data = {}) {
		const clustersData = {};

		// if (this.mapStateData.entryRealtyId) {
		// 	const rid = this.mapStateData.entryRealtyId;
		// 	if (rid in data === false) {
		// 		data
		// 	}
		// }

		for (let id in data) {
			const clusterId = this.getClusterId(data[id].coo);
			const raw = Object.assign({ id }, data[id]);
			const model = this.points.add(raw, { merge: true, mapStateData: this.mapStateData, mergeKeys: ['mapStateData'] });

			if (clusterId != null) {
				let points = clustersData[clusterId];
				if (!points) {
					points = [];
					clustersData[clusterId] = points;
				}
				points.push(model);
			} else {
				console.warn('clusterid not found', clusterId, id, data[id]);
			}
		}

		for (let model of this.models) {
			model.setPoints(clustersData[model.id]);
		}

	},

	getClusterId(ll) {
		if (!ll) { return; }
		
		const [ lng, lat ] = ll;
		const { lngStep, latStep } = this.clusterConfig;
		const b = this.bounds;
		const inBounds = (lat >= b.minLat && lat <= b.maxLat) && (lng >= b.minLng && lng <= b.maxLng);
		if (!inBounds) {
			console.warn('not in bounds', lng, (lng >= b.minLng && lat <= b.maxLng), lat, (lat >= b.minLat && lat <= b.maxLat), b);
			return;
		}

		const y = Math.floor((lat - b.minLat) / latStep);
		const x = Math.floor((lng - b.minLng) / lngStep);
		const id = (this.grid.rows * y) + x; //(this.grid.cells * x);

		return id;

	},

	toggleCluster(id) {
		const cluster = this.get(id);
		cluster?.togglePoints();
	},


	getRealtyCluster(realtyId) {
		for(let model of this.models) {
			if (model.hasRealty(realtyId)) {
				return model;
			}
		}
	},
	getRealtyPoint(realtyId) {
		return this.points.get(realtyId);
	},

	toggleRealty(realtyId, selected) {
		const cluster = this.getRealtyCluster(realtyId);
		if (!cluster) {
			console.warn('cluster not found for:', realtyId);
			return;
		}
		cluster.toggleRealty(selected);
	},



	clearClusters(options) {
		console.error('[clearClusters]');
		for (let model of this.models) {
			model.clearPoints(options);
		}		
	},

	async fetchClusters(options) {
		console.log('[clusters] before:fetch', options?.clearClusters, options?.showOnMap);
		this.trigger('before:fetch');
		if (options?.clearClusters) {
			this.clearClusters();
		}
		this.updateBounds();
		const data = await this.fetchData(options);		
		this._lastFetchedData = data;
		if (options?.showOnMap) {
			this.setPoints(data);
		}
		console.log('[clusters] fetch');
		this.trigger('fetch');
		// console.warn('CLUSTERS', this);
	}


});


