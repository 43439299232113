import { urls } from 'coms/urls';
import { backendApi } from 'apis/backend';
import { modalsApi } from 'apis/modalsApi2';
import { View, Collection } from 'core';
import { ContactsList } from 'coms/ui/ContactsBox';
import { EntityContactsListItem } from 'mods/entity-contacts/views/EntityContactsListItem';
import { NextIconButtonView } from 'coms/ui/Button';
import { UiAtomView } from 'coms/ui/UiAtomView';
import './add-or-join-dialogue.less';
import { entityContactsApi } from '../../entityContactsApi';

const RealtiesView = UiAtomView.extend({
	thisClassName: 'realties',
	text() {
		const inRealties = this.model.get('inRealties') || [];
		let oirs = inRealties.map(ir => ir.ownerInRealty);
		let names = oirs.map(oir => oir.realtyName);
		if (names.length) {
			let allnames = names.join(', ');
			let name = names.shift();
			let add = names.length ? (' и еще ' + names.length) : '';
			return `<small title="${allnames}">${name + add}</small>`;
		} else {
			return '&mdash;';
		}
	}
})

const JoinBtn = NextIconButtonView.extend({
	baseClassName: 'join-btn',
	thisClassName: v => v.ifJoined('joined','not-joined'),
	text: v => v.ifJoined('открепить','прикрепить'),
	ifOption(key, ifTrue, ifNot) {
		return this.getOption(key, true) ? ifTrue : ifNot;
	},
	ifJoined(ifYes, ifNot) {
		return this.ifOption('joined', ifYes, ifNot);
	},
	async action() {
		const oirId = this.getOption('ownerInRealty')?.id;
		const contactId = this.model.id;
		let changes;
		
		if (this.ifJoined(true)) {
			changes = await entityContactsApi.disjoinOwnerInRealtyContact(oirId, contactId);
		} else {
			changes = await entityContactsApi.joinOwnerInRealtyContact(oirId, contactId);
		}
		if (changes) {
			await this.model.fetch();
			this.model.trigger('redraw');
		}
	},
});

const ContactsListItem = EntityContactsListItem.extend({
	initialize() {
		this._updateJoined();
		this.listenTo(this.model, 'redraw', this.render);
	},
	onBeforeRender() {
		this._updateJoined();
		console.log('[[BE FO RE RE ND ER]]')
	},
	_updateJoined() {
		let oirId = this.getOption('ownerInRealty').id;
		let irs = this.model.get('inRealties') || [];
		this.joined = irs.some(ir => ir.ownerInRealty?.id === oirId);
	},
	detailsButton: false,
	entityType: 'owner',
	rightItems: [
		RealtiesView,
		JoinBtn
	],

	childViewOptions() {
		let opts = this.getOptions(['realtyId', 'ownerInRealty', 'joined'])
		return opts;
	}
})

export const AddOrJoinContactsList = ContactsList.extend({
	childView: ContactsListItem,
	//ownerId, realtyId,
	childViewOptions() { 
		let options = this.getOptions(['ownerId', 'realtyId', 'ownerInRealty'])
		return options
	},
	viewComparator(v1, v2) {
		if (v1.joined !== v2.joined) {
			console.log('-j-',v1.joined, v2.joined);
			return (v1.joined || false) > (v2.joined || false) ? 1 : -1;
		}
		let r1 = (v1.model.get('inRealties') || []).length === 0 ? 0 : 1;
		let r2 = (v2.model.get('inRealties') || []).length === 0 ? 0 : 1;
		if (r1 !== r2) {
			console.log('-r-',v1.joined, v2.joined);
			return r1 - r2;
		}
		return entityContactsApi.contactComparator(v1, v2);
	},
	onRenderChildren() {
		console.warn('CHILDREN REDRAWED');
	},	
	onCollectionInitialized() {
		this.listenTo(this.collection, 'redraw', this.sort);
	}
});
