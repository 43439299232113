// define('svc/actualization/realties-list/realty/edit/properties/controls', [
//     'svc/actualization/realties-list/realty/edit/properties/bus'
// ], function(
//     bus
// ) {});


import bus from './bus';

function hasErrors(errors) {
    if (errors == null || errors === true || (Array.isArray(errors) && !errors.length)) {
        return false;
    } else {
        return true;
    }
}


const idScores = {
	none: -1000,
	undefined: -500,
	unknown: -300,
	other: 1000
}
const hash = {}
const enumViewComparator = (v1,v2) => {
	let i1 = v1.model.id;
	let i2 = v2.model.id;
	if (i1 in idScores || i2 in idScores) {
		console.log('*', i1, i2)
	}
	// if (i1 in hash === false) {
	// 	hash[i1] = 1;
	// 	console.log('*', i1);
	// }
	// if (i2 in hash === false) {
	// 	hash[i2] = 1;
	// 	console.log('*', i2);
	// }

	let id_score1 = idScores[i1] || 0;
	let id_score2 = idScores[i2] || 0;
	if (id_score1 !== id_score2) {
		return id_score1 - id_score2;
	}

	let vv1 = v1.model.get('label');
	let vv2 = v2.model.get('label');
	return vv1 < vv2 ? -1
	: vv1 > vv2 ? 1
	: 0;
}

const enumViewFilter = function(v) {
	let lbl = v.model.get('label');
	if (lbl === '' || lbl == null) { return false; }
	if (/^\w{3,}$/.test(lbl)) { return false; }
	return true;
}

const mod = {
    ControlValueMixin: {
        initialize() {
            this.initializeValue();
        },
				transformIncomingValue(value) { return value; },
        initializeValue() {
            if ('schema' in this) return;
            this.entity = this.getOption('entity');
            let schema = this.schema = this.getOption('schema');
            let value = this._value = this.transformIncomingValue(this.getOption('value'));
            this.value = value;

            if (schema.get('valueType') == "enum") {
                this.initializeEnumValue()
            }
        },
        initializeEnumValue() {
            if (this.schema.get('multiple') && !Array.isArray(this.value)) {
                let value = this.value;
                if (value === '' || value == null) {
                    value = [];
                } else {
                    if (typeof(value) == 'number') {
                        value = void 0;
                    }
                    value = (value || '').split(/\s*,\s*/g);
                    // try {
                    // } catch(e) {
                    //     console.log(value, this.schema);
                    //     throw e;
                    // }
                }
                this.value = value;
            }
        },
        getDefaultControlOptions() {
            return {
                schema: this.schema,
                value: this.value
            }
        },
        buildControlOptions(add) {
            return Object.assign({}, this.getDefaultControlOptions(), add);
        }
    },

    ControlChangeValueMixin: {

        changeValueAsync(value) {
            let errors = this.validateAsync(value);
            if (hasErrors(errors)) {
                this.broadcastErrors(errors);
            } else {
                this.broadcastValue(value);
            }
        },

        validateAsync(value) {

        },

        broadcastErrors(errors) {
            console.log('# error:', this.schema.id, errors)
        },
        broadcastValue(value) {
            bus.setEntityValue(this.entity, this.schema, value);
        }

    },

    InputValueMixin: {

        transformInputValue(value) {
            return this.schema.fromString(value);
        },

        onInputValue(value) {
            value = this.transformInputValue(value);
            this.changeValueAsync(value);
        }

    },

    InputValueEventsMixin(tagName, eventName = 'input') {
        let event = eventName + ' ' + tagName;
        return {
            [event](e) {
                this.onInputValue(e.target.value);
            }
        }
    },

		getControlViewComparator(schema) {
			let viewComparator;
			let isEnum = schema.get('valueType') == "enum";
			if (isEnum) {
				viewComparator = enumViewComparator;
			}
			return viewComparator;
		},
		getControlViewFilter(schema) {
			let viewFilter;
			let isEnum = schema.get('valueType') == "enum";
			if (isEnum) {
				viewFilter = enumViewFilter;
			}
			return viewFilter;
		}
}

export default mod;
