import { propertySchemaApi } from "../../apis/schema";
import { entityContactValueSchema } from "./models/entityContactValueSchema";

export const entityContactValueApi = {

	getValueHtml(valueModel, opts = {}) {
		if (valueModel == null) { return ''; }
		const text = propertySchemaApi.displayValue('value', valueModel, entityContactValueSchema);
		if (opts.hrefDisabled) { return text; }
		const href = propertySchemaApi.getValue('href', valueModel, entityContactValueSchema);
		if (!href) { return text; }
		return `<a href="${href}" target="_blank">${text}</a>`;
	},
	getType(valueModel) {
		return propertySchemaApi.displayValue('type', valueModel, entityContactValueSchema);
	},
	getNote(valueModel) {
		return propertySchemaApi.getValue('joinNote', valueModel, entityContactValueSchema) || '';
	},
	displayPurposes(valueModel) {
		return propertySchemaApi.displayValue('purposes', valueModel, entityContactValueSchema) || '';
	}
}