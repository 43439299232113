import { BbModel } from 'vendors';
import { urls } from 'coms/urls';
import { $, Backbone } from 'vendors';
import { headers, updateHeaders } from './headers'
import busApp from 'bus/app';
import { modalsApi } from '../../apis/modalsApi2';

const originalSync = Backbone.sync;



export const NewToken = BbModel.extend({
	constructor: function() {
		BbModel.apply(this, arguments);
		this.set('refresh_token', localStorage.getItem('refresh'));
		this.updateHeaders();
		this.on('change', this.onChange);
		// this.readyPromise = new Promise((res, rej) => this.on('synced', () => res()));
		// this.apiPromise = new Promise((res, rej) => this.on('synced', () => res()));

	},
	url() {
		return urls.api('_token')
	},
	fetch(data) {
		const options = {
			method: 'POST', 
			contentType: 'x-www-form-urlencoded',
			data: reduceData(data)
		}
		this.fetchedAt = Date.now();
		const promise = BbModel.prototype.fetch.call(this, options);
		return promise;
	},
	loginAsync(username, password) {
		const data = {
			grant_type: 'password',
			username, 
			password
		}
		return this.fetch(data);
	},
	refreshAsync() {
		const refresh_token = this.get('refresh_token');
		if (!refresh_token) {
			return Promise.resolve();
		}
		console.log('trying refresh token', typeof refresh_token)
		const data = {
			grant_type: 'refresh_token',
			refresh_token: refresh_token
		}
		const ignoreReloadPaths = {
			'':1,
			'/':1,
			'/acc/login':1
		}
		return this.fetch(data).then(() => {}, err => {
			this.clear();
			if (document.location.pathname in ignoreReloadPaths === false) {
				const cb = () => document.location.reload();
				modalsApi.confirm('Необходимо авторизоваться.').then(cb,cb);
			}
		});
	},

	tryRefreshAsync() {
		if (this.isExpired()) {
			return this.refreshAsync();
		} else {
			return Promise.resolve();
		}
	},
	isExpired() {
		const now = Date.now();
		const fetched = this.fetchedAt;
		const expires_in = this.get('expires_in') || 0;
		if (expires_in === 0) {
			return false;
		}
		const result = now > (fetched + (expires_in * 1000));
		console.log('expired?', result, now, fetched + (expires_in * 1000), fetched, expires_in);
		return result;
	},
	onChange(...args) {
		const refresh_token = this.get('refresh_token');
		if (refresh_token) {
			localStorage.setItem('refresh', refresh_token);
		} else {
			localStorage.removeItem('refresh');
		}
		this.updateHeaders(args);
		// busApp.trigger('token:initialized');
	},
	updateHeaders() {
		const token = this.get('access_token');

		if (token) {
			headers.Authorization = 'Bearer ' + token;
		} else {
			delete headers.Authorization;
		}

		updateHeaders();
		
	}
});


function reduceData(data) {
	if (data == null) {
		return;
	}
	return Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');
}


export const newToken = new NewToken();

Backbone.sync = async function(...args) {
	await newToken.tryRefreshAsync();
	const res = await originalSync.apply(Backbone, args);
	return res;
	// return newToken.tryRefreshAsync().then(() => originalSync.apply(this, args));
}


var methodMap = {
	'create': 'POST',
	'update': 'PUT',
	'patch': 'PATCH',
	'delete': 'DELETE',
	'read': 'GET'
};

Backbone.sync = function(method, model, options) {
	var type = methodMap[method];

	// Default options, unless specified.
	_.defaults(options || (options = {}), {
		emulateHTTP: Backbone.emulateHTTP,
		emulateJSON: Backbone.emulateJSON
	});

	// Default JSON-request options.
	var params = {type: type, dataType: 'json'};

	// Ensure that we have a URL.
	if (!options.url) {
		params.url = _.result(model, 'url') || urlError();
	}

	// Ensure that we have the appropriate request data.
	if (options.data == null && model && (method === 'create' || method === 'update' || method === 'patch')) {
		params.contentType = 'application/json';
		params.data = JSON.stringify(options.attrs || model.toJSON(options));
	}

	// For older servers, emulate JSON by encoding the request into an HTML-form.
	if (options.emulateJSON) {
		params.contentType = 'application/x-www-form-urlencoded';
		params.data = params.data ? {model: params.data} : {};
	}

	// For older servers, emulate HTTP by mimicking the HTTP method with `_method`
	// And an `X-HTTP-Method-Override` header.
	if (options.emulateHTTP && (type === 'PUT' || type === 'DELETE' || type === 'PATCH')) {
		params.type = 'POST';
		if (options.emulateJSON) params.data._method = type;
		var beforeSend = options.beforeSend;
		options.beforeSend = function(xhr) {
			xhr.setRequestHeader('X-HTTP-Method-Override', type);
			if (beforeSend) return beforeSend.apply(this, arguments);
		};
	}

	// Don't process data on a non-GET request.
	if (params.type !== 'GET' && !options.emulateJSON) {
		params.processData = false;
	}

	// Pass along `textStatus` and `errorThrown` from jQuery.
	var error = options.error;
	options.error = function(xhr, textStatus, errorThrown) {
		options.textStatus = textStatus;
		options.errorThrown = errorThrown;
		if (error) error.call(options.context, xhr, textStatus, errorThrown);
	};

	// Make the request, allowing the user to override any Ajax options.
	return newToken.tryRefreshAsync().then(() => {
		var xhr = options.xhr = Backbone.ajax(_.extend(params, options));
		model.trigger('request', model, xhr, options);
		return xhr;
	});
};

function urlError() {
	throw new Error('\'url\' or \'urlRoot\' property must be specified');
}