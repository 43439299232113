//import { View } from 'core';
import { HamburgerView } from '../HamburgerView';
//import { UiSchemaAtomView } from '../../schema';
import { ContactBoxHeader } from './ContactsBoxHeader';
import { ContactsList } from './ContactsList';
import { ContactsListItem } from './ContactsListItem';
import './contacts-box.less';

export const ContactsBox = HamburgerView.extend({
	baseClassName: 'contacts-box ui2-box',
	getChildren() {
		const header = this.getHeader();
		const list = this.getList();
		const footer = this.getFooter();
		return [header, list, footer]
	},

	getHeader() {
		//debugger;
		let opts = this.getOption('headerOptions', true);
		let result = Object.assign({ class: ContactBoxHeader }, opts);
		//console.warn('getHeader:::', result, this);
		return result;
		//return ContactBoxHeader; //{ class: ContactBoxHeader, entity: this.getOption('entity') };
	},
	getList() {
		let opts = this.getOption('listOptions', true);
		let result = Object.assign({ class: ContactsList }, opts);
		return result;
		//return ContactsList; //{ class: ContactsList, contacts: this.getOption('contacts'), entity: this.getOption('entity') };
	},
	getFooter() {
		
	},
	childViewTriggers: {
		'show:details':'show:details',
		'collection:initialized':'collection:initialized'
	},
	// childViewEvents: {
	// 	'collection:initialized'() {
	// 		debugger;
	// 	}
	// }
});

export {
	ContactsListItem,
	ContactsList
}