import { propertySchemaApi } from "../../../apis/schema";
import { entityContactsApi } from "../entityContactsApi";
import { EntityContactsBox } from "./EntityContactsBox";
import { claimsApi } from 'apis/claims';

export const OwnerContactsBox = EntityContactsBox.extend({
	OWNER: true,
	entityType: 'owner',
	initialize(options) {
		this.mergeOptions(options, ['refreshData']);
	},
	getItemFuncs(model) {
		return entityContactsApi.displayOwnerFuncs(model);
	},
	isAddContactAllowed() {
		return entityContactsApi.isAddOwnerContactAllowed()
	},
	isEditContactAllowed() {
		return entityContactsApi.isEditOwnerContactAllowed()
	}
});

