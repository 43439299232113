import { RecordsetView } from 'ui/layouts/recordset-view';
import ResultCollection from './objects-collection';
import ObjectModel from './object-model';
import filtersConfig from './filters';
import ItemView from './list-item-view';
import AcPan from '../actions-panel';

//import './edit-realties-list.less';

const actions = [
	// {
	// 	id: 'create:building',
	// 	text: 'Добавить здание',			
	// },		
];
let counter = 0;
let ItemView2 = ItemView.extend({ template: () => '---', })

export default RecordsetView.extend({
	className: 'recordset-view actualization objects-list',
	instantApplyFilters: true,
	initialize() {
		counter = 0;	
		this.searchResult = new ResultCollection([], { model: ObjectModel });
		this.registerActionsPanel();

	},
	listResultOptions: {
		//className: 'realties-list',
		className: 'load-more-on-scroll-box scrollY-region edit-objects-list',
		childView: ItemView,
		// () => {
		//     if (counter > 40) return ItemView2;
		//     counter++;
		//     return ItemView
		// },
		childViewOptions: {
			modelType: "object",
		},
		
		//modelType: "realty",
	},
	filtersConfig,
	...AcPan.resultViewMixin({ actions }),
});


