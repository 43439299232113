import { ContactsListItem } from "coms/ui/ContactsBox";
import { EntityContactsListItemNameView } from "./EntityContactsListItemNameView";
import { entityContactsApi } from "../entityContactsApi";

export const EntityContactsListItem = ContactsListItem.extend({
	//thisClassName: 'contacts-list-item',
	mainView: EntityContactsListItemNameView,
	detailsButton: true,
	mainViewOptions() {
		return {
			entityType: this.getOption('entityType')
		}
	},
	onShowDetails() {
		console.log(this.model.attributes);
		entityContactsApi.detailsInModal(this.model, {
			isEditAllowed: this.getOption('isEditAllowed', true),
			isDeleteAllowed: this.getOption('isDeleteAllowed', true),
		});
	}
});