import { MneView, MneNextCollectionView } from 'core/mne';
import { View } from 'core';
import { CollectionView } from 'core';
import { BbeModel, BbeCollection } from 'core/bbe';
import { bbeComparator } from 'utils';
import beh from 'behaviors';
import refs from 'references';
import { UiSchemaAtomView } from 'coms/schema';
import { EntityContactsListItem } from 'mods/entity-contacts';
import { entityContactsApi } from 'mods/entity-contacts';

function phoneToString(value)
{
	if (!value) return value;
	return value.split('.').join(' ');
}

function contactValueHtml(item)
{
	switch (item.type) {
		default: return item.value;
		case 'phone':
		case 'mobilePhone':
			return phoneToString(item.value);
	}
}

const PopoverView = MneView.extend({
	className: 'popover-contacts padding-vh',
	template: _.template('<%= personNameFull %>'),
	templateContext() {
		//console.log('popov', this.model.toJSON())
		return {
			personNameFull: this.model.getValueByPath('person.name.full')
		}
	}
});


const PopoverBehavior = beh.BootstrapPopover.extend({
	content: PopoverView,
	contentOptions: function () {
		return {
			model: this.view.model
		}
	},
	popoverTemplateContext: function () {
		return { popoverClass: 'popover-flat popover-contact-details ' };
	},
	placement: 'bottom auto',
	container: 'body',
});


export const ContactsListItem = EntityContactsListItem.extend({
	entityType: 'owner',
	thisClassName: 'contacts-list-item',
	isEditAllowed: v => entityContactsApi.isEditOwnerContactAllowed(),
	isDeleteAllowed: false,
});

export const ContactView = View.extend({
	className: 'contacts-list-item',
	behaviors:[ PopoverBehavior ],
	template: _.template(`			
		<div>
			<span class="name"><%- name %></span>
			<span class="value"><%= valueHtml %></span>
		</div>
		<div class="funcs"><%= roles %></div>
	`),
	getValueHtml() {
		let l1 = this.model.get('values') || [];
		let raw = l1.map(l => {
			return {
				..._.omit(l, 'value'),
				...(l.value || {}),
				orderType: l.type == 'mobilePhone' 
					? 100
					: l.type == 'phone'
						? 50
						: l.type == 'email'
							? 25
							: 0
			}
		});

		let first = _.sortBy(raw, (a,b) => bbeComparator(b, a, [i => i.orderType, i => i.type]))[0];
		if (!first) return '';

		return contactValueHtml(first)
	},
	templateContext() {
		//console.log(this.model.toJSON());
		return {
			name: this.model.getValueByPath('person.name.short') || '<имя не указано>',
			valueHtml: this.getValueHtml(),
			roles: refs.enum('realtyOwnerContactFunctions', this.model.get('ownerFuncs'))
		}
	}
});

export const NoContactsView = View.extend({
	thisClassName: 'badge',
	template: 'контакты не установлены'
});
