import { MapInitializer } from "./MapInitializer";
import { _ } from 'vendors';
import { knownBounds } from "./knownBounds";
import { errResult, invokeValue, mergeObjects, okResult, tryAsync } from "../../utils";
import { defaultCenter, defaultZoom } from "./common";
import { settleSearchHandler } from "./yandex/searchHandler";
import { SearchSuggest } from "./yandex/search";
import { ClustersCollection, MapPointsCollection } from './yandex/map-points';
import { mapsBackend } from "./mapsBackend";
import { createDefaultMarker } from "./yandex/map-points/marker-stuff";
import { YandexMetroLayer } from "./yandex/YandexMetroLayer";

const formatedSym = Symbol('formated-address');
// nrg-plus-it@yandex.ru
const key = '5e79d281-1fc7-4c3b-819d-449a8955b662';
const geocodeUrl = `https://geocode-maps.yandex.ru/1.x/?apikey=${key}&format=json&geocode=`;
const url = `https://api-maps.yandex.ru/v3/?apikey=${key}&lang=ru_RU`;

const defaultYandexCenter = latlngToYaArray(defaultCenter);

// const defaultLocation = {
// 	center: { lng: 37.60493597412108, lat: 55.745829121494936 },
// 	zoom: 11,
// }
// const defaultMapOptions = {
// 	mode: 'raster'
// }

const listenerSymbol = Symbol("listener");

export class YandexmapsInitializer extends MapInitializer {
	constructor() {
		super(url);
	}
	identifier = 'yandex';
	name = 'yandex.next';
	
	supportsMapClusters = true;
	supportsMapPoints = true;
	supportsMetroLayer = true;

	async afterLoadCallback() {
		return ymaps3.ready;
	}

	_extendNs(hash) {
		Object.keys(hash).forEach(key => {
			const value = hash[key];
			let error;
			if (value == null) {
				error = 'missing value for NS key ' + key;
			}
			if (key in ymaps3) {
				error = 'ymaps3 already has ' + key;
			}
			if (error) { return console.warn(error); }
			ymaps3[key] = value;
		});
	}

	// implement namespace
	async importNamespace() {
		const { YMapDefaultMarker } = await ymaps3.import('@yandex/ymaps3-markers@0.0.1');
		
		const { YMapZoomControl } = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');
		//const { YMapZoomControl } = await ymaps3.import('@yandex/ymaps3-default-ui-theme');

		const ns = { YMapDefaultMarker, YMapZoomControl };
		this._extendNs(ns);
		// if ('YMapDefaultMarker' in ymaps3 === false && YMapDefaultMarker) {
		// 	ymaps3.YMapDefaultMarker = YMapDefaultMarker;
		// 	console.log('YMapDefaultMarker imported successfuly', YMapDefaultMarker);
		// } else {
		// 	console.log('YMapDefaultMarker was not imported', YMapDefaultMarker);
		// }

		const module = await import('./yandex');


		// window.dbg_fullscreen = function() {
		// 	debugger;
		// 	if (document.fullscreenElement) {
		// 		// The document.exitFullscreen() requests that the element on this document which is currently being presented in fullscreen mode be taken out of fullscreen mode
		// 		document.exitFullscreen();
		// 	} else {
		// 		// The element.requestFullscreen() method issues an asynchronous request to make the element be displayed in fullscreen mode
		// 		let ctr = window.map.container;
		// 		ctr.requestFullscreen();
		// 	}
		// }

		return module;
	}

	destroyMap(map) {
		const listener = map[listenerSymbol];
		if (listener) {
			map.removeChild(listener);
		}
		map.destroy();
	}

	setMapCenter(map, latlng, zoom) {
		const center = latlngToYaArray(latlng);
		map.setLocation({
			center,
			zoom
		});
	}

	showMarker(map, marker) {
		console.log('showMarker', arguments);
		map.addChild(marker);
	}

	hideMarker(map, marker) {
		map.removeChild(marker);
	}

	createMarker(options = {}) {
		console.log('createMarker', arguments);
		const { coords, animated } = options;
		const markerOptions = { coordinates: latlngToYaArray(coords), color: 'cornflowerblue' };
		const marker = new ymaps3.YMapDefaultMarker(markerOptions);
		return marker;
	}
	createRealtySearchMarker(options = {}) {
		const { coords, animated, autohide } = options;
		const markerOptions = { coordinates: latlngToYaArray(coords), zIndex: 10 };
		const marker = createDefaultMarker(markerOptions);

		console.log(marker)
		return marker;
	}
	updateMarker(map, marker, options = {}) {
		let { coords: coordinates, draggable } = options;
		const upd = {};
		if (coordinates) {
			if (typeof coordinates === 'object' && !Array.isArray(coordinates)) {
				coordinates = latlngToYaArray(coordinates);
			}
			upd.coordinates = coordinates;
		}
		if (draggable != null) {
			upd.draggable = invokeValue(draggable);
		}
		if (!Object.keys(upd).length) { return; }
		marker.update(upd);
	}
	// _attachMapToView (view, options) {
	// 	const el = options.el || view.el;
	// 	const mapOptions = this.buildMapOptions(options);
	// 	const map = this.buildMap(el, mapOptions);
	// 	return { map, mapOptions };
	// }

	buildMap(el, options, mapExtraOptions) {
		const map = new ymaps3.YMap(el, options);
		console.warn('MAP INSTANCE BUILDED', map);
		window.map = map;
		//const layer = new ymaps3.YMapDefaultSchemeLayer();
		map.addChild(new ymaps3.YMapDefaultSchemeLayer());
		map.addChild(new ymaps3.YMapDefaultFeaturesLayer());
		console.log('-- building map --', mapExtraOptions);
		if (mapExtraOptions && mapExtraOptions.manyMarkers) {
			console.log('many markers detected');
		}
		map.addChild(new ymaps3.YMapFeatureDataSource({id: 'marker-source'}));
		map.addChild(new ymaps3.YMapLayer({source: 'marker-source', type: 'markers'}));

		const controls = new ymaps3.YMapControls({position: 'bottom right'});
		const zoomControl = new ymaps3.YMapZoomControl({
			easing: 'linear'
		});
		controls.addChild(zoomControl);
		// 	new ymaps3.YMapZoomControl({
		// 		easing: 'linear'
		// 	})
		// );
		map.addChild(controls);

		// const listener = new ymaps3.YMapListener({});
		// map.addChild(listener);
		// map[listenerSymbol] = listener;

		// map.addChild(
		// 	new ymaps3.YMapMarkerDataSource({
		// 		id: 'markerSource'
		// 	})
		// )
		// map.addChild(
		// 	new ymaps3.YMapLayer({
		// 		source: 'markerSource',
		// 		type: 'markers',
		// 		zIndex: 2020
		// 	})
		// );
		return map;
	}

	updateMap(map, options = {}) {
		let { lat, lng, center, position, zoom, viewport } = options;
		center = (lat && lng ? { lat, lng } : undefined) || position || center;
		center = latlngToYaArray(center);
		let location;
		if (center) {
			location = { center };
			//map.setCenter(center);
		}
		// if (viewport) {
		// 	map.fitBounds(viewport);
		// }
		if (zoom) {
			location = location || {};
			location.zoom = zoom;
			//map.setZoom(zoom);
		}
		if (location) {
			map.setLocation(location);
		}
	}

	// setMapCenter(map, center, zoom) {
		
	// 	map.setCenter(center);
	// 	if (zoom) {
	// 		this.setMapZoom(map, zoom);
	// 	}
	// }

	// setMapZoom(map, zoom) {
	// 	map.setZoom(zoom);
	// }	


	_buildMapOptions(zoomCenter, mapOptions = {}) {
		let { zoom = defaultZoom, center } = zoomCenter;
		center = center ? latlngToYaArray(center) : defaultYandexCenter;
		const location = {
			zoom,
			center
		}
		const result = {
			location,
			//behaviors: ['drag','pinchZoom']
		}
		// let mapOptionsCenter = {
		// 	zoom: mapOptions.zoom,
		// 	center: mapOptions.center
		// }
		// delete mapOptions.zoom;
		// delete mapOptions.center;
		// const location = mergeObjects([zoomCenter, mapOptionsCenter, defaultLocation]);
		// location.center = latlngToYaArray(location.center);
		// const result = Object.assign({ location }, defaultMapOptions, mapOptions);
		// const optionsObjects = [{}, globalMapDefaults, mapOptions];
		// const result = mergeObjects(optionsObjects);
		console.log(result, mapOptions);
		return result;
	}

	_ensureListener(map) {
		let listener = map[listenerSymbol];
		if (listener) { console.warn('MAP _LISTENER ALREADY EXIST'); return listener;}
		
		listener = new ymaps3.YMapListener({});
		map.addChild(listener);
		map[listenerSymbol] = listener;
		return listener;
	}


	on (map, eventEntity, appEventName, callback) {
		const listener = this._ensureListener(map);
		let hash;
		let invoke;
		if (appEventName === 'idle') {
			// hash = {
			// 	onUpdate: callback,
			// 	onResize: callback,
			// }
			hash = this._getYandexEventNames(appEventName, map, eventEntity, callback);
			invoke = true;
		} else if (appEventName === 'click') {
			hash = this._getYandexEventNames(appEventName, map, eventEntity, callback);
			// {
			// 	onClick: callback
			// }
		} else {
			hash = this._getYandexEventNames(appEventName, map, eventEntity, callback);
			// throw new Error(appEventName + ', entity is map - ' + (map === eventEntity));
		}

		listener.update(hash);
		if (invoke) {
			callback();
		}
	}


	_getYandexEventNames(appEventName, map, eventEntity, callback) {
		switch(appEventName) {
			case 'click':
				return { onClick: callback };
			case 'idle': 
				return { onUpdate: callback, onResize: callback };
			case 'resize':
				return { onResize: callback }
			case 'update': 
				return { onUpdate: callback }
			default:
				return { [appEventName]: callback }
		}
	}

	off (map, eventEntity, appEventName, listener, callback) {
		const maplistener = this._ensureListener(map);
		const hash = this._getYandexEventName(appEventName, map, eventEntity, callback || null);
		maplistener.update(hash);
	}

	_addMapDebouncedEvent(eventName, options) {
		let { map, view, delay = 300, onStart, onEnd, invokeStart, invokeEnd } = options;
		if (typeof onStart === 'function') {
			onStart = _.debounce(onStart, delay, true)
		} else { onStart = null }
		if (typeof onEnd === 'function') {
			onEnd = _.debounce(onEnd, delay)
		} else { onEnd = null }

		if (!onStart && !onEnd) { return; }
		const handler = (...args) => {
			onStart && onStart(...args);
			onEnd && onEnd(...args);
		}

		const listener = this._ensureListener(map);

		listener.update({
			[eventName]: handler
		});

		if (view) {
			
			// console.warn('SETTING VIEW ON_DESTROY HANDLER', view);
			// view.on('destroy', () => {
			// 	console.log('DESTROYING MAP VIEW');
			// 	this._listener && map.removeChild(this._listener);
			// 	delete this._listener;
			// });
		}

		console.warn(`MAP DEBOUNCED EVENT "${eventName}" SETTLED`);

		if (invokeStart) {
			onStart();
			console.warn(' onStart callback invoked.')
		}

		if (invokeEnd) {
			onEnd();
			console.warn(' onEnd callback invoked.')
		}

	}	

	addOnResize(options) {
		this._addMapDebouncedEvent('onResize', options);
	}

	addOnUpdate(options) {
		this._addMapDebouncedEvent('onUpdate', options);
	}

	createMetroLayer() {
		return new YandexMetroLayer({ map, engine: this });
	}

	setupAddressApi(map, options = {}) {
		console.error('	SETUP ADRESS API', options);
		const { searchControl, marker } = options;
		let addressPin;
		let onPositionChange;
		let addressPinPosition;
		let searchBox;
		let shouldUpdateMarker;
		let shouldUpdateMarkerBySearch;
		/*

		const onMarkerPositionChange = async (places) => {
			if (!addressPin || !onPositionChange) { return; }
			const center = addressPin.position.toJSON();
			if (!places) {
				let placesRes = await this.geodecode(center.lat, center.lng);
				places = placesRes.value || {};
			}
			const hash = this.geodecodeToHash(places, center);
			console.log(' - geodecodeToHash - ', places, center);
			onPositionChange(hash);
		}



		const changeMarkerPositionByPlaces = places => {
			if (!places) { return; }
			const geometry = places[0]?.geometry || {};

			const { viewport, location } = geometry;
			const center = location?.toJSON() || undefined;
			
			this.updateMap(map, { viewport, center });
			const shouldUpdate = invokeValue(shouldUpdateMarkerBySearch);

			if (center && shouldUpdate) {
				changeMarkerPosition(center, places);
			}
		}





		
		*/
		let markerAutohide;
		if (marker) {
			//debugger
			onPositionChange = marker.onPositionChange;
			const { updateAllowed, isDraggable, destroyMarkerOnClick, updatePositionOnMapClick, updatePositionOnDragend, lat, lng, changeBus, autohide } = marker;
			markerAutohide = autohide;

			shouldUpdateMarker = updateAllowed;
			let position = (lat != null && lng != null) ? [lng, lat] : undefined;
			addressPinPosition = position;
			const addressPinOptions = {
				coordinates: position || [37,55],
				zIndex: 10,
				color: 'cornflowerblue',
				draggable: invokeValue(isDraggable),
				onClick: () => {
					if (invokeValue(destroyMarkerOnClick)) {
						map.removeChild(addressPin);
					}
				},
				// onDrag: () => {
				// 	console.log('drag')
				// },
				// onDragend: () => {
				// 	console.log('dragend')
				// },
				onDragEnd: () => {
					const allowed = invokeValue(updatePositionOnDragend) && (updateAllowed == null || invokeValue(updateAllowed))
					if (!allowed) { return; }
					console.log('dragEnd', addressPin.coordinates);
					onMarkerPositionChange();
				},

			};
			addressPin = createDefaultMarker(addressPinOptions);



			 //new ymaps3.YMapDefaultMarker(addressPinOptions);
			if (changeBus) {
				changeBus.on('change',() => addressPin.update({ draggable: invokeValue(isDraggable) }));
			}


			if (updatePositionOnMapClick) {
				this.on(map, map, 'click', (a1, data = {}) => {
					const { coordinates, screenCoordinates } = data;
					console.log('MAP CLICK', coordinates);
					if (invokeValue(updatePositionOnMapClick) && coordinates) {
						changeMarkerPosition(coordinates);
					}
				})
			}


				//new google.maps.Marker(addressPinOptions);
			if (position) {
				map.addChild(addressPin);
				//addressPin.setMap(map);
			}

			if (updatePositionOnMapClick) {
				// map.addListener('click', event => {
				// 	console.log('- map click -');
				// 	const shouldUpdate = invokeValue(shouldUpdateMarker);
				// 	if (shouldUpdate) {
				// 		changeMarkerPosition(event.latLng)
				// 	}					
				// });
			}
			if (updatePositionOnDragend) {
				// addressPin.addListener('dragend', () => {
				// 	console.log('- drag end -');
				// 	onMarkerPositionChange()
				// })
			}
		}
		


		const changeMarkerPosition = async (center) => {
			//console.log('> changeMarkerPosition', center, addressPin.coordinates);
			if (!addressPin) { return; }
			// debugger;
			map.removeChild(addressPin);
			map.addChild(addressPin);
			if (markerAutohide) {
				console.error('_SETTING UP_ AUTOHIDE');
				setTimeout(() => {
					console.error('AUTOHIDE IN WORKS');
					map.removeChild(addressPin);
				}, markerAutohide);
			}
			addressPin.update({ coordinates: center });
			console.log('> marker updated', center, addressPin.coordinates);
			onMarkerPositionChange();
		}

		const onMarkerPositionChange = async () => {
			if (!addressPin || !onPositionChange) { return; }

			const coords = addressPin.coordinates;
			const [lng, lat] = coords;
			const center = { lat, lng }
			let hash = await this.geodecode(center.lat, center.lng);
			if (!hash) { return; }
			hash = hash[formatedSym] ? hash: this.geodecodeToHash(hash, center);
			onPositionChange(hash);

			// if (!data) {
			// 	let gdres = await this.geodecode(center.lat, center.lng);
			// 	if (!gdres) { return; }
			// 	data = gdres;
			// }
			// const hash = data[formatedSym] ? data: this.geodecodeToHash(data, center);
			// console.log(' - geodecodeToHash - ', data, center);
			// onPositionChange(hash);
		}

		const changeMarkerPositionByCoords = (center) => {
			if (!center) { return; }

			
			this.updateMap(map, { center, zoom: 15 });
			const shouldUpdate = invokeValue(shouldUpdateMarkerBySearch);

			if (center && shouldUpdate) {
				changeMarkerPosition(center);
			}			
		}


		const changeMarkerPositionByTextAddress = async (text) => {
			console.warn(text);
			const places = await this.geocode(text);
			
			if (!places || !places.length) { return; }			
			const coords = places[0]?.geometry?.coordinates;
			if (!coords) { return; }
			changeMarkerPositionByCoords(coords);
			// if (geocodeRes && geocodeRes.length) {
			// 	const model = geocodeRes[0];
			// 	const coords = model?.geometry?.coordinates;
			// 	if (coords) {
					
			// 	}
			// }
			// changeMarkerPositionByPlaces(geocodeRes.value);
		}		


		if (searchControl?.inputEl) {
			const { inputEl, controlEl, updateMarker } = searchControl;
			shouldUpdateMarkerBySearch = updateMarker;
			const searchBounds = this._getSearchBounds('moscow');
			console.log('BOOM', controlEl);
			const view = new SearchSuggest({ 
				inputEl,
				controlEl,
				searchOptions: { bounds: searchBounds },
				onNewCoordinates(coords) {
					changeMarkerPositionByCoords(coords);
				}
			});
			
			
			// searchBox = new google.maps.places.SearchBox(inputEl, { bounds: searchBounds });
			// if (controlEl) {
			// 	map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlEl);
			// }

			// searchBox.addListener("places_changed", () => {
			// 	const places = searchBox.getPlaces() || [];
			// 	changeMarkerPositionByPlaces(places);
			// });			

			if (addressPin && !addressPinPosition && inputEl.value) {
				changeMarkerPositionByTextAddress(inputEl.value);
			}

		}
		console.log('ADRESS PIN', addressPin);
		return { addressPin, searchBox };

	}

	_getSearchBounds(boundsName = 'moscow') {
		const bounds = knownBounds[boundsName];
		const sw = {
			lat: bounds.south,
			lng: bounds.west,
		}
		const ne = {
			lat: bounds.north,
			lng: bounds.east,
		}
		const searchBounds = [latlngToYaArray(sw), latlngToYaArray(ne)];
		//new google.maps.LatLngBounds(sw, ne);
		return searchBounds;
	}

	getBounds(map) {
		const bounds = map.bounds;
		if (!bounds) { return; }
		const [ westNorth, eastSouth ] = bounds;
		const [ west, north ] = westNorth;
		const [ east, south ] = eastSouth;
		return {
			north,
			east,
			south,
			west
		}

	}

	createClusterOverlayView(options) {
		console.log('- = ClusterOverlayView');
		const { ClusterOverlayView } = this.namespace.views;
		return new ClusterOverlayView(options);
	}

	geocode(text) {
		const bounds = this._getSearchBounds('moscow');
		const options = { text, bounds }; 
		return ymaps3.search(options);
	}

	async geodecode(lat, lng) {
		const res = await this.backendGeocode([lng, lat]);

		// массив
		const featureMember = res.ok && res?.value?.response?.GeoObjectCollection?.featureMember;

		if (!featureMember) {
			return await this.geocode([lng, lat]);
		}

		let exactFounded;
		
		const addresshash = featureMember.reduce((memo, feature) => {
			if (exactFounded) { return memo; }
			const metaData = feature.GeoObject?.metaDataProperty?.GeocoderMetaData;
			const addr = metaData?.Address;
			if (!addr) { return memo; }

			const precision = metaData.precision;
			// memo.fa.push(addr.formatted);
			if (!memo.fullAddress) {
				memo.fullAddress = addr.formatted;
			}
			
			const cmps = addr.Components;
			if (!cmps) { return memo; }
			const indexedKinds = {
				province: 0,
				district: 0,
				name(key) {
					const i = this[key] || 0;
					this[key] = i + 1;
					return key + i;
				}
			};
			cmps.forEach(comp => {
				let { kind, name } = comp;
				if (kind in memo === false) {
					memo[kind] = name;
					return;
				}

				let existValue = memo[kind];
				if (!Array.isArray(existValue)) {
					if (existValue === name) {
						return;
					}
					memo[kind] = [existValue, name];
				} else if (existValue.indexOf(name) === -1) {
					existValue.push(name);
				}

				//memo.knds.push({ [kind]: name });
			});
			// if (precision === 'exact') {
			// 	exactFounded = true;
			// }
			return memo;
		}, { lat, lng });



		const formatedHash = toFormatedAddressHash(addresshash)
		
		// const addr = featureMember[0] && featureMember[0]?.GeoObject?.metaDataProperty?.GeocoderMetaData?.Address;
		// if (!addr) {
		// 	return await this.geocode([lng, lat]);
		// }

		console.error(featureMember);
		console.error(addresshash);
		console.log(formatedHash);
		return formatedHash;
	}
	backendGeocode(geocode) {
		const url = geocodeUrl + geocode;
		return tryAsync(() => fetch(url).then(resp => resp.json()));
	}

	geodecodeToHash(places, center) {
		const model = places[0];
		const pat = /\s*,\s*/;
		const exists = {}
		const rawchunks = places.map(place => place.properties.name);
		const chunks = rawchunks.reduce((memo, inner) => {
			if (inner in exists) { return memo; }
			memo.push(inner);
			exists[inner] = 1;
			return memo;
		}, []);

		const props = model.properties || {};
		const { name = '', description = '' } = props;
		const nameChunks = name.split(pat);
		//const descChunks = description.split(pat);
		const fullAddress = [name, description].filter(f => !!f).join(', ');
		const [route, house] = nameChunks;
		
		const hash = Object.assign({ fullAddress, route, house, chunks }, center);
		return hash;
	}

	buildMapPointsCollection(map) {
		const col = new MapPointsCollection([], { map });
		return col;
	}

	buildClustersCollection(map, mapStateData) {
		const fetchData = options => mapsBackend.fetchMapPoints(options);
		const col = new ClustersCollection([], { map, fetchData, mapStateData });
		console.warn('= clusters collection =', col);
		return col;
	}

	async redrawMap(map) {
		// await map.container.requestFullscreen();
		// await document.exitFullscreen();
	}

}

function latlngToYaArray(ll) {
	if (!ll || typeof ll !== 'object' || Array.isArray(ll)) {
		return ll;
	}
	return [ll.lng, ll.lat]
}


function toFormatedAddressHash(data) {
	const { country, province, locality, fullAddress, house, street: route, area, district, lat, lng } = data;
	let administrativeArea1, administrativeArea2, sublocality;

	administrativeArea1 = arrExtract(province, true);
	administrativeArea2 = arrExtract(area, false);
	sublocality = arrExtract(district, false);


	const hash = {
		fullAddress,
		country, 
		administrativeArea1, administrativeArea2, 
		locality, sublocality, 
		route, house,
		lat, lng,
		[formatedSym]: 1
	};
	return hash;
}


function arrExtract(arr, last) {
	if (arr == null) { return; }

	if (Array.isArray(arr)) {
		return last ? arr.pop() : arr.shift();
	}

	return arr;
}