import { modalsApi } from "apis/modalsApi2";

const goodStatuses = {
	200: 1,
	201: 1,
}

export function tryModalError(arg, defaultMessage) {
	if (!arg || typeof arg !== 'object') { return; }
	if ('ok' in arg) {
		return tryAsyncResultModalError(arg, defaultMessage);
	} else if ('responseJSON' in arg) {
		return tryXhrModalError(arg, defaultMessage);
	}
}

export function tryXhrModalError(xhr, defaultMessage)
{
	let json = xhr.responseJSON;
	if (!(xhr.status && !goodStatuses[xhr.status])) { return; }
	return jsonModalError(json, defaultMessage);
}

export function jsonModalError(json, defaultMessage) {
	if (!json) { return; }
	
	let message = (typeof json === 'object' ? json.message || json.Message : json) || defaultMessage;
	
	if (message) {
		return modalsApi.error(message);
	}

}

export function tryAsyncResultModalError(res, defaultMessage) {
	if (res.ok || ('ok' in res === false) || !res.error) { return; }
	return jsonModalError(res.error, defaultMessage);
}