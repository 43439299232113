import { View, Model } from 'core';
import { ApiCollectionView } from 'core';
import OffersView from 'svc/realties/offers/views/collection';
import { OfferInListView } from 'svc/realties/offers/views/inList3';
import { BuildingInList } from '../building/views/inList';
import { getRealtyLatLng, getRealty, getRealtyAsync, realtiesCoords } from 'mods/realties/realties';
import beh from 'behaviors';
import { isAsidelessMap } from './utils';

const MapSelectionInfo = View.extend({
	template: '<%= name %> <span class="badge" title="количество предложений"><%= count %></span>',
	templateContext() {
		const name = this.getName();
		const count = this.getCounts();
		return {
			name, count
		}
	},
	getName() {
		const type = this.model.get('type');
		switch(type) {
			case 'cluster':
				return 'группа зданий';
			case 'station':
				const station = this.model.get('station');
				const stationName = station.get('name');
				return 'ст. м. ' + stationName;
			case 'line':
				const line = this.model.get('line');
				return line.get('name');
			default:
				return 'все предложения';
		}
	},
	getCounts() {
		return this.collection.isFetching() ? '...' : this.collection.fetchResult.get('founded');
	},
	collectionEvents: {
		sync: 'render',
		request: 'render'
	},
	modelEvents: {
		change: 'render'
	}
});

const EmptyView = View.extend({
	className: 'empty-view',
	template: '<%= html %>',
	preloaderHtml: '<div class="loader-ellipsis"><div></div><div></div><div></div><div></div></div>',
	notfoundHtml: '<div class="not-found">предложения не найдены</div>',
	templateContext() {
		let html = this.collection.isFetching() ? this.preloaderHtml : this.notfoundHtml;
		return {
			html
		}
	},
	collectionEvents: {
		'request sync':'render'
	},
});

const OffersListView = ApiCollectionView.extend({
	mergeOptionsKeys: ['filterObject'],
	behaviors: [beh.SelectedForActions],
	childView: OfferInListView,
	emptyView: EmptyView,
	className: 'offers-v2 with-scroll flex-column',
	childViewEventPrefix: false,
	shouldFetchOnCreate: true,
	fetchUntilScrollEnabled: true,
	fetchOnScrollEnabled: 'bottom',
	childViewTriggers: {
		'selectable:toggle':'selectable:toggle'
	},
	modelEvents: {
		'change':'fetch'
	},
	childViewTriggers: {
		'selectable:toggle':'selectable:toggle'
	},
	getFetchData() {
		const h = this.filterObject.getUrlHash();
		return this.model.getFetchData(h);
	},
	initialize() {
		// this.filterObject.on('all', c => console.warn('[f]:>', c));
		// this.listenTo(this.filterObject, 'filters:apply', () => {
		// 	this.collection.reset();
		// 	this.fetch()
		// });
	},
});

export const SideBarOffers = View.extend({
	mergeOptionsKeys: ['filterObject', 'mapStateData', 'mapApi'],
	baseClassName: 'result-container',
	classNames: [
		v => v.model.get('type') ? 'something-selected' : ''
	],
	template: '<div class="item"></div><div class="result"></div><button class="unselect"><i></i></button>',
	regions: {
		'info': '.item',
		'result': '.result'
	},
	events: {
		'click .unselect'() {
			if (isAsidelessMap()) {
				console.log('isAsidelessMap unselect');
				this.trigger('inner:close');
			} else {
				console.log('is NOT AsidelessMap unselect')
			}
			this.model.clear();
			this.mapStateData.unselectAll();

			//this.mapStateData.setSelectedBounds();
		}
	},
	modelEvents: {
		change: 'refresh'
	},
	refresh() {
		if (!this.isRendered() || this.isDestroyed()) { return; }
		this.collection.reset();
		this.updateClassName();
		this.showInfo();
	},
	onRender() {
		this.showInfo();
		this.showData();
	},
	async showInfo() {
		console.error('SHOW-INFO:', this.cid);
		const type = this.model.get('type');
		let view;
		if (type == 'point') {
			var View = BuildingInList;
			var model = await getRealtyAsync(this.model.get('realtyId'));
			view = new View({ model: model });
		} else {
			view = new MapSelectionInfo({ model: this.model, collection: this.collection });
		}
		this.showChildView('info', view);
	},
	showData() {
		var view = new OffersListView({
			// behaviors: [beh.SelectedForActions],
			// childView: OfferInListView,
			// className: 'offers-v2 with-scroll flex-column',
			// childViewEventPrefix: false,
			// shouldFetchOnCreate: true,
			// fetchUntilScrollEnabled: true,
			// fetchOnScrollEnabled: 'bottom',
			filterObject: this.filterObject,
			collection: this.collection,
			model: this.model,



			childViewOptions: {
				collection: this.collection,
				isJoined: v => this.isJoinedOffer(v),
				selectionHolder: this.getOption('selectionHolder'),
				hideBuildingLine: v => this.model.get('type') === 'point' && this.model.get('realtyId') != null,
				onOfferHover: m => this.mapApi.highlightRealty(m.get('realtyId'), true),
				onOfferUnhover: m => this.mapApi.highlightRealty(m.get('realtyId'), false),
			},

			selectionHolder: this.getOption('selectionHolder'),
			selectedComparators: this.getOption('selectedComparators'),
			selectedActions: this.getOption('selectedActions'),

			// getFetchData: () => {
			// 	const h = this.filterObject.getUrlHash();
			// 	return this.model.getFetchData(h);
			// },

			// modelEvents: {
			// 	'change':'fetch'
			// },


			// childViewTriggers: {
			// 	'selectable:toggle':'selectable:toggle'
			// },
			// // // childViewEvents: {
			// // // 	'selectable:toggle': (...args) => console.log('s:t', args),
			// // // },
			// // // isJoined: child => this.isJoinedOffer(child),
			// // // collectionViewOptions: {
			// // // 	childViewEventPrefix: false,
			// // // },
			// // // data: this.data,
			// // // viewFilter: this.viewFilter,

		});

		// this.listenTo(view, 'after:render:children')
		
		// view.triggerMethod('filters:apply');
		// view.listenTo(this.filterObject, 'filters:apply', () => {
		// 	const newData = Object.assign({}, this.data, this.filterObject.getUrlHash());
		// 	view.options.data = newData;
		// 	view.triggerMethod('filters:apply', newData);
		// });
		this.offers = view;
		this.showChildView('result', view);
	},
	isJoinedOffer(view) {
		const joinedOffers = this.getOption('joinedOffers') || {};
		// console.log(view.model.id, view.model.id in joinedOffers, joinedOffers);
		const res = view.model.id in joinedOffers;
		return res;
	}
});