import { displayPhoneValue, hrefPhoneValue } from 'utils/contact-value';
import { UiSchemaAtomView, UiSchemaMoleculeView } from "../../schema";
import { HamburgerView } from "../HamburgerView";
import { TextView } from 'core';
import { NextIconButtonView } from 'coms/ui/Button';
import { openContactDetailsInModal } from './ModalDetails';
//import { entityContactsApi } from 'mods/entity-contacts';


const NameView = UiSchemaAtomView.extend({
	initialize() {
		//this.delegate('click', undefined, () => console.log(this.model.attributes));
	},
	//text: v => v.displayValue(),
	topText: v => v.getName(),
	getName() {
		console.log(this);
	}
});


const detailsButton = { class: NextIconButtonView, icon:'fa:info', thisClassName: 'dark-margined', action: 'trigger:show:details' };

export const ContactsListItem = HamburgerView.extend({
	baseClassName: 'ui2-molecule',
	thisClassName: 'contacts-list-item',
	getChildren() {
		const views = this.getLeftItems();
		views.push(this.getMainView())
		views.push(...this.getRightItems())
		return views;
	},
	getMainView() {
		let MainView = this.getOption('mainView', true) || NameView;
		let view = Object.assign({class: MainView }, this.getOption('mainViewOptions', true));
		return view;
	},
	getRightItems() {
		let items = this.getOption('rightItems') || [];
		if (this.getOption('detailsButton', true) === true) {
			items.unshift(detailsButton);
		}
		return items;
	},
	getLeftItems() {
		let items = this.getOption('leftItems') || [];
		return items;
	},
	childViewTriggers: {
		'show:details':'show:details',
			//openContactDetailsInModal(this.model);
	},
	modelEvents: {
		'refresh':'render'
	}
});

const NoContactsTextView = UiSchemaAtomView.extend({
	text: 'Контакты отсутствуют'
});

export const NoContactsView = HamburgerView.extend({
	baseClassName: 'ui2-molecule',
	thisClassName: 'contacts-list-item',
	childrenViews: [
		NoContactsTextView
	]
})