// define('ui/layouts/searchresult-view', [
// 	'behaviors-selectedForActions'
// ], function (SelectedForActions) {});


import SelectedForActions from 'behaviors/selectedForActions';


import { LoadmoreOnScrollCollectionView } from 'core/mne';

var View = LoadmoreOnScrollCollectionView.extend({
	childViewOptions: function () {
		return {
			selectionHolder: this.getOption('selectionHolder'),
			denySelectionFor: this.getOption('denySelectionFor'),
		}
	},
	collectionViewExtend: {
		behaviors: [SelectedForActions],
		// childViewTriggers: {
		// 	'address:hover': 'address:hover',
		// 	'address:unhover': 'address:unhover',
		// 	'metro:station:click': 'metro:station:click',
		// 	'address:click': 'address:click',
		// }
	},
	// childViewTriggers: {
	// 	'address:hover': 'address:hover',
	// 	'address:unhover': 'address:unhover',
	// 	'metro:station:click': 'metro:station:click',
	// 	'address:click': 'address:click',
	// }
});

export default View;