// import { Collection } from 'core/collection';
import { HamburgerView } from "coms/ui/HamburgerView";
import { ContactsListView } from './ContactsListView';
import { ContactsCollection } from 'mods/contacts';
import { getNestedCollection } from 'utils/getNestedCollection';

import { DropdownButton } from "../../../../../../../coms/ui/DropdownButton";
import { ButtonView } from "../../../../../../../coms/ui/Button";
import { editContactInModal } from 'mods/contacts';
import { Collection, Model } from 'core';


import { OwnerRealtyModel } from 'mods/realties/owners';

const ActionsButton = HamburgerView.extend({
	baseClassName: 'actions-container',
	childrenViews:[
		{
			class: ButtonView,
			text: 'Добавить новое контактное лицо',
			triggers: {
				'click':'add:new:click'
			}
		}
	],
	childViewTriggers: {
		'add:new:click':'add:new:contact'
	}
});

export const ContactsLayout = HamburgerView.extend({
	thisClassName: 'owner-contacts-page',
	initialize() {
		this.collection = getNestedCollection(this.model, {
			Collection: ContactsCollection,
			fetch: true,
			url: this.model.url() + '/contacts'
		});
		this.realties = new Collection(this.model.get('realties') || [], { model: OwnerRealtyModel });
		// const col = new Collection();
		// col.url = this.model.url() + '/contacts';
		// col.fetch();
		// this.collection = col;
	},
	childrenViews: v => [
		ActionsButton,
		// {
		// 	class: DropdownButton,
		// 	withIcons: true,
		// 	emptyLabel: 'выберите опцию',
		// 	multiple: false,
		// 	value: false,
		// 	values: // [1,2,3,4,5,6,7]
		// 	{ true: 'human', false: 'non human' },
		// 	getModelValue: m => m.id === 'true' ? true : false
		// },
		{ 
			class: ContactsListView,
			realties: v.realties,
			owner: v.model
		}
	],
	childViewEvents:{
		'add:new:contact'() {
			editContactInModal(undefined, { realties: this.realties, owner: this.model, contacts: this.collection });
		}
	}
});