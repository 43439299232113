import { BoxView } from "../Box";
import { SmartPropertiesLinesView } from "../PropertiesLineView";

export const PropertiesBoxView = BoxView.extend({
	// thisClassName: 'employees-box with-borders fixed-height',
	// header: 'сотрудники',
	content: v => ({
		class: SmartPropertiesLinesView,
		model: v.model,
		editModel: v.getOption('editModel', true),
		modelValue: v.getOption('modelValue', true),
		modelHash: v.getOption('modelHash', true),
		flatModel: v.getOption('flatModel', true),
		initialKeyValues: v.getOption('initialKeyValues', true),
		_xyz: console.error('CHECK MODEL HASH', v.getOption('modelHash', true)),
		modelSchema: v.getOption('modelSchema'),
		properties: v.getOption('properties', true),
		editEnabled: v.getOption('editEnabled'),
		editAction: v.getOption('editAction', false),
		setValueBySchemaApi: v.getOption('setValueBySchemaApi', true),
		trackChanges: v.getOption('trackChanges', true),
		rejectHardBehavior: v.getOption('rejectHardBehavior'),
		onPropertyChange: (newvalue, propertySchema, modelValue, modelSchema) => v.triggerMethod('property:change', newvalue, propertySchema, modelValue, modelSchema)
	}),
	// action: v => ({
	// 	glyph: 'plus',
	// 	text: 'добавить сотрудников',
	// 	clickAction: () => v.addEmployeesAction()
	// }),
});