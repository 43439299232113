// import { editInModal } from "../../../../coms/ui/EditView";

import { HamburgerView } from "coms/ui/HamburgerView";
//import { DropdownButton } from "../../../../coms/ui/DropdownButton";
import { EditContactInfo } from "./EditContactInfo";
import { unFlatObject, flatObject } from 'utils';
import './edit-contact.less';
import { EditContactContacts } from "./EditContactContacts";
import { EditContactBuildings } from "./EditContactBuildings";
import { Model } from "vendors";
import { ButtonView } from "../../../../coms/ui/Button";
import { addArray } from "../../edit-common";
import { modalsApi } from "../../../../apis/modals";

import { backendApi } from "../../../../apis/backend";
import { urls } from 'coms/urls';
import { tryModalError } from "./utils";

// const defaultShowOptions = {
// 	modalCssCfg: "fullscreen",
// 	cssClasses: {
// 		dialog: "edit-contact-modal-dialogue",
// 		content: 'no-padding'
// 	}
// }


const CreateActionsView = HamburgerView.extend({
	baseClassName: 'actions-container',
	childrenViews:[
		{
			class: ButtonView,
			baseClassName: 'btn-primary',
			text: 'Добавить',
			triggers: {
				'click':'add:new:click'
			}
		}
	],
	childViewTriggers: {
		'add:new:click':'add:new:contact'
	}
});


export const EditContactLayout = HamburgerView.extend({
	thisClassName: 'edit-contact-layout',
	initialize() {
		this.modelHash = this.model 
			? flatObject(this.model.attributes)
			: {};
		this.editModel = new Model();
		this.isClient = this.getOption('client') != null;
		
	},
	childViewOptions() {
		const isClient = this.isClient === true;
		return {
			isClient,			
		}
	},
	getChildren() {
		return [
			{
				class: EditContactInfo,
				modelHash: this.modelHash,
				editModel: this.editModel,
				trackChanges: true,
				owner: this.getOption('owner', true),
				client: this.getOption('client', true),
				isNew: this.getOption('isNew', true),
			},
			{
				class: EditContactContacts,
				modelHash: this.modelHash,
				editModel: this.editModel,
				owner: this.getOption('owner', true),
				client: this.getOption('client', true),				
				isNew: this.getOption('isNew', true),
			},
			v => v.isClient ? undefined : {
				class: EditContactBuildings,
				modelHash: this.modelHash,
				editModel: this.editModel,
				owner: this.getOption('owner', true),
				isNew: this.getOption('isNew', true),
				realties: this.getOption('realties', true),
				autoJoinRealty: this.getOption('realtyId', true)
			},
			!this.model && {
				class: CreateActionsView,
				owner: this.getOption('owner', true),
				client: this.getOption('client', true)
			}
			// EditContactInfo
		]
	},
	ifNoFuncsDefined(flatModel) {
		const prop = this.isClient ? 'clientFuncs' : 'ownerFuncs';
		return !flatModel[prop];
	},
	childViewEvents: {
		async 'add:new:contact'() {

			const errors = [];
			const add = v => addArray(errors, v);
			const flatModel = flatObject(this.modelHash);
			const unflatModel = unFlatObject(this.modelHash);
			console.warn('model hash', this.modelHash);
			console.warn('flatmodel', flatModel);
			console.warn('unflat model', unflatModel);
			if (!(flatModel.contactName || flatModel['person.name.first'] || flatModel['person.name.last'] || flatModel['person.name.middle'])) {
				add('укажите название или ФИО контакта');
			}
			if (this.ifNoFuncsDefined(flatModel)) {
				add('укажите функции контакта');
			}
			if (!this.modelHash.values?.length) {
				add('Добавьте телефон или email');
			}

			if (errors.length) {
				modalsApi.oldError(errors, 'Исправьте ошибки');
				return;
			}
			const owner = this.getOption('owner');
			const contacts = this.getOption('contacts');
			const client = this.getOption('client');
			let url;
			if (client) {
				url =  urls.api(`v4/clients/${client.id}/contacts`);
			} else {
				const oid = owner.id;
				url =  urls.api(`v4/realties/owners/${oid}/contacts`);
			}
			const sync = (data) => {
				if (contacts) {
					contacts.add(data);
				}
				this.triggerMethod('edit:done', data);
			};

			const res = await backendApi.postAsync(unflatModel, url, { sync, relativeUrl: false });
			tryModalError(res, 'что-то пошло не так и произошла ошибка');

		}
	}
});