import { modelSchemaApi, propertySchemaApi } from 'apis/schema';
import { HamburgerView } from '../HamburgerView';
import { ValueWithLabelView } from '../ValueWithLabel';
import { modelValueMixin } from './common';
import { PropertyView } from './property-view';


const PropertiesLineMixin = {
	initialize(options) {
		this.mergeOptions(options, ['modelSchema']);
		if (!this.modelSchema) {
			this.modelSchema = {};
		}
	},
	getPropertySchema(property) {

		if (!property) { return property; }
		let type = typeof property;
		if (type === 'object') {
			return property;
		}
		if (type === 'string') {
			if (!this.modelSchema && this.options.modelSchema) {
				this.modelSchema = this.options.modelSchema;
			}
			return modelSchemaApi.getPropertySchema(property, this.modelSchema);
			//return this.modelSchema && this.modelSchema[property];
		}
	},
	getProperties() {
		return this.getOption('properties', true) || [];
	},
	...modelValueMixin
}

export const PropertiesLineView = HamburgerView.extend({

	...PropertiesLineMixin,

	baseClassName: 'ui2-line',
	classNames: [
		v => 'items-' + v.getProperties().length
	],



	getProperties() {
		if (this._properties) {
			return this._properties;
		}
		let properties = this.getOption('properties', true) || [];
		this._properties = properties;
		// console.log('###', properties);
		return this._properties;
	},

	getChildren() {
		let properties = this.getProperties();
		const propViews = properties.map(property => ({
			class: PropertyView,
			model: this.model,
			editModel: this.getOption('editModel', true),
			modelValue: this.getOption('modelValue', true),
			modelHash: this.getOption('modelHash', true),
			flatModel: this.getOption('flatModel', true),
			initialKeyValues: this.getOption('initialKeyValues', true),
			propertySchema: property,
			modelSchema: this.modelSchema,
			editEnabled: this.getOption('editEnabled'),
			editAction: this.getOption('editAction'),
			trackChanges: this.getOption('trackChanges', true),
			setValueBySchemaApi: this.getOption('setValueBySchemaApi', true),
			rejectHardBehavior: this.getOption('rejectHardBehavior'),
			onPropertyChange: (newvalue, propertySchema, modelValue, modelSchema) => {
				this.triggerMethod('property:change', newvalue, propertySchema, modelValue, modelSchema);
			}
		}));
		// console.warn('###', propViews);
		return propViews;
	},

});


export const PropertiesLinesView = HamburgerView.extend({
	baseClassName: 'ui2-lines',
	...PropertiesLineMixin,

	getPropertiesLines() {
		if (this._propertiesLines) {
			return this._propertiesLines;
		}
		let properties = this.getProperties();
		let flatArray;
		let itemsInLine = this.getOption('itemsInLine') || 2;
		let lines = [];
		
		for(let x = 0; x < properties.length; x++) {

			let item = properties[x];
			let isArray = Array.isArray(item);
			let restrictedValue = isArray;
			if (flatArray === restrictedValue) {
				throw new Error('mixed properties array. you should provide flat or 2d array');
			} else {
				flatArray = !isArray;
			}

			if (flatArray && item) {
				let lineIndex = Math.floor(x/itemsInLine);
				let line = lines[lineIndex] || [];
				//let propertySchema = this.getPropertySchema(item);
				line.push(item);
				lines[lineIndex] = line;
			} else if (!flatArray && item) {
				lines.push(item);
			}

		}

		this._propertiesLines = lines;

		return this._propertiesLines;
	},
	_getPropertiesLine(properties) {
		let showValueless = this.getOption('showValueless');
		const modelValue = this.getModelValue();
		if (showValueless === false) {
			let modelSchema = this.getOption('modelSchema');
			
			properties = properties.filter(property => {
				let value = propertySchemaApi.getValue(property, modelValue, modelSchema);
				return value != null;
			});
				
		}
		if (properties.length > 0) {
			return {
				class: PropertiesLineView,
				modelSchema: this.modelSchema,            
				model: this.model,
				editModel: this.getOption('editModel', true),
				modelValue: this.getOption('modelValue', true),
				modelHash: this.getOption('modelHash', true),
				flatModel: this.getOption('flatModel', true),
				initialKeyValues: this.getOption('initialKeyValues', true),
				properties,
				editEnabled: this.getOption('editEnabled'),
				editAction: this.getOption('editAction'),
				trackChanges: this.getOption('trackChanges', true),
				setValueBySchemaApi: this.getOption('setValueBySchemaApi', true),
				rejectHardBehavior: this.getOption('rejectHardBehavior'),
				onPropertyChange: (newvalue, propertySchema, modelValue, modelSchema) => this.triggerMethod('property:change', newvalue, propertySchema, modelValue, modelSchema)
			}
		}
	},
	getChildren() {
		let lines = this.getPropertiesLines();
		return lines.map(properties => this._getPropertiesLine(properties));
	}

});


export const SmartPropertiesLinesView = PropertiesLinesView.extend({
	getPropertiesLines() {
		let properties = this.getProperties();
		
		let lines = properties.reduce((items, item) => {
			if (!item) { return items; }

			let isArray = typeof item === 'object' && Array.isArray(item);

			let lines = isArray ? item : [item];
			
			items.push(lines);
			return items;
		}, []);

		return lines;
	},
});