﻿//define('svc-deals-deal-infoInBlock', ['bus-svc','ui-controls-editValue'], function (radio, editValue) {});

import radio from 'bus/svc';
import editValue from 'ui/controls/editValue';
import template from './infoInBlock.html';
import { MneView } from 'core/mne';
import appCfg from 'app-config';
import { textToBuffer } from 'utils';
import { modalsApi } from 'apis/modalsApi2';

var View = MneView.extend({
	className:'block deal-info',
	//template: '#tmpl-service-deals-deal-infoInBlock',
	template,
	modelEvents: {
		'change':'render'
	},
	events: {
		'click .deal-name-holder > div': function () {
			var model = this.model;
			editValue.modal.string({
				value: model.get('name')
			}).then(function (type, value, oldValue) {
				if (type == 'ok' && value) {
					model.loudPatch({ name: value });
				}
				console.log('arguments', arguments);
			});
		},
		'click .change-owner' () {
			this.model.executeAction({ action: 'changeOwner' });
		},
		'click .copy-url'() {
			let url = appCfg.urls.url('deal/' + this.model.get('wId'));
			textToBuffer(url);
			modalsApi.message("В буффер скопирована ссылка на процесс:<br>" + url);
			console.log('URL', url);
		}
	},
	templateContextExtend: function () {
		var _ownerName = radio.request('employeeName', this.model.get('ownerId'));
		const _ownerClassName = this.model.getAction('changeOwner') ? 'list-btn change-owner' : 'list-lbl';
		return {
			_ownerName: _ownerName,
			_ownerClassName
		}
	},
});

export default View;