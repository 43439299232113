import { invokeValue, isView } from "utils";
import { NextIconButtonView } from "coms/ui/Button";
import { HamburgerView } from "coms/ui/HamburgerView";
import { View, TextView } from "core";
import { _ } from 'vendors';

export const ModalBoxWrapper = HamburgerView.extend({
	baseClassName: 'v2-modal-box-wrapper',
	getChildren() {
		return {
			modalbox: this.getOption('modalBox')
		}
	}
});

export const ModalFooterView = HamburgerView.extend({
	name: 'footerView',
	tagName: 'footer',
	baseClassName: 'v2-modal-footer',
	mergeOptionsKeys: ['modalBoxView', 'modalView'],
	childViewOptionsKeys: ['modalView', 'modalBoxView'],
	// initialize(options) {
	// 	this.merge
	// },
	childViewTriggers: {
		'modal:resolve':'modal:resolve',
		'modal:reject':'modal:reject',
		'modal:reject:hard':'modal:reject:hard',
		'modal:rejectHard':'modal:rejectHard',
	},

	// childViewOptions() {
	// 	return {
	// 		modalView: this.getOption('movalView'),
	// 		modalBoxView: this.getOption('movalBoxView'),
	// 	}
	// },

	disableButton(name, value) {
		const btnView = this.getNamedView(name);
		if (!btnView) { return; }
		btnView.disable(value);
	},
	disableButtons(value, excludeView) {
		const hash = this.getNamedViews();
		Object.keys(hash).forEach(key => {
			const view = hash[key];
			if (view !== excludeView) {
				view.disable(value);
			}
		});
	},

	childViewEvents: {
		'before:action:executed'(btn) {
			this.disableButtons(true, btn);
		},
		'action:executed'(btn, result) {
			this.disableButtons(false, btn);
			if (result.ok) {
				const buttonName = btn.getOption('name');
				const eventName = 'modal:' + buttonName;
				// console.log('triggered:', eventName);
				this.trigger(eventName, result.value);
			} else {
				if (result.error instanceof Error) {
					throw result.error;
				} else {
					console.error(result.error);
				}
			}
		},
	},

	// onRender() {
	// 	console.log('_FOOTER_BUTTONS_RENDER_');
	// }

});

export const ModalCloseButton = NextIconButtonView.extend({
	className: 'v2-modal-close-button',
	icon: 'fa:close',
	name: 'closeButton',
	action: 'trigger:close:modal'
});

export const ModalHeaderTextView = TextView.extend({
	className: 'v2-modal-header-text',
});

export const ModalHeaderView = HamburgerView.extend({
	tagName: 'header',
	baseClassName: 'v2-modal-header',
	mergeOptionsKeys: ['modalBoxView', 'modalView'],

	getChildren() {
		return [
			this.getHeader(), 
			this.getClose()
		]
	},
	getClose() {
		if (this.getOption('noCloseButton')) { return; }
		const own = this.getOption('closeButtonView');
		if (own) {
			return own;
		}

		const view = { 
			class: ModalCloseButton,

		};

		const ownHtml = this.getOption('closeButtonText');
		if (ownHtml) {
			view.wrapText = false;
			view.text = ownText;
		}

		return view;
	},
	getHeader() {
		const text = this.getOption('header');
		return { class: ModalHeaderTextView, text };
		// {
		// 	class: View,
		// 	className: 'v2-modal-header-text',
		// 	template: '<%= text %>',
		// 	templateContext: {
		// 		text
		// 	}
		// }
	},
	childViewTriggers: {
		'close:modal':'close:modal'
	}
});

export const ModalBox = HamburgerView.extend({
	baseClassName: 'v2-modal-box',
	mergeOptionsKeys: ['modalView'],
	// initialize() {
	// 	this.on('all', c => console.log('[m-b]',c));
	// },
	childViewOptions() {
		return {
			modalView: this.modalView,
			modalBoxView: this,
		}
	},	
	getChildren() {
		const views = [
			this.getHeader(),
			this.getContent(),
			this.getFooter()
		];
		return views;
	},
	getHeader() {
		let headerView = this.getOption('headerView');
		if (headerView) {
			return headerView;
		}
		
		const view = this.getOptions(['noCloseButton', 'header', 'closeButtonView', 'closeButtonText']);
		if (view.noCloseButton && !view.header) { return; }

		view.class = ModalHeaderView;
		return view;
	},
	getContent() {
		const content = this.getOption('modalContent');
		const thisClassName = this.getOption('modalContentContainerClassName');
		
		return {
			class: HamburgerView,
			tagName: 'section',
			baseClassName: 'v2-modal-content-container',
			thisClassName: thisClassName,
			childrenViews: [content],
			childViewOptions: {
				modalBoxView: this.modalBoxView,
				modalView: this.modalView,
			},
			childViewTriggers: {
				'destroy': 'close:modal',
				'close:modal':'close:modal',
				'modal:resolve':'modal:resolve',
				'modal:reject':'modal:reject',
				'modal:reject:hard':'modal:reject:hard',
				'modal:rejectHard':'modal:rejectHard',
				'disable:resolve:button':'disable:resolve:button',
			}
		}
	},
	getFooter() {
		const own = this.getOption('footerView');
		if (own) { return own; }
		return this.getFooterButtonsView()
	},
	getFooterButtonsView() {
		
		const keys = ['resolveButton', 'rejectButton', 'rejectHardButton'];
		
		const separators = this.getOptions(['beforeResolveSeparator', 'resolveRejectSeparator', 'rejectRejectHardSeparator', 'afterRejectHardSeparator']);
		const useAllSeparators = this.getOption('footerButtonsSeparators') === true;
		const { 
			beforeResolveSeparator = useAllSeparators, resolveRejectSeparator = useAllSeparators,
			rejectRejectHardSeparator = useAllSeparators, afterRejectHardSeparator = useAllSeparators
		} = separators;
		
		let buttons = this.getOption('footerButtons');
		
		let actions = this.getOptions(['resolveAction', 'rejectAction', 'rejectHardAction'], false);
		

		if (!buttons) {
			buttons = this.getOptions(keys);
		}

		const children = _.reduce(buttons, (res, rawBtn, key) => {

			rawBtn = invokeValue(rawBtn, this, this);
			if (rawBtn == null) { return res; }

			let btn;
			let buttonName;

			if (!isView(rawBtn)) {

				const typeOfKey = typeof key;
				key = (typeOfKey === 'number' ? keys[key] : key) || '';
				buttonName = key.replace('Button', '');
				const action = actions[buttonName + 'Action'];
				const typeOfRaw = typeof rawBtn;
	
				if (typeOfRaw === 'string') {
					btn = { text: rawBtn };
				} else if (typeOfRaw === 'object') {
					btn = rawBtn;
				} else {
					return res;
				}

				btn = Object.assign({
					class: NextIconButtonView,
					thisClassName: buttonName,
					name: buttonName,
					action,
				}, btn);
				

			} else {
				buttonName = btn.getOption('name');
			}

			let finalSeparator
			if (buttonName === 'resolve' && beforeResolveSeparator) {
				res.push(separator('before-resolve'));
			} else if (buttonName === 'reject' && resolveRejectSeparator) {
				res.push(separator('resolve-reject'));
			} else if (buttonName === 'rejectHard' && rejectRejectHardSeparator) {
				res.push(separator('reject-rejecthard'));
				if (afterRejectHardSeparator) {
					finalSeparator = separator('after-rejecthard');
				}
			}
			// console.log('------- btn ------', btn);
			res.push(btn);
			if (finalSeparator) {
				res.push(finalSeparator);
			}



			return res;
		}, []);

		if (!children.length) {
			return;
		}

		return {
			class: ModalFooterView,
			baseClassName: 'v2-modal-footer',
			thisClassName: this.getOption('footerClassName'),
			childrenViews: children
		}

	},
	childViewTriggers: {
		'close:modal':'close:modal',
		'modal:resolve':'modal:resolve',
		'modal:reject':'modal:reject',
		'modal:reject:hard':'modal:reject:hard',
		'modal:rejectHard':'modal:rejectHard',
	},
	childViewEvents: {
		'disable:resolve:button'(value) {
			const footer = this.getNamedView('footerView');
			if (!footer) {
				console.warn('footer not found');
				return;
			}
			footer.disableButton('resolve', value);
		}
	}
});

export const ModalBgView = View.extend({
	className: 'v2-modal-bg',
	triggers: {
		'click':'bgclick'
	}
});

export const ModalView = HamburgerView.extend({
	classNames: ['v2-modal-container', v => v.getOption('modalClassName', true)],

	childViewOptions() {
		return {
			modalView: this
		}
	},

	getChildren() {
		const bgView = this.getBgView();
		const modalContent = this.getContentView();
		return [bgView, modalContent];
	},

	getBgView() {
		if (this.getOption('noBg')) {
			return;
		}
		return ModalBgView;
	},

	getContentView() {	
		
		const box = this.getModalBox();
		const noWrapper = this.getOption('noModalBoxWrapper');
		if (noWrapper) {
			return box; 
		} else {
			return {
				class: ModalBoxWrapper,
				childViewOptionsKeys: ['modalView'],
				modalBox: box,
				childViewTriggers: {
					'close:modal':'close:modal',
					'modal:resolve':'modal:resolve',
					'modal:reject':'modal:reject',
					'modal:reject:hard':'modal:reject:hard',
					'modal:rejectHard':'modal:rejectHard',
				}			
			}
		}

	},

	getModalBox() {
		const box = this.getOption('modalBoxView');
		if (box) {
			return box;
		} else {

			const options = this.getOptions([
				'modalContent', 'modalContentContainerClassName', 
				'noCloseButton', 'closeButtonView', 'closeButtonText', 'headerView', 'header', 
				'footerButtons', 'footerView', 'resolveButton', 'rejectButton', 'rejectHardButton', 
				'beforeResolveSeparator', 'resolveRejectSeparator', 'rejectRejectHardSeparator', 'afterRejectHardSeparator',
				'footerClassName', 'footerButtonsSeparators'
			]);

			const methods = this.getOptions(['resolveAction', 'rejectAction', 'rejectHardAction'], false);

			_.each(methods, (handler, key) => {
				if (typeof handler === 'function') {
					methods[key] = handler.bind(this);
				}
			});

			const view = Object.assign({ class: ModalBox, thisClassName: this.getOption('modalBoxClassName') }, methods, options);

			return view;
		}
	},

	childViewEvents: {
		'close:modal'() {
			this.trigger('modal:reject');
		},
		'bgclick'() {
			if (this.getOption('destroyOnOutsideClick') && !this.destroyOnOutsideClickDisabled) {
				this.trigger('modal:reject');
			}
		}
	},

	childViewTriggers: {
		'modal:resolve':'modal:resolve',
		'modal:reject':'modal:reject',
		'modal:reject:hard':'modal:reject:hard',
		'modal:rejectHard':'modal:rejectHard',	
	},

	setSettleWith(type, value) {
		const key = '_' + type + 'With';
		this[key] = value;
	},

	getSettleWith(type) {
		const optionsKey = type + 'With';
		const key = '_' + optionsKey;
		if (key in this) {
			return this[key];
		}
		return this.getOption(optionsKey, true);
	},

});


function separator(spec) {
	return {
		class: View,
		tagName: 'span',
		separator: spec,
		className: 'separator ' + spec,
		//name: spec + '-separator'
	};
}