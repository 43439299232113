import { urls } from 'coms/urls';
import { Model, ApiCollection } from 'core';

export const OwnerModel = Model.extend({});

export function ownersUrl() {
	return urls.api('v4/realties/owners');
}

OwnerModel

export const OwnersCollection = ApiCollection.extend({
	url() {
		return ownersUrl(); //urls.api('v4/realties/owners')
	}
});

export const OwnerRealtyModel = Model.extend({
	getLabel() {
		return this.get('realtyName');
	}
});