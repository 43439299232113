import { lastItem } from 'utils/array-manipulations';
// import { domApi } from '../domApi.js';
import { Events } from 'vendors';
import { errResult, okResult } from 'utils/tryAsync';
import { invokeValue } from 'utils/invoke';

const dom = {
	...Events
}


export const stack = {
	modals: [],
	create (view, options = {}, afterCallback) {
		
		const getSettleAction = (type) => {
			const key = type + 'With';
			let action = view.getOption(key, false);
			if (action == null) {
				action = options[key];
			}
			return action;
		}
		
		const { destroyOnEsc, destroyOnOutsideClick, destroyOnSettle, resolveOnDestroy } = options;
		
		if (destroyOnEsc) {
			this._destroyOnEsc(view);
		}
		if (destroyOnOutsideClick) {
			this._destroyOnOutsideClick(view);
		}

		let promise = new Promise((resolve, reject) => {
			view.once({
				'modal:resolve': (data) => resolve(okResult(data)),
				'modal:reject:hard': (data) => resolve(errResult(data, { type: 'hard' })),
				'modal:rejectHard': (data) => resolve(errResult(data, { type: 'hard' })),
				'modal:reject': (data) => resolve(errResult(data, { type: 'soft' })),
				'destroy': () => {
					this.remove(view);
					if (resolveOnDestroy) {
						const resolveWith = getSettleAction('resolve');
						let res = invokeValue(resolveWith, view, view);
						resolve(okResult(res));
					} else {
						const rejectWith = getSettleAction('resolve');
						resolve(errResult(rejectWith, { type: 'soft' }));
					}
				}
			});
		});

		if (destroyOnSettle) {
			promise = promise.then(res => {
				view.destroy();
				return res;
			});
		}

		promise.view = view;

		setTimeout(() => {
			this.modals.push(view);
			if(typeof afterCallback === 'function') {
				afterCallback();
			}
		}, 0);

		// promise = promise.then(
		// 	value => ({ ok: 1, value }),
		// 	error => ({ ok: 0, error })
		// );

		return promise;
	},
	_destroyOnEsc(view) {
		if (!this._escapeListener) {
			this._escapeListener = event => {
				if (event.which === 27) {
					dom.trigger('escape');
				}
			}
			document.addEventListener('keyup', this._escapeListener);
		}
		view.listenTo(dom, 'escape', () => {
			if (this.isLast(view) && !view.destroyOnEscDisabled) {
				view.destroy();
			}
		});
	},
	_destroyOnOutsideClick(view) {
		if (!this._clickListener) {
			this._clickListener = event => {
				dom.trigger('click', event.target);
			}
			document.addEventListener('click', this._clickListener);
		}
		view.listenTo(dom, 'click', (target) => {
			if (view.el.isConnected && this.isLast(view) && !view.destroyOnOutsideClickDisabled) {
				if (!view.el.contains(target)) {
					//console.log(target, view.el, view.el.isConnected);
					view.destroy();
				}
			}
		});
	},
	isLast(view) {
		return lastItem(this.modals) === view;
	},
	remove(view) {
		const index = this.modals.indexOf(view);
		if (index === -1) return;
		this.modals.splice(index, 1);
	}
}