import { userConfig } from "mods/acc";
import { errResult, okResult } from "../../utils";

import { GooglemapsInitializer } from "./GooglemapsInitializer";
import { MapFilters } from "./MapFilters";
import { YandexmapsInitializer } from "./YandexmapsInitializer";
import { SearchMapApi } from "./SearchMapApi";
import { userFeature } from "./common";


export const mapsApi = {
	engineType: 'yandex',
	initializers: {
		yandex: YandexmapsInitializer,
		google: GooglemapsInitializer
	},

	async initialize(engineType) {

		if (this._injectResult) {
			if (this._injectResult.ok) {
				return;
			} else {
				throw this._injectResult.error;
			}
		}

		engineType = engineType || this.engineType;
		const Initializer  = this.initializers[engineType];

		this._initializer = new Initializer();
		const name = this._initializer.name;

		const res = await this._initializer.inject();
		this._injectResult = res;
		console.log('map inject result', res);
		if (!res.ok) {
			// this._initializerError = res.error || 'map inject error';
			throw res.error;
		}
		console.warn(name + ' map script injected');

	},

	async whenReady(options = {}) {
		if (typeof options === 'function') {
			options = { success: options };
		}
		let { success, engineType } = options;
		try {
			await this.initialize(engineType);
			let value;
			if (typeof success === 'function') {
				value = success();
			}
			return okResult(value);
		} catch(exc) {
			console.error('error', exc);
			return errResult(exc);
		}
	},

	getEngine() {
		return this._initializer;
	},

	createSearchApi(options = {}) {
		let { mapFilters, mapFiltersOptions } = options;
		if (!mapFilters) {
			mapFilters = this.createMapFilters(mapFiltersOptions);
		}
		options = Object.assign({}, options, { mapFilters, mapsApi: this });
		return new SearchMapApi(options);
	},

	startSearchApi(api, options = {}) {
		const engine = this.getEngine();
		if (api) {
			api.start({ ...options, engine });
		} else {
			console.warn('search api missing');
		}
	},


	attachMapToView(view, el, mapExtraOptions) {
		const engine = this.getEngine();
		return engine.attachMapToView(view, el, mapExtraOptions);
	},

	createMapFilters(options = {}) {
		const { 
			getFiltersHash, 
			method,
			setup
		} = options;
		const raw = getFiltersHash();
		return new MapFilters(raw, { getFiltersHash, method, setup });
	},

	userFeature(key, value) {
		return userFeature.apply(null, arguments);
	},

	setMapCenter(map, center, zoom) {
		const engine = this.getEngine();
		return engine.setMapCenter(map, center, zoom);
	},

	createMarker(options) {
		const engine = this.getEngine();
		
		return engine.createMarker(options);
	},
	createRealtySearchMarker(options) {
		const engine = this.getEngine();
		
		return engine.createRealtySearchMarker(options);
	},
	updateMarker(map, marker, options) {
		const engine = this.getEngine();
		return engine.updateMarker(map, marker, options);
	},
	showMarker(map, marker) {
		const engine = this.getEngine();
		return engine.showMarker(map, marker)
	},

	hideMarker(map, marker) {
		const engine = this.getEngine();
		return engine.hideMarker(map, marker)
	},

	setupAddressApi(map, options)	 {
		const engine = this.getEngine();
		return engine.setupAddressApi(map, options);
	},

	// deprecated
	setupSearchInput(map, inputEl, options) {
		const engine = this.getEngine();
		return engine.setupSearchInput(map, inputEl, options);
	},
	// deprecated
	getFindAndShowAddressCallback(map, options) {
		const engine = this.getEngine();
		return engine.getFindAndShowAddressCallback(map, options);
	},


	addOnResize(options) { 
		const engine = this.getEngine();
		engine.addOnResize(options);
	},

	addOnUpdate(options) {
		const engine = this.getEngine();
		engine.addOnUpdate(options);
	},

	addViewEvent(map, eventEntity, appEventName, callback, view) {
		let listener = this.on(map, eventEntity, appEventName, callback);
		
		if (view) {
			view.once('before:destroy', () => {
				this.off(map, eventEntity, appEventName, listener || callback, callback);
			});
		}
	},

	on(map, eventEntity, appEventName, callback) {
		const engine = this.getEngine();
		return engine.on(map, eventEntity, appEventName, callback);
	},

	off(map, eventEntity, appEventName, listener, callback) {
		const engine = this.getEngine();
		return engine.off(map, eventEntity, appEventName, listener, callback);
	},


	geocode(addressText, options) {
		const engine = this.getEngine();
		return engine.geocode(addressText, options);
	},

	geodecode(lat, lng, options) {
		const engine = this.getEngine();
		return engine.geodecode(lat, lng, options);
	},

	geodecodeToHash(places, coords) {
		const engine = this.getEngine();
		return engine.geodecodeToHash(places, coords)
	},


	redrawMap(map) {
		const engine = this.getEngine();
		return engine.redrawMap(map);
	}

}