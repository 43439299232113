import { entityContactsApi } from "../entityContactsApi";
import { EntityContactsBox } from "./EntityContactsBox";
import appCfg from 'app-config';
export const ClientContactsBox = EntityContactsBox.extend({
	entityType: 'client',
	headerOptions() {
		const rightButtonView = this.getHeaderRightButtonView();
		const deal = this.getOption('deal');
		let text = 'контакты';
		if (deal) {
			let client = deal.get('client');
			let url = appCfg.urls.url('client/'+ client.wId);
			text = client.name;
			text = `<a href="${url}" target="_blank">${text}</a>`
		}
		return {
			text,
			rightButtonView
		}
	},
	getItemFuncs(model) {
		return entityContactsApi.displayClientFuncs(model);
	},
	amICanEdit() {
		const client = this.getOption('client');
		const deal = this.getOption('deal');
		if (deal) {
			return entityContactsApi.isDealClientEditAllowed(deal);
		} else if (client) {
			return entityContactsApi.isClientEditAllowed(client);
		}
		// const creator = client.get('creatorId');
		// const action = client.getAction('addContact');
		// return action != null;
	},
	isAddContactAllowed() {
		return this.amICanEdit();
	},
	isEditContactAllowed() {
		return this.amICanEdit();
	},
	isDeleteContactAllowed() {
		return this.amICanEdit();
	}
});
