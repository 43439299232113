import { _ } from 'vendors';

function formatPhoneNumber(numberValue, separator = '-') {
	if (!numberValue) { return ''; }

	let text = '';

	while (numberValue.length > 3) {
		const chunk = numberValue.substring(numberValue.length - 2);
		text = separator + chunk + text;
		numberValue = numberValue.substring(0, numberValue.length - 2);
	}

	text = numberValue + text;

	return text;
}


const phoneTemplate = _.template(`<<%= tag %> class="phone-number" <%= href %>>
<span class="country-code"><%= country %></span>
<span class="code"><%= code %></span>
<span class="number"><%= number %></span>
<%= add %>
</<%= tag %>>`);

export function displayPhone(arg, { url } = {}) {

	if (!arg) { return; }

	let value, add;

	if (Array.isArray(arg)) {
		value = arg[0];
		add = arg[1];
	} else {
		value = arg;
	}

	if (!value) { return; }

	let [ country, code, number ] = value.split('.');

	if (country !== '8') {
		country = '+' + country;
	}
	const clickable = country + code + number;
	number = formatPhoneNumber(number);
	
	if (add) {
		add = '<span class="phone-postfix">доб. ' + add + '</span>';
	} else {
		add = '';
	}

	const cntx = {
		tag: url ? 'a' : 'span',
		href: 'href="tel:' + clickable + '"',
		country, 
		code, 
		number,
		add,
	}

	const txt = phoneTemplate(cntx);

	return txt;
	
}


const emailTemplate = _.template(`<<%= tag %> class="email" <%= href %>>
<%= email %>
</<%= tag %>>`);

export function displayEmail(email, { url } = {}) {
	if (!email) { return ''; }
	if (Array.isArray(email)) {
		email = email[0];
	}
	const cntx = {
		tag: url ? 'a' : 'span',
		href: 'href="mailto:' + email + '"',
		email
	}

	return emailTemplate(cntx);
}


export function displayPhoneValue(value, add) {
	if (!value) { return ''; }
	let [ country, code, number = '' ] = value.split('.');
	number = formatPhoneNumber(number);
	if (country !== '8') {
		country = '+' + country;
	}
	if (add) {
		add = ', доб. ' + add;
	}	else {
		add = '';
	}
	return country + ' ' + code + ' ' + number + add;
}

export function hrefPhoneValue(value) {
	value = value.replace(/[^\d]/g, '');
	if (value[0] !== '8' && value[0] !== '+') {
		value = '+'+value;
	}
	return 'tel:' + value;
}