﻿// define('svc-realties-offers-views-cardInModal', [
// 	'bus'
// 	, 'app-paths'
// 	, 'svc-realties-offers-views-cardInModal-offerInfo'
// 	, 'svc-realties-offers-views-cardInModal-objectCommon'
// 	, 'svc-realties-offers-views-cardInModal-objectOffer'
// 	, 'svc-realties-offers-views-cardInModal-objectWarehouse'
// 	, 'svc-realties-offers-views-cardInModal-objectRetail'
// 	, 'svc-realties-offers-views-cardInModal-objectApartment'
// 	, 'svc-realties-offers-views-cardInModal-realty'
// 	, 'ui-layouts-contactsList'
// ], function (Bus, paths, OfferInfo, ObjectCommon, OfObject, WhObject, RetObject, ApartObject, RealtyRegionView, ContactsList) {});

import Bus from 'bus';
import paths from 'app-paths';
import OfferInfo from './offerInfo';
import ObjectCommon, { ObjectCommonView } from './objectCommon';
import OfObject from './objectOffer';
import WhObject from './objectWarehouse';
import RetObject from './objectRetail';
import ApartObject from './objectApartment';
import RealtyRegionView from './realty';
import ContactsList from 'ui/layouts/contactsList';

import template from './layout.html';

import './offer-modal-dialog.less';
import './rgadmin.less';

import { BbeModel, BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';
import { RealtyBoxViewWraper } from 'mods/realties/realties/info-block';
import { RealtyObjectBoxViewWraper } from './object-box-wrapper';
import { RealtyOfferBoxViewWraper } from 'mods/realties/offers/info-block';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { UiColumnView } from 'coms/ui/UiColumnView';
import { realtyOffersFullStore } from '../../../../../../mods/realties/offers/store';
import { ContactsInRealtyModel } from 'm/realties/owners';
import { shortRealties } from '../../../../../../mods/realties/realties/realties';
import { ContactsBox } from 'coms/ui/ContactsBox';

var RgadminModel = BbeModel.extend({
	urlRoot: function () {
		return paths.api.rgadmin + '/' + this.get('type')
	},
});

var PropView = MneView.extend({
	className:'rgadmin-property',
	template: _.template('<small><%= label%></small><span><%= _.escape(value) %></span>')
});

var RgAdminView = MneView.extend({
	template: _.template("<div></div>"),
	regions: {
		'list': {el:'> div', replaceElement:true},
	},
	modelEvents: {
		'change':'render',
	},
	onRender: function () {
		var props = [];
		_(this.model.toJSON()).each(function (val, key) {
			props.push({ value: val, label: key });
		});
		var coll = new BbeCollection(props);
		var view = new MneNextCollectionView({
			childView: PropView,
			collection:coll
		});
		this.showChildView('list', view);
	}
});



var objectSpecialViews = {
	of: OfObject,
	wh: WhObject,
	ret: RetObject,
	apart: ApartObject,
};

export const OfferCardModalView = MneView.extend({
	className:'offer-in-modal',
	//template: '#tmpl-service-realties-offers-views-cardInModal-layout',
	template,
	regions: {
		'offer-info': { el: '.info-region', replaceElement: true },
		'object-of': { el: '.of-region', replaceElement: true },
		'object-wh': { el: '.wh-region', replaceElement: true },
		'object-ret': { el: '.ret-region', replaceElement: true },
		'object-apart': { el: '.apart-region', replaceElement: true },
		'object-common': { el: '.common-region', replaceElement: true },
		'realty': { el: '.realty-region', replaceElement: false },
		'owner': { el: '.owner-region', replaceElement: true },
		'object': { el: '.object-region', replaceElement: false }
	},
	ui: {
		'rgadmin-block': '.rgadmin-block',
		'rgadmin-bc': '.rgadmin-bc',
	},
	events: {
		'click @ui.rgadmin-block': function () {
			var rgadmin = new RgadminModel({ id: this.model.id, type: "block" });
			this.modalRgAdmin(rgadmin);
		},
		'click @ui.rgadmin-bc': function () {
			var rgadmin = new RgadminModel({ id: this.model.get('realtyId'), type: "bc" });
			this.modalRgAdmin(rgadmin);
		},
	},
	modalRgAdmin: function (rgadmin) {
		rgadmin.loudFetch();
		var view = new RgAdminView({ model: rgadmin });
		Bus.modals.request('modal', view);
	},
	onRender: function () {
		// // this.showObjectSpecials();
		// // this.showObjectCommon();
		this.showOfferInfo();
		this.showObject();
		this.showRealty();
		//this.showContacts();
	},
	_getContactsInRealtyModel() {
		if (!this.contactsModel)  {
			const { ownerId, realtyId }  = this.model.attributes;
			this.contactsModel = new ContactsInRealtyModel({ ownerId, realtyId });
		}
		return this.contactsModel;
	},
	showContacts: async function () {
		if (!this.contactsModel)  {
			const { ownerId, realtyId }  = this.model.attributes;
			this.contactsModel = new ContactsInRealtyModel({ ownerId, realtyId });
		}
		let contacts = this.contactsModel;
		if (contacts == null) {
			console.error('no owner contacts defined', this.model);
			return;
		}
		// console.warn('typeof loudFetch', typeof contacts.loudFetch, contacts.loudFetch);
		// contacts.loudFecth();
		//var contacts = this.model.getOwnerContacts();
		await contacts.fetch();
		
		
		// var view = this.clientView = ContactsList.ForOwnerInRealty(contacts, { className: 'owner-contacts-box' });
		let view = ContactsList.ForOwnerInRealty(contacts, { className: 'owner-contacts-box' });
		
		
		this.showChildView('owner', view);

		// contacts.getApiPromise().then(() => {
		// 	console.warn('<<contacts>>', contacts);
		// 	var view = this.clientView = ContactsList.ForOwnerInRealty(contacts);
		// 	this.showChildView('owner', view);
		// });
	},
	showOfferInfo: async function () {
		//var view = new OfferInfo({ model: this.model });
		let { bld, obj, realtyId, ownerId } = this.model.attributes;
		if (!bld) {
			bld = shortRealties.get(realtyId);
		}
		
		const contactsInRealty = this._getContactsInRealtyModel();
		await contactsInRealty.fetch();
		
		
		try {
			let view = new RealtyOfferBoxViewWraper({ offerId: this.model.id, realtyObject: obj, realty: bld, contactsInRealty, realtyId, ownerId });
			this.showChildView('offer-info', view);
		} catch (exc) {
			console.error(exc);
		}
		// console.warn('# oofer', this);
	},

	//deprecated
	showObjectCommon: function () {
		const objectModel = new BbeModel(this.model.get('obj'));
		var view = new ObjectCommonView({ model: objectModel, offer: this.model });
		this.showChildView('object-common', view);
	},
	//deprecated
	_showObjectSpecial: function (name) {
		var hash = (this.model.get('obj') || {})[name];
		if (!hash) return;
		var region = 'object-' + name;
		var View = objectSpecialViews[name];
		if (!View) return;
		var view = new View({ model: new BbeModel(hash), offer: this.model });
		this.showChildView(region, view);
	},
	//deprecated
	showObjectSpecials: function () {
		this._showObjectSpecial('of');
		this._showObjectSpecial('wh');
		this._showObjectSpecial('ret');
		this._showObjectSpecial('apart');
	},

	showObject() {
		let view = new RealtyObjectBoxViewWraper({ offer: this.model });
		this.showChildView('object', view);
	},

	showRealty: function () {
		//var view = new RealtyRegionView({offer: this.model});
		//console.log('---- . ', this.model.attributes);

		let view = new RealtyBoxViewWraper({ offer: this.model, editEnabled: true });
		this.showChildView('realty', view);

	}

});

export const __OfferCardModalView = HamburgerView.extend({
	initialize() {
		this.listOffer = this.model;
		this.model = realtyOffersFullStore.get(this.model.id, true);
	},
	childrenViews: [
		{
			thisClassName: 'offer-column',
			class: UiColumnView,
		},
		{
			thisClassName: 'object-column',
			class: UiColumnView,
		},
		{
			thisClassName: 'realty-column',
			class: UiColumnView,
		},
	]
});

Bus.Views.reply('realties:offer:cardModal', () => OfferCardModalView);

export default OfferCardModalView;