import { ContactsCollection } from "../../contacts";
import { OwnerModel, OwnerRealtyModel, ownersUrl } from "./models";
import { Collection } from 'core';
// import { urls } from 'coms/urls';
// import { backendApi } from 'apis/backend';

export async function prepareOwnerDataAsync(ownerId, ext = {}) {

	const owner = new OwnerModel({ id: ownerId });
	owner.urlRoot = ownersUrl();

	await owner.fetch();

	const realties = new Collection(owner.get('realties') || [], { model: OwnerRealtyModel });

	const contacts = new ContactsCollection();
	contacts.url = owner.url() + '/contacts';

	await contacts.fetch();


	return Object.assign({ owner, realties, contacts }, ext);

}

