// define('svc/actualization/realties-list/realty/edit/properties/multiselect-control', [
//     'behaviors',
//     'svc/actualization/realties-list/realty/edit/properties/controls',
//     'svc/actualization/realties-list/realty/edit/properties/bus'
// ], function(
//     beh, controls, bus
// ) {});

import beh from 'behaviors';
import controls from './controls';
//import bus from '';
import { MnView, NextCollectionView } from 'vendors';
import { MneView } from 'core/mne';

const OptionView = MnView.extend({
    tagName: 'span',
    className: 'multiselect-option',
    behaviors: [beh.DynamicClass],
    dynamicClass() {
        let res = [];
        if (this.isSelected()) {
            res.push('active');
        }
        return res.join(' ');
    },	
    template: _.template('<%= label %>'),
    templateContext() {
        return {
            label: this.model.get('label') || this.model.get('value')
        }
    },
    
    isSelected() {
        return this.model.get('selected') === true;

        // let value = this.model.get('value');
        // if (this.schema.get('multiple')) {
        //     return this.options.isSelected(value);
        //     //return this.value.indexOf(value) > -1;
        // } else {
        //     console.log('### ->', this.schema);
        //     throw new Error('not implemented');
        // }
    },

    ...controls.ControlValueMixin,

    triggers:{
        'click':'click'
    }
});

const SelectView = NextCollectionView.extend({
    className: 'form-control',
    childView: OptionView,

    // isSelected(id) {
    //     return this.selectedIds[id] === true;
    // },

    childViewOptions() {
        return this.buildControlOptions({
            isSelected: (id) => this.isSelected(id)
        });
    },

    ...controls.ControlValueMixin,

    initialize() {
        this.selectedIds = {};
        this.initializeValue();
        this.value.forEach(val => this.selectedIds[val] = true);
    },

    //viewComparator: 'label',

    childViewEvents:{
        click(view, event) {
            let model = view.model;
            model.set('selected', !model.get('selected'));
            this.trigger('selection:change')
            // let val = model.id;
            // this.selectedIds[val] = !this.selectedIds[val];
        }
    }

});



const Layout = MneView.extend({
    className: 'multiselect-control',
    template: _.template(`<div></div>`),
    regions: {
        select: '> div'
    },

    onRender() {
			let viewComparator = controls.getControlViewComparator(this.schema);
			let options = this.buildControlOptions({ collection: this.collection, viewComparator });
			let view = new SelectView(options);            
			this.showChildView('select', view, { replaceElement: true });
    },

    ...controls.ControlValueMixin,
    ...controls.ControlChangeValueMixin,

    initialize() {

        this.initializeValue();

        let removeids = [];

        this.collection.each(model => {
            let sid = model.id;
            if (sid === 'none' || sid === "undefined") {
                removeids.push(model.id);
            }
        });

        removeids.forEach(id => this.collection.remove(id));

        this.value.forEach(val => {
            let model = this.collection.get(val);
            if (model) {
                model.set('selected', true);
            }
        });
        
    },

    getEditValue() {
        let value = this.collection.filter(f => f.get('selected')).map(m => m.get('value')).join(', ');
        return value;
    },

    childViewEvents:{
        'selection:change'() {

            let value = this.getEditValue();
            console.log('- change value -', value);
            this.changeValueAsync(value);
            //bus.setEntityValue(this.entity, this.schema, value);
        }
    }

});

export default Layout;
