import { editInModal } from "coms/ui/EditView";
import { EditContactLayout } from './views/edit-contact';
import modals from '_libs/modals';
import { urls } from 'coms/urls';
import { backendApi } from "apis/backend";
import { propertySchemaApi } from "apis/schema";
import { modalsApi } from "apis/modalsApi2";
import { Model, Collection } from 'core';
import { prepareOwnerDataAsync } from "../realties/owners";
import { AddOrJoinContactsList } from "../entity-contacts/views/addjoin-in-modal";


export function editContactInModal(model, ext = {}) {
	/*
	const isNew = !model;
	const header = isNew ? 'добавление контактного лица' : 'изменение контактного лица';
	const contentArg = {
		header,
		buttons: false,
		content: {
			class: EditContactLayout,
			model,
			isNew,
			...ext
		},
		childViewEvents: {
			'edit:done'() {
				this.destroy();
			}
		}
	}

	return editInModal(contentArg, {
		cssClasses: {
			dialog: "edit-contact-modal-dialogue",
		}
	});
	*/

	const header = 'Изменение данных контактного лица';
	const modalView = {
		header,
		thisClassName: 'edit-contact-details-in-modal',
		//resolveWith: value,
		//rejectHardButton: 'Сбросить значение',
	}
	const content = {
		class: EditContactLayout,
		model,
		...ext,
		onEditDone(data) {
			console.error('NEWCONTACT', data);
			this.triggerMethod('modal:resolve', data);
		},
		onModalResolve() {
			console.error('MODAL:RESOLVE');
		},
	}
	const options = {
		destroyOnSettle: true,
		destroyOnEsc: false, 
		destroyOnOutsideClick: false, 		
	}
	const res = modalsApi.show(content, modalView, options);
	return res;


}



export function removeContact(model, opts = {}) {
	modals.confirm('Подтвердите удаление').then(() => {
		
		const collection = model.collection;

		const sync = () => {
			collection.remove(model);
		}

		const { owner } = opts;

		const oid = owner?.id;

		const cid = model.id;

		const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}`);
		backendApi.delete(url, { sync, relativeUrl: false });		
		

	}, () => {});
}


export function editContactInModalAsync(contactModel) {
	const oirId = propertySchemaApi.getValue('ownerInRealtyId', contactModel);
	if (oirId) {
		return editOwnerInRealtyContactInModalAsync(oirId, contactModel)
	} else {
		return editClientContactInModalAsync(contactModel)
	}
}


const hlp = {
	// async getOiRAsync(oirId) {
	// 	let oirUrl = urls.api(`v4/realties/owner-in-realties/${oirId}`);
	
	// 	const oirRes = await backendApi.getAsync(oirUrl);
	
	// 	if (!oirRes.ok) {
	// 		modalsApi.error(oirRes.error.message || 'не удалось найти соответствующую запись');
	// 		return;
	// 	}
	// 	return oirRes.value;
	// }
	getOiRUrl(id) {
		return urls.api(`v4/realties/owner-in-realties/${id}`);
	}
}

async function editOwnerInRealtyContactInModalAsync(oirId, contactModel) {

	//oirId = 'f' + oirId.substring(1);
	let oirUrl = hlp.getOiRUrl(oirId); //urls.api(`v4/realties/owner-in-realties/${oirId}`);
	
	const oirRes = await backendApi.getAsync(oirUrl);

	if (!oirRes.ok) {
		modalsApi.error(oirRes.error.message || 'не удалось найти соответствующую запись');
		return;
	}

	let oir = oirRes.value;
	let owner = { id: oir.ownerId, name: oir.ownerName };
	let realty = { id: oir.realtyId, name: oir.realtyName };
	let cntUrl = urls.api(`v4/realties/owners/${oir.ownerId}/contacts/${contactModel.id}`);
	let cntRes = await backendApi.getAsync(cntUrl);
	if (!cntRes.ok) {
		modalsApi.error(cntRes.error.message || 'не удалось найти соответствующую запись');
	}
	
	let cntModel = new Model(cntRes.value);
	await editContactInModal(cntModel, { owner, realty });

	let updatedOirContactUrl = oirUrl + '/contacts/'	+ contactModel.id;
	let result = await backendApi.getAsync(updatedOirContactUrl);

	return result.value

}

async function editClientContactInModalAsync(cntModel) {
	const client = { id: cntModel.get('clientId') }
	const url = urls.api(`v4/clients/${client.id}/contacts/${cntModel.id}`);
	const editModel = new Model();
	editModel.url = url + '?view=edit';
	await editModel.fetch();
	await editContactInModal(editModel, { client });

	const updatedContactUrl = url + "?view=view";
	let result = await backendApi.getAsync(updatedContactUrl);

	return result.value	

}

export function addContactInModalAsync(model, options = {}) {
	const { clientId, ownerId, realtyId } = options;
	if (clientId) {
		return addClientContactInModalAsync(model, options);
	} else if (ownerId && realtyId) {
		return addOwnerInRealtyContactInModalAsync(model, options)
	} else {
		console.warn('add new contact failed: missing needed options')
	}
}

async function addClientContactInModalAsync(model, options) {

	const res = await editContactInModal(undefined, options);

	return res.value;

}

async function addOwnerInRealtyContactInModalAsync(model, options) {


	let { ownerId, realtyId, ownerInRealty } = options;
	
	if (!ownerInRealty) {
		let oirUrl = urls.api(`v4/realties/owner-in-realties/o${ownerId}-r${realtyId}`);
		let oirRes = await backendApi.getAsync(oirUrl);
		ownerInRealty = oirRes.value;
	}

	const res = await openAddOrJoinDialogue({ ownerId, realtyId, ownerInRealty });
	if (!res.ok && res.type === 'hard') {
		if (!model._ownerDataForAddingContact) {
			model._ownerDataForAddingContact = await prepareOwnerDataAsync(ownerId, { realtyId });
		}
		const res = await editContactInModal(undefined, model._ownerDataForAddingContact);
		return res.value;
	}
	console.warn('[MODAL RESULT]', res);
	return true;
	

	

	// let oirId;
	// for (let cnt of contacts.models) {
	// 	let inRealties = cnt.get('inRealties') || [];
	// 	for (let cntInRealty of inRealties) {
	// 		let cir_oid = cntInRealty?.ownerInRealty?.ownerId;
	// 		let cir_rid = cntInRealty?.ownerInRealty?.realtyId;
	// 		if (cir_oid === ownerId && cir_rid === realtyId) {
	// 			oirId = cntInRealty.ownerInRealty.id;
	// 			break;
	// 		}
	// 	}
	// 	if (oirId) {
	// 		break;
	// 	}
	// }

	// if (!oirId) {
	// 	modalsApi.error('Что-то пошло не так.<br>Лучше бы перезагрузить страницу.');
	// }

	// console.log('RESLL::', res, model._ownerDataForAddingContact);

	// return res;

}



export async function openAddOrJoinDialogue(opts = {}) 
{
	const { ownerId, realtyId, ownerInRealty } = opts;
	let url = urls.api(`v4/realties/owners/${ownerId}/contacts`);
	let res = await backendApi.getAsync(url);
	let collection = new Collection(res.value);
	collection.url = url;
	let content = {
		class: AddOrJoinContactsList,
		collection,
		ownerId, realtyId,
		ownerInRealty,
	}
	let modalViewOptions = {
		header: 'Список всех доступных контактных лиц собственника',
		thisClassName: 'add-or-join-contacts-to-oir',
		resolveButton: 'Закрыть',
		rejectHardButton: 'Добавить новое контактное лицо',
		modalContentContainerClassName: 'contacts-container'
	}
	let modalOptions = {}

	let modalres = await modalsApi.show(content, modalViewOptions, modalOptions);
	return modalres;
}