import app from '../app/app'; //'app'
import Bus from 'bus'; // 'bus'
import identity from '_libs/identity'; // '_libs/identity'
import ModuleLayout from './layout'; //'appModule-layout'
import BehaviorBlockButtons from 'behaviors/blockbuttons';

import { AppModule } from './appModule';
import { MneView } from 'core/mne';

import './page-buttons.less';

const NoButtonsView = MneView.extend({
	template: () => `<div style="margin: 15px">
		<p>нет доступных разделов</p>
		<ul>
			<li><a href="/acc/logoff">разлогиниться</a></li>
			<li><a href="/">вернуться на главную</a></li>
		</ul>
	</div>`
});

// const BehaviorBlockButtons = {};

var ButtonsLayout = MneView.extend({
	className: 'buttons-layout',
	template: _.noop,
	behaviors: [BehaviorBlockButtons],
	noButtonsView: NoButtonsView,
	// initialize: function () {
	// 	console.log('#i am buttons#', this);
	// }
});

var NotAuthorizedLayout = MneView.extend({
	template: () => '<h3>У вас недостаточно прав для просмотра этой страницы</h3>',
	className: 'module-run-error-message'
	// initialize: function () {
	// 	console.log('#i am buttons#', this);
	// }
});

export const ContentModule = AppModule.extend({
	appRadio: Bus.app,
	isContent: true,
	showButtonsLayout: function () {
		//var result = 

		let label = _.result(this, 'label');
		// console.log('#show-btns#');
		this.createAndShow(ButtonsLayout, label, {}, { navLinks: this.navLinks(), addSectionClass: 'flex-page flex-column' });
	},
	createAndShow: function () {
		var layout = this.createLayout.apply(this, arguments);
		// console.log('#layout#', layout);
		this.setLayout(layout);
		return layout;
	},
	createLayout: function () {
		var args = [].slice.call(arguments);
		args.unshift(this);
		//console.log('#& CREATE ARGS: >> ', args);
		return ModuleLayout.smartCreate.apply(ModuleLayout, args);
	},
	setLayout: function (layout) {
		this.renderedLayout = layout;
		if (layout && this.getOption('destroyLayoutOnStop')) {
			this.once('stop', () => {
				layout.destroy();
			});
		}
		if (this.appRadio) {
			try {
				this.appRadio.trigger('content:update', layout, this, this.options);
			} catch (e) {
				console.error(e);
				throw new Error('zhopa');
			}
		}
	},
	isAnonymousAllowed()
	{
		return !!this.getOption('anonymousAllowed');
	},
	isRunPrevented() {
		if (identity.isLogged()) return false;
		return !this.isAnonymousAllowed();
	},
	onRunPrevented(action = 'show-login') {

		switch(action) {
			case 'show-not-authorized':
				this.createAndShow(NotAuthorizedLayout, _.result(this, 'label'), {}, {});
				break;
			case 'show-login':
			default:
				app.routers.navigate('acc/login', true);

		}
	}
});