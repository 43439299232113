

import { View, CollectionView, Collection } from 'core';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { NextIconButtonView } from 'coms/ui/Button';
import { UiAtomView } from 'coms/ui/UiAtomView';
import { entityContactValueApi } from '../../entityContactValueApi';
import { entityContactsApi } from '../../entityContactsApi';
import { modalsApi } from 'apis/modalsApi2';
import { editContactInModalAsync } from 'mods/contacts';
import './modal-contact-details.less';


const ContactDetail = UiAtomView.extend({
	initialize() {
		this.simple = !!this.model.get('simple');
	},
	thisClassName: 'tdtv-item',
	topText(){
		if (this.simple) {
			return this.model.get('topText');
		}
		let text = entityContactValueApi.getType(this.model);
		let purposes = entityContactValueApi.displayPurposes(this.model);
		if (purposes) {
			text += ` <small>(${purposes})</small>`
		}
		return text;
	},
	text() {
		if (this.simple) {
			return this.model.get('text');
		}
		return entityContactValueApi.getValueHtml(this.model);
	},
	bottomText() {
		if (this.simple) { return; }
		return entityContactValueApi.getNote(this.model);
	}

});

const Details = CollectionView.extend({ 
	className: 'tdtv-list with-borders',
	childView: ContactDetail,
	initialize() {
		const raw = this.model.get('values') || [];
		
		this.collection = new Collection(raw);
		const note = this._createSimple('заметка', this.model.get('note'));
		if (note) {
			this.collection.unshift(note);
		}
		const funcs = this._getFuncsRawModel();
		if (funcs) {
			this.collection.unshift(funcs);
		}

	},
	_getFuncsRawModel() {
		const { ownerFuncs, clientFuncs } = this.model.attributes;
		const funcs = ownerFuncs ? entityContactsApi.displayOwnerFuncs(this.model)
								: clientFuncs ? entityContactsApi.displayClientFuncs(this.model)
								: undefined;
		return this._createSimple("функции", funcs);
	},
	_createSimple(topText, text) {
		if (!text) { return; }
		return {
			simple: true,
			topText,
			text
		}
	}
});
const editBtn = { class: NextIconButtonView, text: 'изменить', icon: 'fa:pencil', action: 'trigger:edit' };
const deleteBtn = { class: NextIconButtonView, text: 'удалить', icon: 'fa:trash', action: 'trigger:delete' };
const Actions = HamburgerView.extend({ 
	thisClassName: 'contact-actions',
	getChildren() {
		const views = [];
		if (this.getOption('editAllowed')) {
			views.push(editBtn);
		}
		if (this.getOption('deleteAllowed')) {
			views.push(deleteBtn);
		}
		return views;
	},
	// childrenViews: [
	// 	{ class: NextIconButtonView, text: 'изменить', icon: 'fa:pencil', action: 'trigger:edit' },
	// 	// { class: NextIconButtonView, text: 'удалить', icon: 'fa:trash', action: 'trigger:delete' },
	// ],
	childViewTriggers: {
		'edit': 'edit',
		'delete': 'delete'
	},
	async onEdit() {
		let newhash = await editContactInModalAsync(this.model);
		if (newhash) {
			this.model.set(newhash);
			this.model.trigger('refresh');
		}
		console.error('newhash applied', this.model);
	},
	async onDelete() {
		const prom = await modalsApi.confirm('подтвердите удаление');
		if (!prom.ok) { return; }
		this._delete();
	},
	_delete() {
		const clientId = this.model.get('clientId');
		let deleteUrlRoot = this.getOption('deleteUrlRoot');
		if (clientId) {
			if (deleteUrlRoot) {
				this.model.urlRoot = deleteUrlRoot
			}
			this.model.destroy({ wait: true });
		} else {
			console.error('delete contact not implemented');
		}
		
	}
});


export const ContactDetailsInModal = HamburgerView.extend({
	initialize() {
		//debugger;
	},
	modelEvents: {
		'destroy':'destroy',
		'refresh'() {
			const modal = this.getOption('modalView');
			if (modal) {
				modal.render();
			}
		}
	},
	getChildren(){
		const views = [Details];
		let editAllowed = this.getOption('isEditAllowed', true);
		let deleteAllowed = this.getOption('isDeleteAllowed', true);
		if (editAllowed || deleteAllowed) {
			views.push({ class: Actions, deleteAllowed, editAllowed, deleteUrlRoot: this.getOption('deleteUrlRoot', true) });
		}
		return views;
	},
	// editActionsAllowed() {
	// 	return entityContactsApi.isEditAllowed(this.model);
	// },

});

