import identity from '_libs/identity';
const anyClaimOperator = Symbol('any');
const allClaimsOperator = Symbol('all');


function isMe(id) {
	return identity.isMe(id);
}

export const claimsApi = {
	_hasModuleClaims(module, claimsArray) {
		let any = !!claimsArray[anyClaimOperator];
		let all = !any && claimsArray[allClaimsOperator];
		let result;
		for(let claims of claimsArray) {
			result = this._hasModuleClaim(module, claims);
			if ((all && !result) || (any && result)) {
				return result;
			}
		}
		return result;
	},
	_hasModuleClaim(module, claims) {
		return identity.rights.checkClaimValue(module, claims);
	},
	hasModuleClaim(module, claims) {
		if (Array.isArray(claims)) {
			return this._hasModuleClaims(module, claims);
		}
		return this._hasModuleClaim(module, claims);
	},
	hasClaims(module, claims) {
		if (arguments.length === 2) {
			return this.hasModuleClaim(module, claims);
		} else if (arguments.length === 1 && module && typeof module === 'object') {
			const keys = Object.keys(module);
			let any = !!module[anyClaimOperator];
			let all = !any && module[allClaimsOperator];
			let result;
			for(let _module of keys) {
				let _claims = module[_module];
				result = this.hasModuleClaim(_module, _claims);
				if ((all && !result) || (any && result)) {
					return result;
				}				
			}
			return result;
		} else {
			console.warn('unhandled hasClaims case, arguments:', arguments);
			return identity.rights.checkClaimValue(module, claims);
		}
	},
	canEditRealtiesInCard() {
			return this.hasClaims('svcActualizationModule', 'editRealtiesFast');
	},
	isMe(ids) {
		if (!ids) { return false; }
		if (!Array.isArray(ids)) {
			ids = [ids];
		}
		return ids.some(isMe)
	}
}

export function anyClaim(obj) {
	obj[anyClaimOperator] = 1;
	return obj;
}
export function allClaims(obj) {
	obj[allClaimsOperator] = 1;
	return obj;
}