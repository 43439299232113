// define('svc/actualization/changes/layout', [
// 	'ui/layouts/recordset-view', 
// 	'svc/actualization/changes/result-collection',
// 	'svc/actualization/changes/filters',
// 	'svc/actualization/changes/item-view',
// 	'app-config'
// ], 
// function(
// 	BaseLayout, ResultCollection, filtersConfig, ItemView, cfg
// ) {});

import { RecordsetView } from 'ui/layouts/recordset-view';
import ResultCollection from './result-collection';
import filtersConfig from './filters';
import ItemView from './item-view';
//import cfg from '';

import './actualization-changes.less';

export default RecordsetView.extend({
	className: 'recordset-view actualization actualization-changes main-content',
	instantApplyFilters: true,
	initialize() {			
		this.searchResult = new ResultCollection();
	},
	listResultOptions: {
		className: 'actualization-changes-result',
		childView: ItemView,
		// childViewOptions() {
		// 	return {
		// 		modelType: "owner",
		// 	}
		// }
	},
	filtersConfig
});
