import { View } from 'core';
import { HamburgerView } from '../HamburgerView';
import { UiSchemaAtomView } from '../../schema';

export const ContactBoxHeader = HamburgerView.extend({
	tagName: 'header',
	className: 'contacts-box-header',
	getChildren() {
		//const entity = this.getOption('entity') || {};
		const views = [
			this.getHeaderTextView(),
			this.getRightButtonView(),
		]
		return views;
	},
	getHeaderTextView() {
		return {
			class: UiSchemaAtomView,
			thisClassName: 'contacts-box-header-text',
			topText: this.getOption('topText'),
			text: this.getOption('text'),
			noBottomText: true
		}
	},
	getRightButtonView() {
		return this.getOption('rightButtonView', true);
	}
});