


import { RecordsetView } from 'ui/layouts/recordset-view';
import ResultCollection from './result-collection';
import filtersConfig from './filters';
import ItemView from '../actualize-subject';
import AcPan from '../actions-panel';
//import cfg from '';


	// const AsideView = MneView.extend({
	// 	template: _.template('<div class="filter"></div><div class="actions"></div>'),
	// 	regions: {
	// 		filter:  '.filter',
	// 		actions: '.actions'
	// 	},
	// 	onRender() {
		
	// 	}
	// });





	export default RecordsetView.extend({
		className: 'recordset-view actualization actualization-owners',
		instantApplyFilters: true,
    initialize() {			
			this.searchResult = new ResultCollection();
			this.registerActionsPanel();
		},
		listResultOptions: {
			childView: ItemView,
			childViewOptions: {
				modelType: "owner",
			}
		},
		filtersConfig,
		
		...AcPan.resultViewMixin({ actions: AcPan.actions }),



	});
