import { UiSchemaAtomView } from "coms/schema";
import { entityContactsApi } from "../entityContactsApi";

export const EntityContactsListItemNameView = UiSchemaAtomView.extend({
	thisClassName: 'contact-item-main',
	topText: v => v.getName(),
	text: v => v.getBestContact(),
	bottomText: v => v.getFuncs(),
	
	getName() {
		let model = this.model;
		let name = this._getName(model);

		if (model.get('primary')) {
			name = '<i class="primary"></i> ' + name;
		}
		return name;
	},

	getBestContact() {
		return entityContactsApi.getMainValueHtml(this.model);
	},


	getFuncs(model) {
		let res = this._getItemFuncs(model);
		if (res) {
			const chunks = res.split(/\s*,\s*/);
			if (chunks.length > 2) {
				let txt = chunks.shift() + ', ' + chunks.shift();
				let count = ' + ещё ' + chunks.length;
				let rest = chunks.join(', ')
				return `<span title="и ещё ${rest}">${ txt + count }</span>`;
			}
		}
		return res;
	},


	_getName(model) {
		const name = entityContactsApi.displayFullName(model);
		const count = entityContactsApi.getContactValuesCount(model);
		return name + (count > 1 ? ` (${count})` : '');
	},

	_getItemFuncs() {
		let type = this.getOption('entityType');
		switch(type) {
			case 'owner':
				return entityContactsApi.displayOwnerFuncs(this.model);
			case 'client':
				return entityContactsApi.displayClientFuncs(this.model);
		}
	},

});