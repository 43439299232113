import { editInModal } from "coms/ui/EditView";
import { backendApi } from "../../apis/backend";
import { flatObject, getValueByPath } from "../../utils";
import { EditContactValueLayout } from './views/edit-contact-value';
import { urls } from 'coms/urls';
import { modalsApi } from "../../apis/modals";
import { addArray, normArr } from "./edit-common";




function invalidateContactValue(flatValues, isNew) {

	const errors = [];
	const add = v => addArray(errors, v);

	const valueType = flatValues['value.type'];
	const valueValue = flatValues['value.value'];

	if (isNew) {
		
		if (!valueType) {
			add('укажите тип контакта');
		}
		if (valueValue == null) {
			add('добавьте значение контакта');
		}
	} else {
		if ('value.type' in flatValues && !valueType) {
			add('укажите тип контакта');
		}
		if ('value.value' in flatValues && (valueValue == null || valueValue === '')) {
			add('добавьте значение контакта');
		}

	}

	return normArr(errors);
}

function _catchException(exc, message) {
	let error = [message]
	const jsonMessage = exc?.responseJSON?.message || exc?.responseJSON?.Message;
	if (jsonMessage) {
		error.push(jsonMessage);
	}
	return {
		ok: false,
		error: error
	}
}

function getBaseUrl(owner, client) {
	if (client) {
		return urls.api(`v4/clients/${client.id}/contacts`);
	} else {
		return urls.api(`v4/realties/owners/${owner.id}/contacts`);
	}
}


async function insertNewContactValue(url, changes) {
	try {
		//const sync = () => {};
		const result = await backendApi.post(changes, url, { relativeUrl: false });	
		return {
			ok: true,
			value: result
		}
	} catch (exc) {
		return _catchException(exc, 'не удалось добавить контакт');

		// let error = ['не удалось добавить контакт']
		// const jsonMessage = exc?.responseJSON?.message;
		// if (jsonMessage) {
		// 	error.push(jsonMessage);
		// }
		// return {
		// 	ok: false,
		// 	error: error
		// }
	}
}

async function patchExistContactValue(url, changes) {
	try {
		//const sync = () => {};
		//let url = contactUrl + `/${vid}`;
		await backendApi.patch(changes, url, { relativeUrl: false });
		return {
			ok: true,
			value: changes
		}
	} catch (exc) {
		return _catchException(exc, 'не удалось сохранить изменения')
		// return {
		// 	ok: false,
		// 	error: ['не удалось сохранить изменения']
		// }
	}
}

export function editContactValueInModal(model, options = {}) {
	const { isNewContact, owner, contact, client } = options;

	const isNew = !model;
	const header = isNew ? 'добавление контактных данных' : 'изменение контактных данных';
	const contentArg = {
		header,
		buttons: false,
		content: {
			class: EditContactValueLayout,
			model,
			isNew,
			isNewContact
		},
		childViewTriggers: {
			'before:edit:done':'before:edit:done'
		},

		async ___beforeEditDone(changes) {
			const flatChanges = flatObject(changes);
			const errors = invalidateContactValue(flatChanges, isNew);
			if (errors) {
				return {
					ok: false,
					error: errors
				}
			}

			let baseUrl = getBaseUrl(owner, client);
			const cid = contact?.id;
			let contactUrl = baseUrl + `/${cid}/values`;
			//const oid = owner.id;
			//const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}/values`);


			if (isNew) {
				if (!cid) {
					return {
						ok: true,
						value: changes
					}
				}
				try {
					//const sync = () => {};
					let url = contactUrl;
					const result = await backendApi.post(changes, url, { relativeUrl: false });	
					return {
						ok: true,
						value: result
					}
				} catch (exc) {
					return _catchException('не удалось добавить контакт');

					// let error = ['не удалось добавить контакт']
					// const jsonMessage = exc?.responseJSON?.message;
					// if (jsonMessage) {
					// 	error.push(jsonMessage);
					// }
					// return {
					// 	ok: false,
					// 	error: error
					// }
				}
			}


			const vid = model.id;
			//const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}/values/${vid}`);
			try {
				//const sync = () => {};
				let url = contactUrl + `/${vid}`;
				await backendApi.patch(changes, url, { relativeUrl: false });
				return {
					ok: true,
					value: changes
				}
			} catch (exc) {
				return _catchException(exc, 'не удалось сохранить изменения')
				// return {
				// 	ok: false,
				// 	error: ['не удалось сохранить изменения']
				// }
			}

		},

		async beforeEditDone(changes) {

			const flatChanges = flatObject(changes);
			const errors = invalidateContactValue(flatChanges, isNew);

			if (errors) {
				return {
					ok: false,
					error: errors
				}
			}

			let baseUrl = getBaseUrl(owner, client);
			
			
			let method;
			let url;
			if (isNew) {
				// if contact is also new just exit
				if (!contact?.id) {
					return {
						ok: true,
						value: changes
					}
				}
				url = baseUrl + `/${contact.id}/values`;
				method = insertNewContactValue;

			} else {
				url = baseUrl + `/${contact.id}/values/${model.id}`;
				method = patchExistContactValue;
			}

			let result = await method(url, changes);

			return result;

		}


	}

	const result = editInModal(contentArg, {
		cssClasses: {
			dialog: "edit-contact-value-modal-dialogue flex-modal-dialog",
			box: 'block shadowed in-middle',
		}
	});
	console.warn('editContactValueInModal', result);
	return result;

}



export async function removeContactValue(model, options = {}) {
	const { collection, owner, contact, client } = options;
	let url;
	const cid = contact.id;
	const vid = model.id;

	if (client) {
		let clientId = client.id;
		url = urls.api(`v4/clients/${clientId}/contacts/${cid}/values/${vid}`);
	} else {
		const oid = owner.id;
		url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}/values/${vid}`);
	}
	try {

		const sync = () => {
			collection.remove(model);
		};
		await backendApi.delete(url, { sync, relativeUrl: false });

	} catch (exc) {
		modalsApi.oldError('не удалось удалить');
	}
}