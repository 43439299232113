﻿// define('svc-deals-search-layout', ['bus', 'references', 'bus-svc', 'm-deals', 'ui-models-filters', 'realties-search-actionsPanel', 'ui-controls-fastfilters', 'svc-deals-list-dealInBlock', 'ui-controls-leadSourceEdit']
// , function (Bus, refs, svcRadio, dealsNs, Filters,  ActionsPanel, FastFilters, DealInBlockView, LeadSourceEdit) {});

import refs from 'references';
import svcRadio from 'bus/svc';
import dealsNs from 'm/deals';
import Filters from 'ui/models/filters';
// import ActionsPanel from 'svc/realties/search/actionsPanel';
import ActionsPanel from 'ui/layouts/actions-panel';
import FastFilters from 'ui/controls/fastfilters';
import DealInBlockView from 'svc/deals/list/dealInBlock';
import LeadSourceEdit from 'ui/controls/leadSourceEdit';

import template from './layout.html';

import { MneView, LoadmoreOnScrollCollectionView } from 'core/mne';
import { buildUrlHashByKeys } from 'utils';
import { _ } from 'vendors';
import { View as BaseView, CollectionView as BaseCollectionView } from 'core/views';
import { BaseRecordsetView } from 'ui/layouts/recordset-view';

function getfilters(values) {
	var leadSources = _.reduce(LeadSourceEdit.sources, function (memo, v) {
		memo[v] = v;
		return memo;
	}, {});


	var filters = [
		{
			id: 'person',
			group: 'base',
			order: -2,
			caption: 'клиент',
			emptyText: 'не указано',
			modelType: 'single',
			valueType: 'string',
			multiple: false,
			pinned: true,
		},
		{
			id: 'wid',
			group: 'base',
			order: -1,
			caption: 'id процесса',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'number',
			multiple: false,
			pinned: true,
		},
		{
			id: 'text',
			group: 'base',
			order: -1,
			caption: 'ключевое слово',
			emptyText: 'не указано',
			modelType: 'single',
			valueType: 'string',
			multiple: false,
			pinned: true,
		},
		{
			id: 'oid',
			group: 'base',
			order: 0,
			caption: 'ответственный',				
			emptyText: 'не выбран',
			controlType: 'selectEmployees',
			sourceValues: svcRadio.request('employeesSelect'),
			multiple: false,
			pinned: true,
		},
		{
			id: 'cid',
			group: 'base',
			order: 0,
			caption: 'создатель',
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			controlType: 'selectEmployees',
			sourceValues: svcRadio.request('employeesSelect'),
			multiple: false,
			pinned: true,
		},
		{
			id: 'sq',
			group: 'base',
			order: 2,
			caption: 'площадь',
			emptyText: 'не задана',
			valueType: 'number',
			modelType: 'range',
			pinned: true
		},
		{
			id: 'tid',
			group: 'base',
			order: 0,
			caption: 'участник',
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			controlType: 'selectEmployees',
			sourceValues: svcRadio.request('employeesSelect'),
			multiple: false,
			pinned: true,
		},
		{
			id: 'status',
			group: 'base',
			order: 0,
			caption: 'состояние',
			values: ['inProcess'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('dealStatuses'),
			flattenValues: true,
			multiple: false,
			pinned: true
		},
		{
			id: 'stage',
			group: 'base',
			order: 0,
			caption: 'стадия',
			//values: [''],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('dealStages'),
			noComparator: true,
			flattenValues: true,
			multiple: false,
			pinned: true
		},
		{
			id: 'created',
			group: 'base',
			order: 0,
			caption: 'дата создания',
			//values: [''],
			emptyText: 'не установлено',
			modelType: 'range',
			valueType: 'datetime',
			multiple: false,
			extOpts: {
				noTime: true,
				showExact: true,
				noRestrictions: true,
			},
			noTime: true,
			pinned: true
		},
		{
			id: 'src',
			order: 5,
			caption: 'площадка',
			emptyText: 'не задано',

			modelType: 'single',
			valueType: 'string',
			sourceValues: leadSources, 
			multiple: false,
			
			pinned: true
		},
		{
			id: 'mt',
			group: 'base',
			order: 0,
			caption: 'рынок',
			//values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('realtyMarkets'),
			flattenValues: true,
			multiple: true,
			pinned: true
		},		
		{
			id: 'mo',
			order: 5,
			caption: 'операция',
			//values: ['rent'],
			emptyText: 'не выбрана',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('realtyOperations'),
			multiple: false,
			pinned: true
		},
		{
			id: 'notags',
			group: 'base',
			//order: -2,
			caption: 'не содержит тэги #',
			//values: ['неработаем'],
			emptyText: 'не заданы',
			modelType: 'single',
			valueType: 'string',
			controlType: 'tagsInput',
			flattenValues: true,
			multiple: true,
			pinned: true,
		},
		{
			id: 'hastags',
			group: 'base',
			caption: 'содержит тэги #',
			emptyText: 'не заданы',
			modelType: 'single',
			valueType: 'string',
			controlType: 'tagsInput',
			flattenValues: true,
			multiple: true,
			pinned: true,
		},		
	];

	values || (values = {});
	let webid;
	_(values).each(function (value, id) {
		var filter = _(filters).findWhere({ id: id });
		if (!filter) return;
		filter.values = value;
		if (id == 'wid') {
			webid = value;
		}
	});


	if (webid) {
		if (webid != null) {
			filters.forEach(filter => {
				if (filter.id !== 'wid') {
					filter.values = undefined;
				}
			})
		}
	}
	console.warn('FILTERS', filters);
	return filters;

}


// var ___CollectionView = MneNextCollectionView.extend({
// 	className: 'deals-items-list deals-search scrollY-region',
// 	childView: DealInBlockView,
// 	behaviors: [MneBehaviors.OnScrollLoadmorable, MneBehaviors.ScrollableY],
// });

var CollectionView = LoadmoreOnScrollCollectionView.extend({
	className: 'deals-items-list deals-search scrollY-region',
	childView: DealInBlockView,
	collectionEvents: {
		'load:first'() {
			this.collection.___state = 'new:search';
			console.log("[LOAD:FIRST]", this.collection.___state);
			this.collection.reset();
		},
		'request'() {
			if (this.collection.___state !== 'new:search') {
				this.collection.___state = 'load:more';
			}
			console.log("[REQUEST]", this.collection.___state);
		},
		'sync'() {
			console.warn('[sync]',this.collection.___state)
			if (this.collection.___state == 'new:search' && this.collection.length === 0) {
				this.collection.___state = 'success:fetch';
				const reg = this.getRegion('result');
				reg.currentView._showEmptyView();
			}
		},
		'error'(){
			if (this.collection.___state = 'new:search' && this.collection.length === 0) {
				this.collection.___state = 'fetch:error';
				const reg = this.getRegion('result');
				reg.currentView._showEmptyView();
			}
		}
	},
	CollectionView: BaseCollectionView,
	emptyView() {
		switch(this.collection.___state) {
			case 'new:search':
			case 'success:fetch':
			case 'fetch:error':
				return BaseView;
		}
	},
	emptyViewOptions() {
		switch(this.collection.___state) {
			case 'new:search':
				return { template: 'подождите, идёт поиск...' };
			case 'success:fetch':
				return { template: 'поиск не дал результатов' };
			case 'fetch:error':
				return { template: 'поиск завершился с ошибкой :(' };
		}
	},
	// collectionViewExtend: {
	// 	_showEmptyView() {
	// 		console.error("BLYAM")
	// 		debugger;
	// 		let res = BaseCollectionView.prototype._showEmptyView.apply(this, arguments);
	// 		return res;
	// 	}
	// },
	//childViewOptions: function () {
	//	return {
	//		selectionHolder: this.getOption('selectionHolder')
	//	}
	//},
	//collectionViewExtend: {
	//	behaviors: [SelectedForActions]
	//}
});


var View = BaseRecordsetView.extend({
	//template: '#tmpl-service-deals-search-layout',
	
	// template,
	// regions: {
	// 	'actionsPanel': { el: '.actions-panel-left', replaceElement: true },
	// 	'result': '.result',
	// },
	onRender: function () {
		//this.resultRadio = this.getOption('radio');
		this.initializeFilters();
		this.showSearchResult();
		this.showActionPanel();
	},
	initialFiltersValues: function () {
		
		const urlHash = buildUrlHashByKeys(this.getOption('urlQuery'), 'wid','text');
		return urlHash;

		// var res = {};
		// const fromurls = [
		// 	{ key: 'text', value: this.getOption('searchText') },
		// 	{ key: 'id', value: this.getOption('searchId') },
		// ]

		// fromurls.forEach(kv => {
		// 	if (kv.value) {
		// 		res[kv.key] = decodeURIComponent(kv.value);
		// 	}
		// });

		// if (!this.getOption('searchText'))
		// 	return res;

		// res.text = decodeURIComponent(this.getOption('searchText'));
		//return res;
	},
	initializeFilters: function () {
		if (this.filterObject) return;
		this.filterObject = Filters.create({
			filters: getfilters(this.initialFiltersValues()),
		});
	},
	showSearchResult: function () {
		var resultView = new CollectionView({
			collection: this.collection,
			filterObject: this.filterObject,
		});

		//this.collection.on('all', e => console.log("-0- col:", e))

		this.showChildView('result', resultView);
		//if (this.getOption('searchText'))
		this.filterObject.apply();
	},
	showActionPanel: function () {

		var actionspanel = new ActionsPanel({
			className: 'actions-panel actions-panel-left',
			statsView: undefined,
			filterObject: this.filterObject,
			fastFiltersView: FastFilters.create({
				filters: this.filterObject,
			}),
		});
		this.showChildView('actionsPanel', actionspanel);
	},
});

View.create = function (module, opts) {
	console.log('OPTS', opts);
	opts || (opts = {});
	//var radio = Bus.channel('dealssearch');
	var col = new dealsNs.DealsSearchCollection();

	return new View(_.extend(opts, { collection: col, module: module }));
}

export default View;

