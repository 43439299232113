import { propertySchemaApi } from 'apis/schema';

export const editContactSchema = {
	_contactType: {
		label: 'тип контакта',
		virtual: true,
		valueType: 'boolean',
		sourceValues: { true: 'персональные контакты', false: 'общие контакты' },
		getValue(m, s, getter) {
			const original = getter();
			if (original != null) {
				console.log('[getValue]', this.property, original, '- original');
				return original;
			}
			const nonhuman = propertySchemaApi.getValue('contactName', m, s);
			const res = nonhuman == null || !nonhuman.length;
			console.log('[getValue]', this.property, res, '- calcula');
			return res;
		},
		displayValue(v, m, s) {
			console.log('[display]', this.property, v, ' - modelhash: ', m[this.property]);
			return this.sourceValues[v];
		}
	},
	contactName: {
		valueType: 'string',
		label: 'Название'
	},
	'person.name.first' : {
		valueType: 'string',
		label: 'Имя'
	},
	'person.name.last' : {
		valueType: 'string',
		label: 'Фамилия'
	},
	'person.name.middle' : {
		valueType: 'string',
		label: 'Отчество'
	},
	ownerFuncs: {
		label: 'Функции',
		valueType: 'enum',
		valueSubType: 'realtyOwnerContactFunctions',
		multiple: true
	},
	clientFuncs: {
		label: 'Функции',
		valueType: 'enum',
		valueSubType: 'clientContactFunctions',
		multiple: true
	},
	primary: {
		label: 'Основной контакт',
		valueType: 'boolean',
	},
	order: {
		label: 'Порядок сортировки',
		valueType: 'number',
	},	
	note: {
		label: 'описание',
		valueType: 'bigtext'
	}
}