
import { entityContactsApi } from "mods/entity-contacts";
import { ContactsListItem, ContactView, NoContactsView } from "./ContactsListItem";
import { Collection, CollectionView } from 'core';
import { urls } from 'coms/urls';

export const ContactsList = CollectionView.extend({
	className: 'contacts-list',
	emptyView: NoContactsView,
	childView: ContactsListItem, //ContactView,
	initialize() {
		let models = this.model.get('contacts') || [];
		const oirId = this.model.id
		this.collection = new Collection(models);
		this.collection.url = urls.api(`v4/actualization/ownerInRealty/${oirId}/contacts`);
	},
	viewComparator: entityContactsApi.contactComparator
});
