import { propertySchemaApi } from '../../apis/schema';
import { entityContactValueApi } from './entityContactValueApi';
import { entityContactSchema } from './models';
import { modalsApi } from 'apis/modalsApi2';
import { claimsApi } from 'apis/claims';
import { ContactDetailsInModal } from './views/details-in-modal';
import { urls } from 'coms/urls';
import { backendApi } from '../../apis/backend';

// const typeSortValues = {
// 	mobilePhone: 30,
// 	phone: 20,
// 	email: 10,
// }

// function contactValueComparator(v1, v2) {
// 	let t1 = typeSortValues[v1.type] || 0;
// 	let t2 = typeSortValues[v2.type] || 0;
// 	if (t1 != t2) {
// 		return t2 - t1;
// 	}
// 	let o1 = v1.order || 0;
// 	let o2 = v2.order || 0;
// 	return o2 - o1;
// }


const isSorted = Symbol('values-is-sorted');
export const entityContactsApi = {
	displayFullName(model) {
		const name = propertySchemaApi.displayValue('fullName', model, entityContactSchema);
		console.log("[displayFullName]", name, model);
		return name;
	},
	getMainValue(model) {
		const values = propertySchemaApi.getValue('values', model, entityContactSchema) || [];

		if (!values[isSorted]) {
			values.sort(this.contactValueComparator);
			values[isSorted] = true;
		}

		let value = values[0];
		
		// if (typeof value === 'object' && 'value' in value === true) {
		// 	value = value.value;
		// }
		return value;
	},
	getContactValuesCount(model) {
		const values = propertySchemaApi.getValue('values', model, entityContactSchema) || [];
		return values.length;
	},
	getMainValueHtml(model, opts) {
		const value = this.getMainValue(model);
		return entityContactValueApi.getValueHtml(value, opts);
	},
	displayOwnerFuncs(model) {
		return propertySchemaApi.displayValue('ownerFuncs', model, entityContactSchema);
	},
	displayClientFuncs(model) {
		return propertySchemaApi.displayValue('clientFuncs', model, entityContactSchema);
	},
	detailsInModal(model, ext = {}) {
		let info = this.getModelInfo(model);
		console.log('?', info, model);
		let deleteUrlRoot = info.isClient ? urls.api(`v4/clients/${model.get('clientId')}/contacts`) : undefined;
		const header = () => this.displayFullName(model);
		const modal = {
			header,
			thisClassName: 'contact-details-in-modal',
			//resolveWith: value,
			//rejectHardButton: 'Сбросить значение',
		}
		const content = {
			class: ContactDetailsInModal,
			model,
			deleteUrlRoot,
			...info,
			...ext
		}
		const res = modalsApi.show(content, modal);
		return res;
	},
	getModelInfo(model) {
		const isOwner = propertySchemaApi.getValue('ownerFuncs', model, entityContactSchema);
		const isClient = propertySchemaApi.getValue('clientFuncs', model, entityContactSchema);
		return { isOwner, isClient }
	},
	isEditAllowed(model) {
		const { isOwner, isClient } = this.getModelInfo(model)
		return isOwner ? claimsApi.canEditRealtiesInCard() 
		: isClient ? false //todo: implement check
		: false;
	},
	isDealClientEditAllowed(deal) {
		let creator = deal.get('creatorId');
		let ids = (deal.get('team') || []).map(t => t.id);
		ids.push(creator);
		return claimsApi.isMe(ids);
	},
	isClientEditAllowed(client) {
		const empsids = [
			client.get('creatorId'),
			...(client.get('relatedEmployees') || [])
		];
		return claimsApi.isMe(empsids);
	},
	_isOwnerContactsEditable() {
		return claimsApi.canEditRealtiesInCard() || claimsApi.hasClaims('svcActualizationModule', ['actualizationAdmin', 'contactsManagement']);
	},
	isAddOwnerContactAllowed() {
		return this._isOwnerContactsEditable();
	},
	isEditOwnerContactAllowed() {
		return this._isOwnerContactsEditable();
	},

	_getJoinOwnerInRealtyContactUrl(oirId, contactId, action) {
		let url = urls.api(`v4/realties/owner-in-realties/${oirId}/contacts/${action}/${contactId}`);
		return url;
	},
	async joinOwnerInRealtyContact(oirId, contactId)	{
		let url = this._getJoinOwnerInRealtyContactUrl(oirId, contactId, 'join');
		let res = await backendApi.postAsync(url);
		if (res.ok) {
			return res.value;
		}
	},
	async disjoinOwnerInRealtyContact(oirId, contactId)	{
		let url = this._getJoinOwnerInRealtyContactUrl(oirId, contactId, 'disjoin');
		let res = await backendApi.postAsync(url);
		if (res.ok) {
			return res.value;
		}
	},


	contactComparator,
	contactValueComparator,
}


function contactComparator(v1, v2) {
	let m1 = v1.model ? v1.model.attributes 
		: v1.attributes ? v1.attributes
		: v1;
	let m2 = v2.model ? v2.model.attributes 
		: v2.attributes ? v2.attributes
		: v2;

	if (m1.primary !== m2.primary) {
		return m1.primary > m2.primary ? -1 : 1;
	}
	let order1 = m1.order || 0;
	let order2 = m2.order || 0;
	if (order1 !== order2) {
		return order2-order1;
	}
	let vl1 = getContactScore(m1.values);
	let vl2 = getContactScore(m2.values);
	return vl2 - vl1;
}

function contactValueComparator(v1, v2) {
	let t1 = getContactItemScore(v1);
	let t2 = getContactItemScore(v2);
	if (t1 != t2) {
		return t2 - t1;
	}
	let o1 = v1.order || 0;
	let o2 = v2.order || 0;
	return o2 - o1;
}

function getContactScore(vals) {
	return vals.reduce((score, item) => getContactItemScore(item) + score, 0);
}
function _getProperty(item, key) {
	return item.value && typeof item.value === 'object' ? item.value[key] : item[key];
}
function getContactItemScore(item) {
	let type = _getProperty(item, 'type');
	let score = contactTypeScores[type] || 0;
	if (score >= 300) {
		let purpose = item.purposes;
		if (purpose === 'primary') {
			score += 200;
		}
	}
	return score;
}
const contactTypeScores = {
	other: 0,
	phone: 300,
	mobilePhone: 400,
	email: 20,
	messenger: 0,
	site: 1,
	social: 0,
}