import { modelSchemaApi, propertySchemaApi } from 'apis/schema';
import { HamburgerView } from '../HamburgerView';
import { ValueWithLabelView } from '../ValueWithLabel';
import { CoreBbView } from 'core/coreBbView';
import { getPropertySchema } from './getPropertySchema';
import { unFlatObject } from 'utils';
import refs from 'references';
import { modalsApi } from 'apis/modals';

import './entity-property.less';
import { modelValueMixin } from './common';

export const PropertyView = HamburgerView.extend({
    constructor: function() {
        HamburgerView.apply(this, arguments);
				this.editModel = this.getOption('editModel', true);
        this._initializePropertyView();
    },
		_intializeChangeListeners() {
			const schema = this.getPropertySchema();
			const listenModel = this.editModel || this.model;

			// console.log('#LISTENING EDITMODEL#', this.model, schema.property);

			if (!schema || !schema.refreshOnChange || !listenModel) { return; }

			let changeProps = schema.refreshOnChange;
			if (!Array.isArray(changeProps)) {
				changeProps = [changeProps];
			}

			const changePropsHash = changeProps.reduce((memo, prop) => {
				memo[prop] = 1;
				return memo;
			}, {});

			// console.log('#START LISTEN#');
			this.listenTo(listenModel, 'edit', (prop) => {
				// console.log('#EDIT#', prop, changePropsHash);
				if (prop in changePropsHash) {
					this.render();
				}
			});
			
		},
    _initializePropertyView() {

			this._intializeChangeListeners();

			// if (!this.model) {
        //     return;
        // }
        // const propertySchema = this.getPropertySchema();
        // if (propertySchema && propertySchema.property) {
        //     this.listenTo(this.model, 'chamge:' + propertySchema.property, () => this.render());
        // }
        // console.error(propertySchema);
    },

    ...modelValueMixin,

    baseClassName: 'ui2-molecule entity-property elastic',
    classNames: v => [
			v.isEditEnabled() ? 'editable' : '',
			v.hasChanges() ? 'has-changes' : '',
    ],
		hasChanges() {
			if (!this.getOption('trackChanges', true)) { return console.log('[hasChanges]:', 'no trackChanges'); }
			const initial = this.getOption('initialKeyValues', true) || {};
			const schema = this.getPropertySchema();
			if (schema.virtual) { return console.log('[hasChanges]:', 'schema.virtual'); }
			const modelSchema = this.getModelSchema();
			const modelValue = this.getModelValue();
			const cur = propertySchemaApi.getValue(schema, modelValue, modelSchema);
			const curIsEmpty = cur == null || cur === '';
			const ori = initial[schema.property];
			const oriIsEmpty = ori == null || ori === '';
			if (curIsEmpty && oriIsEmpty) {
				return false;
			}
			const has = cur !== ori;
			console.log('[hasChanges]', this.cid, cur, ori, initial, this);
			//console.log('has-changes', schema.property, has);
			return has;
		},
    isEditEnabled() {
			
			if (this.getOption('editEnabled', true) !== true) { return false }
			let propertySchema = this.getPropertySchema();
			if (!propertySchema) {
					return false;
			}

			let { valueType, editable, controlType, editAllowed } = propertySchema;

			if (typeof editAllowed === 'function') {
				const modelValue = this.getModelValue();
				const modelSchema = this.getModelSchema();
				const res = editAllowed(modelValue, modelSchema, { isNew: this.model?.id == null });
				if (res != null) {
					return res;
				}
			}

			if (valueType || controlType) {
				editable = editable !== false;
			}


			return editable === true;
    },
    getModelSchema() {
        return this.getOption('modelSchema', true);
    },
    getPropertySchema(key) {
			let modelSchema = this.getModelSchema();
			// let model = this.getSchemaModel();
			key || (key = this.getOption('propertySchema', true));
			return propertySchemaApi.getSchema(key, modelSchema);
				//getPropertySchema(key, modelSchema);
    },
    getEditPropertySchema() {
        let propertySchema = this.getPropertySchema();
        if (!propertySchema) { return; }
        if (propertySchema.editProperty) {
            propertySchema = this.getPropertySchema(propertySchema.editProperty);
        }
        return propertySchema;
    },
    getChildren() {
			let modelSchema = this.getOption('modelSchema');
			let propertySchema = this.getOption('propertySchema');
			let edit = this.getEditButton();
			const children = [
				{
					class: ValueWithLabelView,
					thisClassName: 'elastic',
					model: this.model,
					modelValue: this.getOption('modelValue', true),
					modelHash: this.getOption('modelHash', true),
					flatModel: this.getOption('flatModel', true),
					schema: propertySchema,
					modelSchema: modelSchema,
				},
				edit
			];
			return children;
    },
    getEditButton() {
        if (!this.isEditEnabled()) { return; }
        return {        
            class: CoreBbView,
            className: 'fast-edit',
            tagName: 'button',
            template: '<i class="fa fa-pencil-square-o"></i>'
        }
    },
    events: {
        'click .fast-edit'(event) {

            event.preventDefault();
            event.stopPropagation();

            if (!this.isEditEnabled()) {
                return;
            }
            this._startEdit();
        },
        'click'(event) {
            if (!this.isEditEnabled()) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            this._startEdit();
        }
    },
    _startEdit() {
			let propertySchema = this.getEditPropertySchema();

			if (!propertySchema) {
				console.warn('property schema was not defined');
				return;
			}

			let modelSchema = this.getModelSchema();
			
			const modelValue = this.getModelValue();
			const schemaOptions = propertySchemaApi.getOptions(['valueType', 'valueSubType', 'enumType', 'editDescription'], propertySchema, modelValue, modelSchema);
			let { valueType, valueSubType, enumType, editDescription } = schemaOptions;
			let { controlType, sourceValues, getControlValue, applyNewValue } = propertySchema;
			const flatModel = this.getOption('flatModel', true);
			let value;
				
			let header = propertySchemaApi.getEditHeader(propertySchema, modelValue, modelSchema);
			
			value = propertySchemaApi.getValue(propertySchema, modelValue, modelSchema, { flatModel });

			if (getControlValue) {
				value = getControlValue(value, modelValue, modelSchema, { flatModel });
			}

			if (valueType === 'enum') {
				const enumName = valueSubType || enumType;
				if (enumName && !sourceValues) {
					sourceValues = refs.enum(enumName);
					if (!sourceValues) {
						console.warn('missing enum', enumName);
					}
				}

				if (value && propertySchema.multiple) {
						value = value.split(/\s*,\s*/gmi);
				}
			}
			if (valueType === 'boolean' && !sourceValues) {
				sourceValues = { true: 'Да', false: 'Нет' }
			}

			var popupSetup = {
					// valueType: propertySchema.valueType,
					// controlType: propertySchema.controlType,
					valueType, controlType, sourceValues,
					editDescription,
					header,
					value,
					multiple: propertySchema.multiple,
					// sourceValues: refs.enum('dealStages'),
					// noComparator: true,
			};

        // if (propertySchema.valueType === 'enum' && !popupSetup.sourceValues) {
        //     const enumType = propertySchema.enumType || propertySchema.valueSubType;
        //     popupSetup.sourceValues = refs.enum(enumType);
        // }

			let editAction = this.getOption('editAction');
			let setValueBySchemaApi = this.getOption('setValueBySchemaApi', true);
			const listenModel = this.editModel || this.model;

			const triggerChangeAction = () => {
				if (!listenModel) { return console.log('[triggerChangeAction]', 'no listenModel'); }
				listenModel.trigger('edit', propertySchema.property);
			}
			const rejectHardBehavior = this.getOption('rejectHardBehavior');

			let callback = (type, newvalue, initialValue, ...rest) => {
				//debugger;
				if (type === 'reset' && rejectHardBehavior === 'reset') {
					newvalue = undefined;
				}
				this._updateElClassName();

				console.error('callback', type, newvalue, initialValue, ...rest);
				if (type != 'ok') return;
				if (initialValue && typeof initialValue === 'object' && newvalue == null) {
					return;
				}

				if (propertySchema.valueType === 'enum') {
					if (newvalue instanceof Array) {
							newvalue = newvalue.join(', ');
					}
					if (newvalue === '') {
							newvalue = null;
					}
				}

				if (newvalue instanceof Array && !popupSetup.multiple) {
					newvalue = newvalue[0];
				}



				if (newvalue === undefined) {
						newvalue = null;
				}

				console.error('property:change', newvalue, propertySchema, modelValue, modelSchema);
				this.triggerMethod('property:change', newvalue, propertySchema, modelValue, modelSchema);

				if (setValueBySchemaApi) {
					console.warn('SETTING VALUE BY SCHEMA API');
					propertySchemaApi.setValue(newvalue, propertySchema, modelValue, modelSchema, { flatModel });
					this.render();
					triggerChangeAction();
					
					return;
				}

				let hash = {};
				if (!applyNewValue) {
					hash[propertySchema.property] = newvalue;
					hash = unFlatObject(hash);
					console.warn('NO APPLY VALUE', hash);
				} else {
					hash = applyNewValue(newvalue);
					console.warn('APPLY VALUE', hash);
				}


				if (editAction) {
					console.warn('EDIT ACTION TAKED');
					editAction(hash, this.model);
				} else if(editActionModern) {
					console.warn('MODERN EDIT ACTION TAKED');
					editActionModern(newvalue);
				}
				else {
					console.warn('editAction was not provided');
				}

				triggerChangeAction();

			};

			let resetCallback;

			if (rejectHardBehavior === 'reset') {
				resetCallback = (type, newvalue, initialValue, ...rest) => {

					if (setValueBySchemaApi && type === 'reset') {
						console.log('[resetting value]', this.cid);
						propertySchemaApi.setValue(undefined, propertySchema, modelValue, modelSchema, { flatModel });
						console.log('[resetting value]', this.cid, 'before:render');
						this.render();
						console.log('[resetting value]', this.cid, 'after:render');
						this._updateElClassName();
						triggerChangeAction();						
					}
				};
			}

			modalsApi.oldEditProperty(popupSetup, callback, resetCallback);
    },
});