import { propertySchemaApi } from "apis/schema";

export const entityContactSchema = {
	fullName: {
		displayValue(_val, model, schema) {
			const contactName = propertySchemaApi.getValue('contactName', model, schema);
			const fullName = propertySchemaApi.getValue('person.name.full', model, schema);
			if (fullName) {	
				return fullName;
			} else {
				return contactName || '';
			}			
		},
	},
	ownerFuncs: {
		valueType: 'enum',
		valueSubType: 'realtyOwnerContactFunctions'
	},
	clientFuncs: {
		valueType: 'enum',
		valueSubType: 'clientContactFunctions'
	},
	
}