import { ContactsBox } from "coms/ui/ContactsBox";
import { entityContactsApi } from "../entityContactsApi";
import { NextIconButtonView } from "coms/ui/Button";
import { addContactInModalAsync } from 'mods/contacts';
import { EntityContactsListItem } from "./EntityContactsListItem";



export const EntityContactsBox = ContactsBox.extend({
	ENTITY:true,
	
	childViewOptions() {
		//debugger;
		return {
			entity: this.getOption('entity'),
			contacts: this.getOption('contacts')
		}
	},
	headerOptions() {
		const rightButtonView = this.getHeaderRightButtonView();
		return {
			topText: this.getEntityTypeName(),
			text: this.getEntityName(),
			rightButtonView
		}
	},
	listOptions() {
		return {
			childView: EntityContactsListItem,
			childViewOptions: {
				entityType: this.getOption('entityType'),
				isEditAllowed: this.isEditContactAllowed(),
				isDeleteAllowed: this.isDeleteContactAllowed(),				
			},
			viewComparator: entityContactsApi.contactComparator
		}
	},
	getEntityTypeName() {
		const ent = this.getOption('entity');
		return ent?.typeText ?? '';
	},
	getEntityName() {
		const ent = this.getOption('entity');
		return ent?.name ?? '';
	},

	getItemName(model) {
		const name = entityContactsApi.displayFullName(model);
		const count = entityContactsApi.getContactValuesCount(model);
		return name + (count > 1 ? ` (${count})` : '');
	},
	getItemNameHtml(model) {
		let name = this.getItemName(model);
		if (model.get('primary')) {
			name = '<i class="primary"></i> ' + name
		}
		return name;
	},
	getItemMainValue(model) {
		return entityContactsApi.getMainValueHtml(model);
	},
	getNormalizedItemFuncs(model) {
		let res = this.getItemFuncs(model);
		if (res) {
			const chunks = res.split(/\s*,\s*/);
			if (chunks.length > 2) {
				let txt = chunks.shift() + ', ' + chunks.shift();
				let count = ' + ещё ' + chunks.length;
				let rest = chunks.join(', ')
				return `<span title="и ещё ${rest}">${ txt + count }</span>`;
			}
		}
		return res;
	},

	getItemFuncs(model) { },
	onShowDetails(event, view) {

		// entityContactsApi.detailsInModal(view.model, {
		// 	isEditAllowed: this.isEditContactAllowed(),
		// 	isDeleteAllowed: this.isDeleteContactAllowed() 
		// });
		//console.log('SHPOK', view.model)
	},
	isAddContactAllowed() {

	},
	isEditContactAllowed() { },
	isDeleteContactAllowed() { },
	getHeaderRightButtonView() {
		if (this.isAddContactAllowed()) {
			return {
				thisClassName: 'dark-margined fixed',
				class: NextIconButtonView,
				icon: 'fa:plus',
				action: () => this.triggerMethod('add:new:click')
			}
		}
	},
	async onAddNewClick() {
		const options = this.getAddNewOptions();
		const res = await addContactInModalAsync(this.model, options);
		let refreshData = this.getOption('refreshData', false);
		if (res && refreshData) {
			await refreshData.call(this);
		}
	},
	getAddNewOptions() {
		const options = this.getOptions(['ownerId', 'realtyId', 'clientId', 'client']);
		if (options.client) {
			//options.contacts = this.collection
		}
		return options;
	},
	onCollectionInitialized(col) {
		
		this.collection = col;
	},
	refreshData() {

	},
});