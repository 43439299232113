import { NextCollectionView } from 'vendors';
import { MneView } from 'core/mne';

const RealtyInList = MneView.extend({
	onDestroy() {
		//debugger;
	},
	className: 'map-realty-search-popup-item',
	template: _.template('<big><%= label %></big><small><%= description %></small>'),
	events: {
		click: function () {
			var data = {
				id: this.model.get('value'),
				coords: this.model.get('coords')
			};
			console.log('--- [[ B L Y A M ]] ---');
			this.triggerMethod('searched:bld:click', data);
		}
	}
});

export const PopupView = NextCollectionView.extend({
	className: 'map-realty-search-popup actual',
	childView: RealtyInList,
	childViewTriggers: {
		'searched:bld:click': 'searched:bld:click'
	}
});