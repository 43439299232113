module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n	<li class="list-btn deal-name-holder flex-row-li">\n		<div><small>#'+
((__t=( _m.wId ))==null?'':__t)+
'</small><span>'+
((__t=( _m.name ))==null?'':__t)+
'</span></div>\n		<button class="copy-url" title="Скопировать ссылку на процесс в буфер обмена"><i class="fa fa-copy"></i></button>\n	</li>\n	<li class="list-lbl halfed"><small>стадия</small><span>'+
((__t=( _enum(_m.stageId,'dealStages')))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>статус</small><span>'+
((__t=( _enum(_m.status,'dealStatuses')))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>детали</small><span>'+
((__t=( _modelfunc('getSquareStr')))==null?'':__t)+
' <small>площадь</small></span></li>\n	<li class="list-lbl halfed"><small>&nbsp;</small><span>'+
((__t=( _modelfunc('getIncomeStr')))==null?'':__t)+
' <small>доход</small></span></li>\n	<li class="'+
((__t=( _ownerClassName ))==null?'':__t)+
'"><small>ответственный</small><span>'+
((__t=(_ownerName))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>изменена</small><span>'+
((__t=( _tfn(_m.modified) ))==null?'':__t)+
' <small>'+
((__t=( _fromNow(_m.modified) ))==null?'':__t)+
'</small></span></li>\n	<li class="list-lbl halfed"><small>создана</small><span>'+
((__t=( _tfn(_m.created) ))==null?'':__t)+
' <small>'+
((__t=( _fromNow(_m.created) ))==null?'':__t)+
', '+
((__t=( _empn(_m.creatorId) ))==null?'':__t)+
'</small></span></li>\n</ul>';
}
return __p;
};
