
import { View } from 'core/view';

import './recordset-view.less';



	//let ListResultView = CollectionView.V2;

let template = _.template('<div class="actions-panel actions-panel-left"></div><div class="result scrolled-region"></div><button class="show-left-panel"></button><button class="hide-left-panel"></button>');


export const BaseRecordsetView = View.extend({
	constructor: function() {
		View.apply(this, arguments);
		this._initializePanelState();
	},
	baseClassName: 'recordset-view',
	stateClassNames: ['panel-on'],
	template,
	regions: {
		'actionsPanel': { el: '.actions-panel-left', replaceElement: true },
		'result': '.result',
	},
	_initializePanelState() {
		this.delegate('click', '.show-left-panel', () => this.state('panel-on', true));
		this.delegate('click', '.hide-left-panel', () => this.state('panel-on', false));
	}
});


